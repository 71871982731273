function between(min, max) {  
  return Math.floor(
    Math.random() * (max - min) + min
  )
}

function guestId() {
  return `${Date.now()}_${between(0, 999999)}`;
}

export default guestId;