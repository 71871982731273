import axios from 'axios';

export const investorRegisterApi = async (authtoken, investor) => {

  return await axios.post(`${process.env.REACT_APP_API}/investor/register`, investor, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const investorProfilesApi = async (authtoken) => {

  return await axios.get(`${process.env.REACT_APP_API}/investor/profiles`, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const investorProfileApi = async (authtoken, investor) => {

  return await axios.get(`${process.env.REACT_APP_API}/investor/profile/${investor}`, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const updateInvestorProfileApi = async (authtoken, investor) => {

  return await axios.post(`${process.env.REACT_APP_API}/investor/profile`, investor, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};