/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "components/CustomButtons/Button";
import FilterListIcon from '@material-ui/icons/FilterList';

import CustomSearch from 'components/CustomSearch/CustomSearch';
import useStartupMarket from 'hooks/useStartupMarket';
import useIndustrytypeEnum from 'hooks/enums/useIndustryTypeEnum';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    height: "100%",
    // height: 1500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: 1200,
    marginTop: '0.5em',
    overflow: "auto",
    //height: "100%",
    [theme.breakpoints.up("md")]: {
      overflow: "auto",
      width: drawerWidth,
      position: "relative",
      //height: "100%",
      height: 1200,
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(2),
    paddingTop:0,
    paddingBottom:0,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

StartupListSection.propTypes = {
  data: PropTypes.array,
};

// eslint-disable-next-line no-unused-vars
export default function StartupListSection(props) {
  const {enumState, search} = useSelector((state) => ({...state}));
  const dispatch = useDispatch();
  const classes = useStyles2();
  const classesDrawer = useStyles();
  const {enumIndustrytype, error: errorGetEnum, onGetIndustrytypeEnumAll, loading: loadingIndustrytype} = useIndustrytypeEnum();
  const {onGetAll, data, onSearch, error: errorStartupMarket, loading: loadingStartupMarket} = useStartupMarket();
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // eslint-disable-next-line no-unused-vars
  const [industryFilter, setIndsutryFilter] = React.useState([]);
  const [openIndustry, setOpenIndustry] = React.useState(true);
  // eslint-disable-next-line no-unused-vars
  const [industry, setIndsutry] = React.useState([]);
  //const {data} = props;
  const [startupMarketData, setStartupMarketData] = React.useState([]);

  const emptyRows =
    // eslint-disable-next-line react/prop-types
    rowsPerPage - Math.min(rowsPerPage, startupMarketData.length - page * rowsPerPage);

  React.useEffect(() => {
    if(data.length <= 0) {
      onGetAll();
    }
    setStartupMarketData(data);
  }, []);

  React.useEffect(() => {
    if (loadingIndustrytype || loadingStartupMarket) {
      dispatch({type: APP_LOAD});
    }

    if (!loadingIndustrytype && !loadingStartupMarket) {
      dispatch({type: APP_LOADED});
    }

  }, [loadingIndustrytype, loadingStartupMarket]);

  React.useEffect(() => {
    setStartupMarketData(data);
  }, [data]);

  React.useEffect(() => {
    errorGetEnum && console.log('errorGetEnum', errorGetEnum)
    errorGetEnum && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on retrieve enum industry', message: errorGetEnum.error.message}});
  }, [errorGetEnum]);

  React.useEffect(() => {
    errorStartupMarket && console.log('errorStartupMarket', errorStartupMarket)
    errorStartupMarket && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on search startup market', message: errorStartupMarket.error.message}});
  }, [errorStartupMarket]);

  React.useEffect(() => {
    const filter = enumIndustrytype.filter(v => v.name !== 'Others');

    setIndsutry(filter.concat({code: '99', name: 'Others'}));
    // setIndsutry(enumIndustrytype);

    dispatch({
      type: 'SET_INDUSTRY_TYPE_ENUM',
      payload: { industryTypes: filter },
    });
  }, [enumIndustrytype]);

  React.useEffect(() => {
    //let isMounted = true; // note this flag denote mount status

    if(!enumState || !enumState.industryTypes){
      onGetIndustrytypeEnumAll();

      // getIndustryTypesEnum()
      //   .then((resp) => {
      //     dispatch({
      //       type: 'SET_INDUSTRY_TYPE_ENUM',
      //       payload: { industryTypes: resp.data },
      //     });

      //     if (isMounted) setIndsutry(resp.data);
         
      //   })
      //   .catch((error) => {console.log(error); return () => { isMounted = false };}); 
    }

    //return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  }, [enumState]);

  const handleClickIndustry = () => {
    setOpenIndustry(!openIndustry);
  };

  // https://www.youtube.com/watch?v=ArvR-ddOO78
  const handleIndustryChange = (event) => {
    //console.log(event.target.name, event.target.checked);
    const values = [...industryFilter];
    const index = values.indexOf(event.target.name);

    if(index === -1 && event.target.checked) {
      values.push(event.target.name);
    } else if(index >= 0 && !event.target.checked) {
      values.splice(index, 1);
    }
    
    setIndsutryFilter(values);
    //setState({ ...state, [event.target.name]: event.target.checked });
    
  };

  const handleApply = (event) => {
    event && event.preventDefault();

    console.log('textSearch', search.text, 'enumSearch: ', industryFilter);

    onSearch({textSearch: search.text || '', enumSearch: industryFilter});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CustomDrawers = () => {
    
    
    return (
      <React.Fragment>
        <Drawer
          className={classesDrawer.drawer}
          variant='permanent'
          elevation={25}
          classes={{
            paper: classesDrawer.drawerPaper,
          }}
          anchor='left'
        >
          <div className={classesDrawer.toolbar} />
          {/** search */}
          <Grid container spacing={1}>
            <Grid xs={12} sm={12} md={12} item>
              <List disablePadding style={{ margin: '0.9rem' }}>
                <CustomSearch textSearch={search.text} onSearch={handleApply} />
              </List>
            </Grid>
          </Grid>
          
          <Divider />
          <Grid container spacing={1}>
            <Grid xs={12} sm={12} md={12} item>
              {/** Industry */}
              <ListItem button onClick={handleClickIndustry}>
                <ListItemText primary='Industry' disableTypography />
                {openIndustry ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openIndustry} timeout='auto'>
                <List component='div' disablePadding>
                  {industry.map((item, idx) => {
                    return (
                      <ListItem
                        key={item + idx}
                        button
                        className={classesDrawer.nested}
                        disableGutters
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              //checked={state.Ecommerce}
                              value={item.code}
                              onChange={handleIndustryChange}
                              name={item.name} ///'Ecommerce'
                              color='primary'
                            />
                          }
                          label={
                            <Box component='div' fontSize={15}>
                              {item.name}
                            </Box>
                          } //'Ecommerce'
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Grid>
          </Grid>
          <Divider />
          <Grid container
          direction="row"
          justify="center"
          alignItems="center" spacing={1}>
            <Grid item xs={12} sm={12}  md={6} >
              <Button onClick={handleApply} color='primary' size='sm' round>
                <FilterListIcon className={classes.icons} /> Apply
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </React.Fragment>
    );
  };

  return (
    <div className={classesDrawer.root}>
      <CssBaseline />
      {CustomDrawers()}
      <main className={classesDrawer.content}>
        
        <div>
          <div className={classes.typo}>
            <h4>Startup market</h4>
          </div>
          <TableContainer  component={Paper}>
            <Table
              className={classes.table}
              aria-label='custom pagination table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Company name</StyledTableCell>
                  <StyledTableCell align='left'>Industry</StyledTableCell>
                  <StyledTableCell align='left'>Website</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? startupMarketData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : startupMarketData
                ).map((row) => (
                  <TableRow key={row.startupName}>
                    <TableCell
                      style={{ width: 260 }}
                      component='th'
                      scope='row'
                    >
                      {row.startupName}
                    </TableCell>
                    <TableCell style={{ width: 260 }} align='left'>
                      {row.industry}
                    </TableCell>
                    <TableCell align='left'><a href={`${row.website}`} rel="noopener noreferrer" target="_blank">{row.website}</a></TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={3}
                    count={startupMarketData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </main>
    </div>
  );
}
