import React from 'react';
// import moment from 'moment';
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
import classNames from "classnames";
import Datetime from "react-datetime";

import { makeStyles } from '@material-ui/core/styles';

import Email from '@material-ui/icons/Email';

import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import HeaderLinks from "components/Header/HeaderLinks";
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';

import {CLEAR_DRAF_SIGNUP} from 'constants/actionTypes';
import { LOAD_GUEST_ID } from 'constants/actionTypes';
import { LOGGED_IN_USER } from 'constants/actionTypes';
import { STATUS_MODAL_SHOW } from 'constants/actionTypes';

// import {updateUserProfileApi} from 'api/startup';
import useCurrentUser from 'hooks/useCurrentUser';
// import {jsDateToEpoch} from 'functions/dateEpoch';
import useMyProfiles from 'hooks/useMyProfiles';
// eslint-disable-next-line no-unused-vars
import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 'auto',
    width: '99%',
    padding: '1em',
  },
  paper2: {
    height: 'auto',
    width: '99%',
    padding: '1em',
    marginBottom: '1em',
    color: "#fff",
    background: "linear-gradient(60deg, #f7bebd, #e53935)", 
    boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
  },
  paper3: {
    height: 'auto',
    width: '99%',
    padding: '1em',
    marginBottom: '1em',
    color: "#fff",
    background: "linear-gradient(60deg, #3B5998 , #f1f4fa)",  
    boxShadow:
    "0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2)",
  },
  control: {
    padding: theme.spacing(2),
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    //zIndex: "3"
  },
  mainRaised: {
    margin: "81px 15px 15px",
    //borderRadius: "6px",
    // boxShadow:
    //   "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
}));

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  tel: '',
  birthdate: '',
};

const MyProfile = (props) => {
  const {common} = useSelector((state) => ({...state}));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ...rest } = props;

  const [state, setState] = React.useState(initialState);
  // const [startupProfiles, setStartupProfiles] = React.useState([]);

  const {user, gid, error: errorCurrentUserHook} = useCurrentUser();
  const {startupProfiles, investorProfiles, myProfiles, onGetStartupProfiles, onGetUserProfile, onGetInvestorProfiles, onUpdateMyProfile, loading: loadingMyProfileHook, error: errorMyProfileHook} = useMyProfiles();

  React.useEffect(() => {

    if(gid) {
      dispatch({ type: LOAD_GUEST_ID, gid: gid });
    }
    if(user) {
      dispatch({
        type: LOGGED_IN_USER,
        payload: user,
      });
    }

    if(errorCurrentUserHook) {
      console.log(errorCurrentUserHook);
    }

    // dispatch({ type: SAVE_DRAF_SIGNUP, payload: null });
    dispatch({ type: CLEAR_DRAF_SIGNUP, payload: null });

    // let isMounted = true; // note this flag denote mount status

    // startupProfilesApi(common.currentUser.token)
    // .then(resp => {
    //   if (isMounted) {
    //     //console.log(resp);
    //     setStartupProfiles(resp.data.name || []);
    //   }
    // })
    // .catch((error) => {console.log(error); return () => { isMounted = false };});

    // startupUserProfileApi(common.currentUser.token)
    // .then(resp => {
    //   if (isMounted) {
    //     // console.log('resp.data: ', resp.data);
    //     const dateString = (resp.data && resp.data.birthdate) ? moment.unix(resp.data.birthdate).format('DD/MM/YYYY') : '';
    //     // console.log(moment.unix(resp.data.birthdate));
    //     // console.log(moment.unix(resp.data.birthdate).format('DD/MM/YYYY'));
    //     setState({
    //       firstname: resp.data.firstname,
    //       lastname: resp.data.lastname,
    //       email: resp.data.email,
    //       tel: resp.data.tel,
    //       birthdate: dateString, //resp.data.birthdate,
    //     });
    //   }
    // })
    // .catch((error) => {console.log(error); return () => { isMounted = false };});

    // return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  }, []);

  React.useEffect(async () => {
    common.currentUser && common.currentUser.token &&  onGetUserProfile();
    common.currentUser && common.currentUser.token &&  onGetStartupProfiles();
    common.currentUser && common.currentUser.token &&  onGetInvestorProfiles();
  }, [common.currentUser]);

  React.useEffect(async () => {
    myProfiles && setState(myProfiles);
    // console.log('myProfiles', myProfiles);
  }, [myProfiles]);

  React.useEffect(() => {
    errorMyProfileHook && console.log('errorMyProfileHook', errorMyProfileHook);
    errorMyProfileHook && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorMyProfileHook.error.message}});
  }, [errorMyProfileHook]);

  React.useEffect(async () => {
    // console.log('loadingMyProfileHook', loadingMyProfileHook);
    
    (loadingMyProfileHook || !common.currentUser) && dispatch({type: APP_LOAD});
    (!loadingMyProfileHook && common.currentUser) && dispatch({type: APP_LOADED});
  }, [loadingMyProfileHook, common.currentUser]);

  const handleUpdateUserProfile = (event) => {
    event.preventDefault();

    //console.log('state:', state);

    // const myMomentObject = moment(state.birthdate, 'DD/MM/YYYY');
    // const dateString = moment(state.birthdate).format('DD/MM/YYYY');

    onUpdateMyProfile(state).then(() => dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'result', message: 'Updated successfully'}}));

    // updateUserProfileApi(common.currentUser.token, {
    //   firstname: state.firstname,
    //   lastname: state.lastname,
    //   email: state.email,
    //   tel: state.tel,
    //   birthdate: myMomentObject.unix() || '',
    // })
    // .then((resp) => { console.log('updateUserProfileApi: ', resp.data.message);})
    // .catch((error) => {console.log(error)});
    
  };

  const handleDate = (date) => {
    setState({
      ...state,
      birthdate: date,
    });
  };

  const handleStateChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  
  return (
    <div>
      <Header
        absolute
        //color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <div className={classNames(classes.main, classes.mainRaised)}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='center' spacing={2}>
              <Grid xs={12} sm={12} md={6} item>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    direction='row'
                  >
                    <Grid xs={10} sm={8} item>
                      <CustomInput
                        labelText='First name...'
                        id='firstname'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: 'firstname',
                          type: 'text',
                          onChange: (event) => handleStateChange(event),
                          value: state.firstname ? state.firstname.charAt(0).toUpperCase() + state.firstname.slice(1) : '', //state.firstname,
                        }}
                      />
                    </Grid>
                    <Grid xs={10} sm={8} item>
                      <CustomInput
                        labelText='Last name...'
                        id='lastname'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: 'lastname',
                          type: 'text',
                          onChange: (event) => handleStateChange(event),
                          value: state.lastname ? state.lastname.charAt(0).toUpperCase() + state.lastname.slice(1) : '', // state.lastname,
                        }}
                      />
                    </Grid>
                    <Grid xs={10} sm={8} item>
                      <CustomInput
                        labelText='Email...'
                        id='email'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: 'email',
                          type: 'email',
                          //error: emailNotValid,
                          //onChange: (event) => handleEmail(event),
                          value: state.email || '',
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid xs={10} sm={8} item>
                      <CustomInput
                        labelText='Tel...'
                        id='tel'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: 'tel',
                          type: 'text',
                          onChange: (event) => handleStateChange(event),
                          value: state.tel || '',
                        }}
                      />
                    </Grid>
                    <Grid xs={10} sm={8} item>
                      <InputLabel shrink className={classes.label}>
                        Birthday
                      </InputLabel>
                      
                      <FormControl fullWidth>
                        <Datetime
                          dateFormat='DD/MM/YYYY'
                          timeFormat={false}
                          value={state.birthdate || ''}
                          onChange={handleDate}
                          inputProps={{
                            placeholder: 'Datetime Picker Here',
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={10} sm={8} item>
                      <Button
                        color='facebook'
                        simple
                        fullWidth
                        // eslint-disable-next-line react/prop-types
                        onClick={handleUpdateUserProfile}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid xs={12} sm={12} md={6} item>
                <Paper className={classes.paper}>
                  <Grid container justify='center' direction='row'>
                    <Grid xs={12} sm={12} md={8} item>
                      <Paper className={classes.paper2}>
                        <Typography align='center' variant='h6'>
                          Startup
                        </Typography>
                        {startupProfiles && startupProfiles.length <= 0 ? (
                          <Typography
                            align='center'
                            variant='subtitle1'
                            paragraph
                          >
                            You haven’t created your Startup Information yet.
                          </Typography>
                        ) : <Typography
                        align='center'
                        variant='subtitle1'
                        paragraph
                      >
                        Your Startup Information. 
                      </Typography>}
                        {startupProfiles &&
                          startupProfiles.map((startupname) => {
                            return (
                              <Button
                              key={startupname}
                                color='white'
                                simple
                                fullWidth
                                // eslint-disable-next-line react/prop-types
                                onClick={() =>
                                  // eslint-disable-next-line react/prop-types
                                  props.history.push('/startup-profile?p='+startupname)
                                }
                              >
                                - {startupname}
                              </Button>
                            );
                          })}
                        <Button
                          color='facebook'
                          simple
                          fullWidth
                          // eslint-disable-next-line react/prop-types
                          onClick={() => props.history.push('/startup-search')}
                        >
                          Click here to insert
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid xs={12} sm={12} md={8} item>
                      <Paper className={classes.paper3}>
                        <Typography align='center' variant='h6'>
                          Investor
                        </Typography>
                        {investorProfiles && investorProfiles.length <= 0 ? (
                        <Typography
                          align='center'
                          variant='subtitle1'
                          paragraph
                        >
                          You haven’t registered as an investor yet.
                        </Typography> 
                        ) :
                        <Typography
                        align='center'
                        variant='subtitle1'
                        paragraph
                        >
                          Your Startup Information. 
                        </Typography>}
                          {investorProfiles &&
                            investorProfiles.map((investorName) => {
                              return (
                                <Button
                                key={investorName}
                                  color='white'
                                  simple
                                  fullWidth
                                  // eslint-disable-next-line react/prop-types
                                  onClick={() =>
                                    // eslint-disable-next-line react/prop-types
                                    props.history.push('/investor-profile?p='+investorName)
                                  }
                                >
                                  - {investorName}
                                </Button>
                              );
                            })}
                        
                        <Button
                          color='facebook'
                          simple
                          fullWidth
                          // eslint-disable-next-line react/prop-types
                          onClick={() => props.history.push('/investor-signup')}
                        >
                          Click here to insert
                        </Button>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default MyProfile;