import {
  LOGIN,
  LOGOUT,
  //REGISTER,
  LOGGED_IN_USER,
} from './constants/actionTypes';

// eslint-disable-next-line no-unused-vars
const localStorageMiddleware = (store) => (next) => (action) => {
  if (action.type === LOGGED_IN_USER || action.type === LOGIN) {
    if (!action.error) {
      window.localStorage.setItem(
        'jwt',
        JSON.stringify({
          t: action.payload.user.token,
          r: action.payload.user.role,
        })
      );
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem('jwt', null);
  }

  next(action);
};


export { localStorageMiddleware }