import React from "react";
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import InviteSection from "views/LandingPage/Sections/InviteSection.js";
import useMyProfiles from 'hooks/useMyProfiles';

import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

import landingBg from "assets/img/HeaderDatacenterV.3.jpg";
import landingMBg from "assets/img/Header-mV.2.jpg";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function InviteFriendsPage(props) {
  const {common} = useSelector((state) => ({...state}));
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const matcheSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { ...rest } = props;
  const {loading, error, startupProfiles, onGetStartupProfiles} = useMyProfiles();

  React.useEffect(async () => {
    dispatch({type: APP_LOAD});
  }, []);

  React.useEffect(() => {
    error && console.log('error', error);
    error && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: error.error.message}});

    // console.log(startupProfiles);
  }, [error]);

  React.useEffect(async () => {
    loading && !common.currentUser && dispatch({type: APP_LOAD});
    !loading && common.currentUser && dispatch({type: APP_LOADED});
  }, [loading, common.currentUser]);

  React.useEffect(async () => {
    common.currentUser && common.currentUser.token && await onGetStartupProfiles();
  }, [common.currentUser]);

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="YAK START"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color:  "white"
        }}
        {...rest}
      />
      <Parallax small filter image={!matcheSm ? landingBg : landingMBg}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}></h2>
              <h4>
                
              </h4>
              <br />
              
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <InviteSection data={startupProfiles} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
