/* eslint-disable react/prop-types */
import React from 'react';

import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

// core components
import Button from 'components/CustomButtons/Button.js';

// eslint-disable-next-line no-unused-vars
import {startupRegisterApi} from 'api/startup';
// eslint-disable-next-line no-unused-vars
import {SAVE_DRAF_SIGNUP} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

import styles from 'assets/jss/material-kit-react/views/startupSections/businessModel';

const useStyles = makeStyles(styles);

const titleMobile = () =>
  window.innerWidth < 450 ? (
    <Typography style={{ color: '#999', textAlign: 'center' }} variant='h5'>
      Business Model
    </Typography>
  ) : null;

  // let initialState = {
  //   keyPartners: '',
  //   keyActivities: '',
  //   keyResource: '',
  //   customerSegments: '',
  //   customerRelationships: '',
  //   channels: '',
  //   valueProposition: '',
  //   revenueStreams: '',
  //   costStructure: '',
  // };

const BusinessModel = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {common, auth} = useSelector((state) => ({...state}));
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = React.useState([]);
  const [state, setState] = React.useState({
    keyPartners: '',
    keyActivities: '',
    keyResource: '',
    customerSegments: '',
    customerRelationships: '',
    channels: '',
    valueProposition: '',
    revenueStreams: '',
    costStructure: '',
    problemSolved: '',
    solutionConcept: '',
    unfairAdvantage: '',
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  React.useEffect(() => {
    errors && errors.length > 0 && console.log('errors', errors);
  }, [errors]);

  React.useEffect(() => {
    if (auth.drafSignup && auth.drafSignup.startupName) {
      let initialState = {...auth.drafSignup};
      setState(initialState);
    }
  }, [auth]);

  const handleStateChange = (event) => {

    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    
    //console.log(state);
  };

  const onSave = (e) => {
    // eslint-disable-next-line react/prop-types
    props.setLoading(true);
    e.preventDefault();

    //console.log('state: ', state);

    const startup = {...auth.drafSignup, 
      keyPartners: state.keyPartners,
      keyActivities: state.keyActivities,
      keyResource: state.keyResource,
      customerSegments: state.customerSegments,
      customerRelationships: state.customerRelationships,
      channels: state.channels,
      valueProposition: state.valueProposition,
      revenueStreams: state.revenueStreams,
      costStructure: state.costStructure,
      problemSolved: state.problemSolved,
      solutionConcept: state.solutionConcept,
      unfairAdvantage: state.unfairAdvantage,
    };
      
      startupRegisterApi(common.currentUser.token,startup)
      // eslint-disable-next-line no-unused-vars
      .then((resp) => {
        //console.log(resp);
        dispatch({ type: SAVE_DRAF_SIGNUP, payload: startup });
        props.validToNext(true);
        props.handleComplete(); // next page
      })
      .catch((error) => {
        console.log(error.response.data);
        // eslint-disable-next-line react/prop-types
        props.setLoading(false);
        setErrors(error);
        dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: error.response.data}});
          
        //dispatch({type: API_RESPONSE_ERROR, payload: error.message})
        return;
      });

    // eslint-disable-next-line react/prop-types
    props.setLoading(false);
  };

  return (
    <div>
      {titleMobile()}
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='keyPartners'
              label='Key Partners'
              multiline
              rowsMax={4}
              value={state.keyPartners || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='keyActivities'
              label='Key Activities'
              multiline
              rowsMax={4}
              value={state.keyActivities || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='keyResource'
              label='Key Resource'
              multiline
              rowsMax={4}
              value={state.keyResource || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='customerSegments'
              label='Customer Segments'
              multiline
              rowsMax={4}
              value={state.customerSegments || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='customerRelationships'
              label='Customer Relationships'
              multiline
              rowsMax={4}
              value={state.customerRelationships || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='channels'
              label='Channels'
              multiline
              rowsMax={4}
              value={state.channels || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='valueProposition'
              label='Value proposition'
              multiline
              rowsMax={4}
              value={state.valueProposition || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='revenueStreams'
              label='Revenue Streams'
              multiline
              rowsMax={4}
              value={state.revenueStreams || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='costStructure'
              label='Cost Structure'
              multiline
              rowsMax={4}
              value={state.costStructure || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='problemSolved'
              label='Problem to be solved'
              multiline
              rowsMax={4}
              value={state.problemSolved || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='solutionConcept'
              label='Solution concept'
              multiline
              rowsMax={4}
              value={state.solutionConcept || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <TextField
              name='unfairAdvantage'
              label='Unfair Advantage'
              multiline
              rowsMax={4}
              value={state.unfairAdvantage || ''}
              onChange={handleStateChange}
            />
          </FormControl>
        </Grid>
        
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={2} item>
          <p className={classes.divider}> </p>
          <Button 
            onClick={onSave}
            block
            color='danger'
            size='lg'
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessModel;
