import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';

const LoadingToRedirect = (props) => {
    const [count, setCount] = useState(1);
    const history = useHistory();

    useEffect(() => {

        const interval = setInterval(() => {
            setCount((currentCount) => --currentCount);
        }, 1000);

        // eslint-disable-next-line react/prop-types
        const {to} = props;
        count === 0 && history.push(to || '/');

        return () => clearInterval(interval);
    }, [count, history, props]);

    return (
        <div className="container p-5 text-coner">
            <p>Redirecting you in {count} seconds.</p>
        </div>
    );
};

export default LoadingToRedirect;