/* eslint-disable react/prop-types */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React from 'react';
import {useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormControl, Typography, Fab } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import LockOutlined from '@material-ui/icons/LockOutlined';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AddIcon from '@material-ui/icons/Add';
import Check from "@material-ui/icons/Check";

// core components
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import AvatarUpload2 from 'components/AvatarUpload2/AvatarUpload2';
import CropImageDialog from 'components/CropImageDialog/CropImageDialog';
import { STATUS_MODAL_SHOW } from 'constants/actionTypes';
//import AvatarUpload from 'components/AvatarUpload/AvatarUpload';
import FullScreenDialog from 'components/FullScreenDialog/FullScreenDialog';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
// import {getIndustryTypesEnum} from 'api/enums/industryType';
import Snackbar from 'components/CustomSnackbar/Snackbar';
import styles from 'assets/jss/material-kit-react/views/investorSignup.js';

import image from 'assets/img/bg7.jpg';

import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
// functions validate
import validateEmail from 'functions/validations/email';

import useIndustrytypeEnum from 'hooks/enums/useIndustryTypeEnum';
import useInvestor from 'hooks/useInvestor';

const useStyles = makeStyles(styles);

export default function InvestorSignupPage(props) {
  const {common, enums, auth} = useSelector((state) => ({...state}));
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { ...rest } = props;
  const [errors, setErrors] = React.useState([]);
  const {dataInvestor, onRegisterInvestor, loading: loadingInvestorHook, error: errorInvestorHook} = useInvestor();
  const {enumIndustrytype, error: errorGetEnum, onGetIndustrytypeEnumAll, loading: loadingIndustryEnum} = useIndustrytypeEnum();
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [email, setEmail] = React.useState('');
  const [emailValidate, setEmailValidate] = React.useState(false);
  // const [password, setPassword] = React.useState('');
  const [touch, setTouch] = React.useState(false);
  const [fields, setFields] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openCropImageDialog, setOpenCropImageDialog] = React.useState(false);
  // const [industryTypeEnum, setIndsutryTypeEnum] = React.useState([]);
  const [preferredIndustry, setPreferredIndustry] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [state, setState] = React.useState({
    email: '',
    // password: '',
    investorName: '',
    investorLogo: [],
    investorIndustry: '',
    investorType: '',
    preferredIndustry: [],
    preferredFundMinRange: '',
    preferredFundMaxRange: '',
    investorContactName: '',
    investorContactPhone: '',
    investorWebsite: '',
    acceptTerm: false,
  });

  React.useEffect(() => {
    dispatch({type: APP_LOAD});

    let isMounted = true; // note this flag denote mount status

    onGetIndustrytypeEnumAll();
    // if(!enums || !enums.industryTypes){
    //   getIndustryTypesEnum()
    //     .then((resp) => {
    //       dispatch({
    //         type: 'SET_INDUSTRY_TYPE_ENUM',
    //         payload: { industryTypes: resp.data },
    //       });

    //       if (isMounted) setIndsutryTypeEnum(resp.data);
    //     })
    //     .catch((error) => {console.log(error); return () => { isMounted = false };}); 
    // } else {
    //   setIndsutryTypeEnum(enums.industryTypes);
    // }

  }, []);

  // React.useEffect(() => {
  //   console.log('preferredIndustry: ',preferredIndustry);
  // }, [preferredIndustry]);

  React.useEffect(() => {
    loading && dispatch({type: APP_LOAD});
    !loading && dispatch({type: APP_LOADED});
  }, [loading]);

  React.useEffect(() => {
    loadingInvestorHook && dispatch({type: APP_LOAD});
    !loadingInvestorHook && dispatch({type: APP_LOADED});
  }, [loadingInvestorHook]);

  React.useEffect(() => {
    // console.log('dataInvestor', dataInvestor);

    if(dataInvestor && dataInvestor.status === 'success') {
      // redirect
      history.push('/profile');
    }

  }, [dataInvestor]);

  React.useEffect(() => {
    // effect for spinner
    (common.currentUser && !loadingIndustryEnum) && dispatch({type: APP_LOADED});
  }, [common.currentUser, loadingIndustryEnum]);

  React.useEffect(() => {
    errorGetEnum && console.log('errorGetEnum', errorGetEnum)
    errorGetEnum && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on retrieve enum industry', message: errorGetEnum.error.message}});
  }, [errorGetEnum]);

  React.useEffect(() => {
    errorInvestorHook && console.log('errorInvestorHook', errorInvestorHook)
    errorInvestorHook && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on register investor industry', message: errorInvestorHook.error.message}});
  }, [errorInvestorHook]);

  const handleAddInput = () => {
    const values = [...fields];
    values.push({
      investmentYear: '',
      industry: '',
      startupName: '',
      investmentAmount: '',
    });
    setFields(values);
  };

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      investmentHistory: fields,
    }));
  }, [fields]);

  React.useEffect(() => {
    if (fields && fields.length <= 0) {
      handleAddInput();
    }
  }, []);

  setTimeout(() => {
    setCardAnimation('');
  }, 700);
  

  const handleEmail = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
      emailValidate: validateEmail(event.target.value),
    });
    setErrors([]);
  };

  const handleChange = (event) => {
    // console.log(event.target.name , event.target.value);
    setPreferredIndustry(event.target.value);
 
  };

  const handleChangeInput = (i, event) => {
    const values = [...fields];
    const { name, value } = event.target;
    values[i][name] = value;
    setFields(values);
    // console.log(fields);
  };

  const handleRemoveInput = (i) => {
    if (fields && fields.length <= 1) return;

    const values = [...fields];
    // console.log(values);
    values.splice(fields.length - 1, 1);
    setFields(values);
  };

  const handleStateChange = (event) => {

    const name = event.target.name;
    if(name === 'acceptTerm') {
      // console.log(name, event.target.checked);
      setState({
        ...state,
        [name]: event.target.checked,
      });
    } else {
      // console.log(name, event.target.value);
      setState({
        ...state,
        [name]: event.target.value,
      });
    }
  };

  React.useEffect(() => {
    setLoading(false);
  }, [image]);

  // React.useEffect(() => {
  //   errors && errors.length > 0 && console.log(errors);
  // }, [errors]);

  const handleTermDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseTermDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenCropImageDialog(false);
  };

  const investorHistory = () => {
    return (
      <div>
        <p className={classes.divider}> </p>
        {fields.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <FormHelperText>Investment history: {idx + 1}</FormHelperText>
              <Grid xs={12} sm={12} md={12} container spacing={1} item>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='number'
                      label='Year of investment'
                      name='investmentYear'
                      value={fields[idx]['investmentYear'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Industry'
                      name='industry'
                      value={fields[idx]['industry'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Startup name'
                      name='startupName'
                      value={fields[idx]['startupName'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Investment amount'
                      name='investmentAmount'
                      value={fields[idx]['investmentAmount'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <Grid container xs={12} sm={12} md={12} item justify='flex-end'>
          <Button
            simple
            color='facebook'
            type='button'
            onClick={() => handleAddInput()}
          >
            <i className='fa fa-plus' aria-hidden='true'></i>
          </Button>
          <Button
            simple
            color='danger'
            type='button'
            onClick={() => handleRemoveInput()}
          >
            <i className='fa fa-times' aria-hidden='true'></i>
          </Button>
        </Grid>
      </div>
    );
  };


  const handleValidation = () => {
    let formIsValid = true;   
    setErrors([]);
    // console.log('state: ', state);
    //Business/CompanyName
    if (!state['investorName']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Investor/Company Name Cannot be empty.`,
          key: 'investorName', 
        },
      ]);
    }

    //email
    if (!state['email'] || !state['emailValidate']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `email is invalid.`,
          key: 'email', 
        },
      ]);
    }

    // investorLogo: [image],
    if (!state['investorLogo'] || state['investorLogo'].length <= 0) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Investor Logo Cannot be empty.`,
          key: 'investorLogo',
        },
      ]);
    }

    // businessIndustry: state.investorIndustry,
    if (!state['investorIndustry']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Business Industry Cannot be empty.`,
          key: 'investorIndustry', 
        },
      ]);
    }

    // investorType: state.investorType,
    if (!state['investorType']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Investor Type Cannot be empty.`,
          key: 'investorType', 
        },
      ]);
    }

    // investmentHistory: state.investmentHistory || [],
    if (!state['investmentHistory'] || state['investmentHistory'].length <= 0) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Investment History Cannot be empty.`,
          key: 'investmentHistory',
        },
      ]);
    }

    // preferredIndustries: preferredIndustry || [],
    if (!preferredIndustry || preferredIndustry.length <= 0) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Preferred Industry Cannot be empty.`,
          key: 'preferredIndustry',
        },
      ]);
    }

    // preferredFundMin: state.preferredFundMinRange || 0,
    if (!state['preferredFundMinRange']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Preferred Fund Min Range Cannot be empty.`,
          key: 'preferredFundMinRange', 
        },
      ]);
    }

    // preferredFundMax: state.preferredFundMaxRange || 0,
    if (!state['preferredFundMaxRange']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Preferred Fund Max Range Cannot be empty.`,
          key: 'preferredFundMaxRange', 
        },
      ]);
    }

    // contactName: state.investorContactName,
    if (!state['investorContactName']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Investor Contact Name Cannot be empty.`,
          key: 'investorContactName', 
        },
      ]);
    }

    // contactNumber: state.investorContactPhone,
    if (!state['investorContactPhone']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Investor Contact Phone Cannot be empty.`,
          key: 'investorContactPhone', 
        },
      ]);
    }

    // linkOfWebsite: state.investorWebsite,
    // if (!state['investorWebsite']) {
    //   formIsValid = false;
    //   setErrors((prev) => [
    //     ...prev,
    //     {
    //       message: `Investor Website Cannot be empty.`,
    //       key: 'investorWebsite', 
    //     },
    //   ]);
    // }

    // termAndCondition: state.acceptTerm || false,
    if (!state['acceptTerm'] || state['acceptTerm'] === false) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Term And Condition Cannot be empty or false.`,
          key: 'acceptTerm', 
        },
      ]);
    }

    return formIsValid;
  };
  const formSignup = () => {
    const handleSignup = (e) => {
      e.preventDefault();
      dispatch({type: APP_LOAD});

      if (handleValidation()) {
        const investor = {
          investorName: state.investorName,
          email: state.email,
          investorLogo: [image],
          businessIndustry: state.investorIndustry,
          investorType: state.investorType,
          investmentHistory: state.investmentHistory || [],
          preferredIndustries: preferredIndustry || [],
          preferredFundMin: state.preferredFundMinRange || 0,
          preferredFundMax: state.preferredFundMaxRange || 0,
          contactName: state.investorContactName,
          contactNumber: state.investorContactPhone,
          linkOfWebsite: state.investorWebsite,
          termAndCondition: state.acceptTerm || false,
        };

        onRegisterInvestor(investor);

        // console.log('investor: ', investor);
      }
      
      dispatch({type: APP_LOADED});
    };

    return (
      <GridItem xs={12} sm={12} md={12}>
        <p className={classes.divider}> </p>
        <AvatarUpload2 id={1} 
          // eslint-disable-next-line react/prop-types
          setLoading={(r) => setLoading(r)}
          imagecloud={state.investorLogo.length > 0 ? state.investorLogo[0] : undefined}
          clearImage={setImage}
          handleClick={() =>  setOpenCropImageDialog(true)}
        />
        <CropImageDialog folder='investorlogo' gid={common.gid} 
          result={(d) => {
            // console.log(d);
            setImage(d);
            setState((prev) => ({
              ...prev,
              investorLogo: [d],
            }));
            dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'Upload Image', message: 'Upload image successful'}});
            setOpenCropImageDialog(false);
          }}
          imageName={`${state.investorName ? state.investorName.replace(/\s/g, '') : `${common.gid}_${new Date().valueOf()}`}_logo`}
          handle={handleCloseDialog} openDialog={openCropImageDialog}
        />
        {/*<AvatarUpload 
          setLoading={(r) => setLoading(r)}
          folder='logo'
          imageName={`${state.investorName ? state.investorName.replace(/\s/g, '') : `${common.gid}_${new Date().valueOf()}`}_logo`}
          resize
          autoSave
          imagecloud={state.investorLogo.length > 0 ? state.investorLogo[0] : undefined}
          clearImage={setImage}
          result={(d) => {
            setImage(d);
          }}
        />*/}
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText='Email'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'email',
              type: 'email',
              error: !state.emailValidate,
              onChange: (event) => handleEmail(event),
              value: state.email,
              endAdornment: (
                <InputAdornment position='end'>
                  <Email className={classes.inputIconsColor} />
                </InputAdornment>
              ),
            }}
          />
          {/*<CustomInput
            labelText='Password'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'password',
              type: 'password',
              error: passValidate(),
              onChange: (event) => handleStateChange(event),
              value: state.password,
              endAdornment: (
                <InputAdornment position='end'>
                  <LockOutlined className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              autoComplete: 'off',
            }}
          />*/}
          <CustomInput
            labelText='Investor / Company Name'
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              name: 'investorName',
              type: 'text',
              error: !state.investorName,
              onChange: (event) => handleStateChange(event),
              value: state.investorName,
            }}
          />
          <FormControl className={classes.formControl} fullWidth margin='dense'>
            <InputLabel htmlFor='investorIndustry-native-helper'>
              Your business’ industry...
            </InputLabel>
            <NativeSelect
              value={state.investorIndustry}
              onChange={handleStateChange}
              inputProps={{
                name: 'investorIndustry',
                id: 'investorIndustry-native-helper',
              }}
            >
              <option aria-label='None' value='' />
              {enumIndustrytype.map((item) => {
                return (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
              
            </NativeSelect>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
          </FormControl>
          <FormControl className={classes.formControl} fullWidth margin='dense'>
            <InputLabel htmlFor='investorType-native-helper'>
              Type of investor...
            </InputLabel>
            <NativeSelect
              value={state.investorType}
              onChange={handleStateChange}
              inputProps={{
                name: 'investorType',
                id: 'investorType-native-helper',
              }}
            >
              <option aria-label='None' value='' />
              <option value={'VC'}>VC</option>
              <option value={'CVC'}>CVC</option>
              <option value={'Angel'}>Angel</option>
              <option value={'Investor'}>Investor</option>
              <option value={'Corporate'}>Corporate</option>
            </NativeSelect>
          </FormControl>
        </GridItem>
        {investorHistory()}
        <GridItem xs={12} sm={12} md={6}>
          <FormControl className={classes.formControl} fullWidth margin='dense'>
            <InputLabel htmlFor='preferredIndustry-native-helper'>
              Preferred industries to invest
            </InputLabel>
            <p className={classes.divider}> </p>
            <Select
            labelId='demo-mutiple-checkbox-label'
            id='demo-mutiple-checkbox'
            multiple
            value={preferredIndustry}
            onChange={handleChange}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            //MenuProps={MenuProps}
          >
            {enumIndustrytype.map((item) => (
              <MenuItem key={item.name} value={item.name}>
                <Checkbox checked={preferredIndustry.indexOf(item.name) > -1} />
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </Select>
          </FormControl>
        </GridItem>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl className={classes.formControl} fullWidth margin='dense'>
            <p className={classes.divider}> </p>
            <InputLabel htmlFor='preferredIndustry-native-helper'>
              Preferred fund range of each round:
            </InputLabel>
          </FormControl>
        </Grid>

        <Grid container>
          <Grid item container direction='row'>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                labelText='MIN'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: 'preferredFundMinRange',
                  type: 'number',
                  onChange: (event) => handleStateChange(event),
                  value: state.preferredFundMinRange,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CustomInput
                labelText='MAX'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: 'preferredFundMaxRange',
                  type: 'number',
                  onChange: (event) => handleStateChange(event),
                  value: state.preferredFundMaxRange,
                }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container direction='row'>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  labelText='Contact Name'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'investorContactName',
                    type: 'text',
                    onChange: (event) => handleStateChange(event),
                    value: state.investorContactName,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  labelText='Contact Number'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'investorContactPhone',
                    type: 'text',
                    onChange: (event) => handleStateChange(event),
                    value: state.investorContactPhone,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container direction='row'>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  labelText='Link of website...'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'investorWebsite',
                    type: 'text',
                    onChange: (event) => handleStateChange(event),
                    value: state.investorWebsite,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container >
            <Grid
              item
              container spacing={0}
              direction='column'
              justify='center'
              alignItems='center'
            >
              <Grid item xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.acceptTerm}
                      onChange={(event) => handleStateChange(event)}
                      name='acceptTerm'
                    />
                  }
                  label=''
                />
                <Button
                  simple
                  color='facebook'
                  type='button'
                  style={{padding: 0, margin: 0}}
                  onClick={() => handleTermDialog()}
                >Term & condition</Button>
              </Grid>
              <FullScreenDialog handle={handleCloseTermDialog} openDialog={openDialog}/>
            </Grid>
          </Grid>
        </Grid>

        <p className={classes.divider}> </p>
        <Button onClick={handleSignup} block color='facebook' size='lg'>
          submit
        </Button>
      </GridItem>
    );
  };

  return (
    <div>
      <Header
        absolute
        color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          {errors && errors.length > 0 && <Snackbar color='danger' errors={errors} />}
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes[cardAnimaton]}>
                <form
                  className={classes.form + ' ' + classes.margintop1rem}
                  // onSubmit={(e) => onSubmit(e)}
                  encType='multipart/form-data'
                  method='POST'
                >
                  <p className={classes.divider}></p>
                  <CustomTabs styles={{ background: '#3b5998' }}
                    headerColor='info'
                    tabs={[
                      {
                        tabName: 'Sign up for investor',
                        tabIcon: PersonPinIcon,
                        tabContent: (
                          <CardBody className={classes.cardBodyNoPadding}>
                            {formSignup()}
                          </CardBody>
                        ),
                      },
                    ]}
                  />

                  <CardFooter className={classes.cardFooter}></CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
