import {combineReducers} from 'redux';
import {searchReducer} from 'reducers/searchReducer';
import {enumReducer} from 'reducers/enumReducer';
import authReducer from 'reducers/authReducer';
import commonReducer from 'reducers/commonReducer';

const rootReducer = combineReducers({
  search: searchReducer,
  enums: enumReducer,
  auth: authReducer,
  common: commonReducer,
});

export default rootReducer;