import React from 'react';
import classNames from "classnames";
import {useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import HeaderLinks from "components/Header/HeaderLinks";
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import useStartup from 'hooks/useStartup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 'auto',
    width: '99%',
    padding: '1em',
  },
  paper2: {
    height: 'auto',
    width: '99%',
    padding: '1em',
    marginBottom: '1em',
    color: "#fff",
    background: "linear-gradient(60deg, #f7bebd, #e53935)", 
    boxShadow:
    "0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)",
  },
  control: {
    padding: theme.spacing(2),
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    //zIndex: "3"
  },
  mainRaised: {
    margin: "81px 0px 0px",
    //borderRadius: "6px",
    // boxShadow:
    //   "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
}));

const SearchStartup = (props) => {
  const dispatch = useDispatch();
  const { ...rest } = props;
  const classes = useStyles();
  const [startupName, setStartupName] = React.useState('');
  const {onSearchStartup, setQuery, data, error: errorSearchStartup} = useStartup();
 
  // React.useEffect(() => {
  //   errorSearchStartup && console.log('errorSearchStartup: ', errorSearchStartup);
  //   data && data.length > 0 && console.log('data: ', data);
  // }, [error, data]);

  React.useEffect(() => {
    // errorSearchStartup && console.log('errorSearchStartup', errorSearchStartup)
    errorSearchStartup && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorSearchStartup.error.message}});
  }, [errorSearchStartup]);

  const handleQueryChange = (event) => {
    setStartupName(event.target.value);
    setQuery(event.target.value);
  };

  return (
    <div>
      <Header
        absolute
        //color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='center' spacing={2}>
              <Grid xs={12} sm={12} md={12} item>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    direction='row'
                  >
                    <Grid xs={12} sm={12} md={10} item>
                      <Typography align='center' variant='subtitle1' paragraph>
                        Create your Startup Information into Yak Start Data
                        Center for more chance of Investors to connect with you.
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justify='center'
                      alignItems='center'
                      direction='row'
                    >
                      <Grid xs={10} sm={10} md={6} item>
                        <CustomInput
                          labelText='Startup Name*'
                          id='startupname'
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            name: 'startupname',
                            type: 'text',
                            onChange: (event) => handleQueryChange(event),
                            value: startupName,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12} sm={12} md={12} item>
                      <Grid
                        container
                        justify='center'
                        alignItems='center'
                        direction='column'
                      >
                        <Grid xs={12} sm={12} md={12} item>
                          <Button
                            color='facebook'
                            round
                            size='sm'
                            //fullWidth
                            // eslint-disable-next-line react/prop-types
                            onClick={() => onSearchStartup()}
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={10} sm={8} item>
                      <Typography align='center' variant='subtitle1' paragraph>
                        
                      </Typography>
                    </Grid>
                    <Grid xs={12} sm={12} md={12} item>
                      <Grid container justify='center' direction='row'>
                        <Grid xs={12} sm={12} md={8} item>
                          <Paper className={classes.paper2}>
                            <Typography align='center' variant='h6'>
                              Result:
                            </Typography>
                            <Button
                              color='facebook'
                              simple
                              fullWidth
                              onClick={() =>
                                // eslint-disable-next-line react/prop-types
                                props.history.push('/startup-signup')
                              }
                            >
                              Click here to register
                            </Button>
                            {!data || data.length <= 0 ?
                              <Typography
                                align='center'
                                variant='subtitle1'
                                paragraph
                              >
                                No information please register
                            </Typography>
                              : undefined}
                            {data.map(v => {
                              return (
                                <Typography
                                key={v.startupName}
                              align='center'
                              variant='subtitle1'
                              paragraph
                            >
                              - {v.startupName}
                            </Typography>
                              );
                            })}
                            
                          </Paper>
                        </Grid>
                        
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default SearchStartup;