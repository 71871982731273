import axios from 'axios';

export const startupsApi = async ({currentUser, query}) => {

  return await axios.get(`${process.env.REACT_APP_API}/admin/startups/${query}`, {
      headers: {
          authtoken: currentUser.token,
          "Content-Type": "application/json",
      },
  });
};