export const enumReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_INDUSTRY_TYPE_ENUM':
      return {...state, ...action.payload};
    case 'SET_KEY_TECHNOLOGY_ENUM':
      return {...state, ...action.payload};
    case 'SET_COUNTRY_ENUM':
      return {...state, ...action.payload};
    case 'SET_BUSINESS_STATUS_ENUM':
      return {...state, ...action.payload};
    default:
      return state;
  }
}