import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from "components/CustomButtons/Button.js";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
// import Slider from '@material-ui/core/Slider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import FilterListIcon from '@material-ui/icons/FilterList';

import CustomSearch from 'components/CustomSearch/CustomSearch';
// import { getIndustryTypesEnum } from 'api/enums/industryType';
import useIndustrytypeEnum from 'hooks/enums/useIndustryTypeEnum';
import useKeyTechnologyEnum from 'hooks/enums/useKeyTechnologyEnum';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 260,
    height: 3000,
    flexShrink: 0,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
  rootNumberEmployee: {
    width: 200,
    margin: '0.9rem',
  },
}));

// function valuetext(value) {
//   return `${value}`;
// }

// const marks = [
//   {
//     value: 0,
//     label: '0',
//   },
//   {
//     value: 1000,
//     label: '1000',
//   },
// ];


export default function SearchDrawer(props) {
  const { enumState, search } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const classes = useStyles();

  const [state, setState] = React.useState({
    left: false,
  });

  const {enumIndustrytype, error: errorGetEnum, onGetIndustrytypeEnumAll} = useIndustrytypeEnum();
  const {enumKeyTechnologies, error: errorGetKeyTechnologies, onGetKeyTechnologiesAll} = useKeyTechnologyEnum();
  const [openIndustry, setOpenIndustry] = React.useState(false);
  const [industry, setIndsutry] = React.useState([]);
  const [industryFilter, setIndsutryFilter] = React.useState([]);
  // const [numberEmployee, setNumberEmployee] = React.useState([0, 100]);
  const [openLastFundingType, setOpenLastFundingType] = React.useState(false);
  const [lastFundingTypeFilter, setLastFundingTypeFilter] = React.useState([]);
  const [openKeyTechnology, setOpenKeyTechnology] = React.useState(false);
  const [keyTechnology, setKeyTechnology] = React.useState([]);
  const [keyTechnologyFilter, setKeyTechnologyFilter] = React.useState([]);
  const [openStartupState, setOpenStartupState] = React.useState(true);
  const [startupStateFilter, setStartupStateFilter] = React.useState([]);

  React.useEffect(() => {

    if (!enumState || !enumState.industryTypes) {
      onGetIndustrytypeEnumAll();
    }

    if (!enumState || !enumState.keyTechnologies) {
      onGetKeyTechnologiesAll();
    }

  }, []);

  React.useEffect(() => {
    const filter = enumIndustrytype.filter(v => v.name !== 'Others');

    setIndsutry(filter.concat({code: '99', name: 'Others'}));

    dispatch({
      type: 'SET_INDUSTRY_TYPE_ENUM',
      payload: { industryTypes: enumIndustrytype },
    });
  }, [enumIndustrytype]);

  React.useEffect(() => {
    setKeyTechnology(enumKeyTechnologies);
    dispatch({
      type: 'SET_KEY_TECHNOLOGY_ENUM',
      payload: { keyTechnologies: enumKeyTechnologies },
    });
  }, [enumKeyTechnologies]);

  React.useEffect(() => {
    errorGetEnum && console.log('errorGetEnum', errorGetEnum)
    errorGetEnum && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on retrieve enum industry', message: errorGetEnum.error.message}});
  }, [errorGetEnum]);

  React.useEffect(() => {
    errorGetKeyTechnologies && console.log('errorGetKeyTechnologies', errorGetKeyTechnologies)
    errorGetKeyTechnologies && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on retrieve enum key technology', message: errorGetKeyTechnologies.error.message}});
  }, [errorGetKeyTechnologies]);

  const handleApply = (event) => {
    event && event.preventDefault();

    // console.log('textSearch', search.text, 'industrySearch: ', industryFilter);

    setState({ ...state, ['left']: false });
    // eslint-disable-next-line react/prop-types
    props.onSearch({
      textSearch: search.text || '',
      industrySearch: industryFilter,
      lastFundingTypeSearch: lastFundingTypeFilter,
      keyTechnologySearch: keyTechnologyFilter,
      startupStateSearch: startupStateFilter,
      fundraisingStatusSearch: valueFundraisingStatus,
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }

    if (event.type === 'keydown' && event.key !== 'Enter') {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleIndustryChange = (event) => {
    // console.log(event.target.name, event.target.checked);
    const values = [...industryFilter];
    const index = values.indexOf(event.target.name);

    if (index === -1 && event.target.checked) {
      values.push(event.target.name);
    } else if (index >= 0 && !event.target.checked) {
      values.splice(index, 1);
    }

    setIndsutryFilter(values);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClickIndustry = () => {
    setOpenIndustry(!openIndustry);
  };

  // const handleNumberEmployeeChange = (event, newValue) => {
  //   setNumberEmployee(newValue);
  // };

  const handleClickLastFundingType = () => {
    setOpenLastFundingType(!openLastFundingType);
  };

  const handleLastFundingTypeChange = (event) => {
    // console.log(event.target.name, event.target.checked);
    const values = [...lastFundingTypeFilter];
    const index = values.indexOf(event.target.name);

    if (index === -1 && event.target.checked) {
      values.push(event.target.name);
    } else if (index >= 0 && !event.target.checked) {
      values.splice(index, 1);
    }

    setLastFundingTypeFilter(values);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClickKeyTechnology = () => {
    setOpenKeyTechnology(!openKeyTechnology);
  };

  const handleKeyTechnologyChange = (event) => {
    // console.log(event.target.name, event.target.checked);
    const values = [...keyTechnologyFilter];
    const index = values.indexOf(event.target.name);

    if (index === -1 && event.target.checked) {
      values.push(event.target.name);
    } else if (index >= 0 && !event.target.checked) {
      values.splice(index, 1);
    }

    setKeyTechnologyFilter(values);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClickStartupState = () => {
    setOpenStartupState(!openStartupState);
  };

  const handleStartupStateChange = (event) => {
    // console.log(event.target.name, event.target.checked);
    const values = [...startupStateFilter];
    const index = values.indexOf(event.target.name);

    if (index === -1 && event.target.checked) {
      values.push(event.target.name);
    } else if (index >= 0 && !event.target.checked) {
      values.splice(index, 1);
    }

    setStartupStateFilter(values);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [valueFundraisingStatus, setValueFundraisingStatus] = React.useState('');
  const handleFundraisingStatusChange = (event) => {
    setValueFundraisingStatus(event.target.value);
  };

  // const [valueConfidenceLevel, setValueConfidenceLevel] = React.useState('');
  // const handleConfidenceLevelChange = (event) => {
  //   setValueConfidenceLevel(event.target.value);
  // };

  // const handleCbValue = (itemname) => {
    
  //   const result = industryFilter.some((v) => v === itemname) || false;
  //   console.log(itemname, result);
  //   return result;
  // };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role='presentation'
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {/** search */}
      <List disablePadding style={{ margin: '0.9rem' }}>
        <CustomSearch textSearch={search.text} onSearch={handleApply} />
      </List>
      <Divider />

      {/** Industry */}
      <List>
        <ListItem button onClick={handleClickIndustry}>
          <ListItemText primary='Industry' disableTypography />
          {openIndustry ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openIndustry} timeout='auto'>
          <List component='div' disablePadding>
            {industry.map((item, idx) => {
              return (
                <ListItem
                  key={item + idx}
                  button
                  className={classes.nested}
                  disableGutters
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={industryFilter.some((v) => v === item.name)}
                        value={item.code}
                        onChange={handleIndustryChange}
                        name={item.name} ///'Ecommerce'
                        color='primary'
                      />
                    }
                    label={
                      <Box component='div' fontSize={14}>
                        {item.name}
                      </Box>
                    } //'Ecommerce'
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>
      <Divider />

      {/** Number of employee
      <List disablePadding>
        <div className={classes.rootNumberEmployee}>
          <ListItemText disableTypography primary='Number of employee' />
          <Slider
            min={0}
            step={10}
            max={1000}
            value={numberEmployee}
            onChange={handleNumberEmployeeChange}
            valueLabelDisplay='auto'
            aria-labelledby='range-slider'
            getAriaValueText={valuetext}
            marks={marks}
          />
        </div>
      </List>
      */}
      {/** Last funding type */}
      <List>
        <ListItem button onClick={handleClickLastFundingType}>
          <ListItemText primary='Last funding type' disableTypography />
          {openLastFundingType ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openLastFundingType} timeout='auto'>
          <List component='div' disablePadding>
            {[
              { name: 'Pre-seed', code: '01' },
              { name: 'Seed', code: '02' },
              { name: 'Series A', code: '03' },
              { name: 'Series B', code: '04' },
              { name: 'Series C', code: '05' },
              { name: 'Series D', code: '06' },
              { name: 'Series E', code: '07' },
            ].map((item, idx) => {
              return (
                <ListItem
                  key={item + idx}
                  button
                  className={classes.nested}
                  disableGutters
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item.code}
                        onChange={handleLastFundingTypeChange}
                        name={item.name}
                        color='primary'
                      />
                    }
                    label={
                      <Box component='div' fontSize={14}>
                        {item.name}
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>

      <Divider />

      {/** Key technology */}
      <List>
        <ListItem button onClick={handleClickKeyTechnology}>
          <ListItemText primary='Key technology' disableTypography />
          {openKeyTechnology ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openKeyTechnology} timeout='auto'>
          <List component='div' disablePadding>
            {keyTechnology.map((item, idx) => {
              return (
                <ListItem
                  key={item + idx}
                  button
                  className={classes.nested}
                  disableGutters
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item.code}
                        onChange={handleKeyTechnologyChange}
                        name={item.name}
                        color='primary'
                      />
                    }
                    label={
                      <Box component='div' fontSize={14}>
                        {item.name}
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>
      <Divider />

      {/** State of startup */}
      <List>
        <ListItem button onClick={handleClickStartupState}>
          <ListItemText primary='State of startup' disableTypography />
          {openStartupState ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openStartupState} timeout='auto'>
          <List component='div' disablePadding>
            {[
              { name: 'Ideas state', code: '01' },
              { name: 'Already Luach Product (MVP)', code: '02' },
              { name: 'Already have active users', code: '03' },
              { name: 'Already have revenue', code: '04' },
              { name: 'Profitable', code: '05' },
            ].map((item, idx) => {
              return (
                <ListItem
                  key={item + idx}
                  button
                  className={classes.nested}
                  disableGutters
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={item.code}
                        onChange={handleStartupStateChange}
                        name={item.name}
                        color='primary'
                      />
                    }
                    label={
                      <Box component='div' fontSize={14}>
                        {item.name}
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>
      <Divider />

      {/** Fundraising Status */}
      <List disablePadding style={{ margin: '0.9rem' }}>
        <ListItemText disableTypography primary='Fundraising Status' />
        <ListItem button className={classes.nested} disableGutters>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='fundraisingStatus'
              name='fundraisingStatus'
              value={valueFundraisingStatus}
              onChange={handleFundraisingStatusChange}
            >
              <FormControlLabel
                value='Open'
                control={<Radio color='primary' />}
                label={
                  <Box component='div' fontSize={14}>
                    Open
                  </Box>
                }
              />
              <FormControlLabel
                value='Not Open'
                control={<Radio color='primary' />}
                label={
                  <Box component='div' fontSize={14}>
                    Not Open
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
      </List>
      <Divider />
      {/** Confidence Level */}
      {/**  
      <List disablePadding style={{ margin: '0.9rem' }}>
        <ListItemText disableTypography primary='Confidence Level' />
        <ListItem button className={classes.nested} disableGutters>
          <FormControl component='fieldset'>
            <RadioGroup
              aria-label='confidenceLevel'
              name='confidenceLevel'
              value={valueConfidenceLevel}
              onChange={handleConfidenceLevelChange}
            >
              <FormControlLabel
                value='open'
                control={<Radio color='primary' />}
                label={
                  <Box component='div' fontSize={14}>
                    Pass
                  </Box>
                }
              />
              <FormControlLabel
                value='notpass'
                control={<Radio color='primary' />}
                label={
                  <Box component='div' fontSize={14}>
                    Not pass
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </ListItem>
      </List>
      */}
      <Divider />
      <Grid
        container
        direction='row'
        justify='center'
        alignItems='center'
        spacing={1}
      >
        <Grid item xs={6} sm={6}>
          <Button onClick={handleApply} color='primary' size='sm' round>
            <FilterListIcon className={classes.icons} /> Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button link onClick={toggleDrawer(anchor, true)}>
            <i className={'fas fa-bars'} /> Search & Filtering
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
