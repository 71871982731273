/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';

// core components
// import AvatarUpload from 'components/AvatarUpload/AvatarUpload';
import AvatarUpload2 from 'components/AvatarUpload2/AvatarUpload2';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button';
import CropImageDialog from 'components/CropImageDialog/CropImageDialog';

import {startupRegisterApi} from 'api/startup';
import {SAVE_DRAF_SIGNUP} from 'constants/actionTypes';
import { STATUS_MODAL_SHOW } from 'constants/actionTypes';

import styles from 'assets/jss/material-kit-react/views/startupSections/team';

const useStyles = makeStyles(styles);

const titleMobile = () =>
  window.innerWidth < 450 ? (
    <Typography style={{ color: '#999', textAlign: 'center' }} variant='h5'>
      Team
    </Typography>
  ) : null;

const Team = (props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const {common, auth} = useSelector((state) => ({...state}));
  const dispatch = useDispatch();

  let initialState = {
    currentTeam: [],
  };
  const [state, setState] = React.useState(initialState);
  const [image1, setImage1] = React.useState(null);
  const [image2, setImage2] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);

  React.useEffect(() => {
    errors && errors.length > 0 && console.log('errors', errors);
  }, [errors]);
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    // console.log('image2: ', image2);
    // eslint-disable-next-line react/prop-types
    props.setLoading(false);

    //console.log('keyTechnology: ', keyTechnology);
  }, [image1, image2]);
  
  React.useEffect(() => {
    // eslint-disable-next-line react/prop-types
    props.setLoading(true);

    if (auth.drafSignup && auth.drafSignup.startupName) {

      if(auth.drafSignup.currentTeam && auth.drafSignup.currentTeam.length > 0) {
        const {currentTeam} = auth.drafSignup;

        setState((prev) => ({
          ...prev,
          name1: (currentTeam[0] && currentTeam[0].name) || '',
          position1: (currentTeam[0] && currentTeam[0].position) || '',
          briefProfile1: (currentTeam[0] && currentTeam[0].briefProfile) || '',
          name2: (currentTeam[1] && currentTeam[1].name)|| '',
          position2: (currentTeam[1] && currentTeam[1].position) || '',
          briefProfile2: (currentTeam[1] && currentTeam[1].briefProfile) || '',
        }));

        // eslint-disable-next-line no-debugger
        //debugger;
        if (currentTeam[0] && currentTeam[0].startupImage) {
          //console.log('startupLogo: ', startupLogo);
          const {startupImage} = currentTeam[0];
          const checkType = (startupImage !== undefined && startupImage !== null && startupImage.constructor == Object);
          //console.log('checkType: ', checkType);
          if(!checkType) {
            setImage1(JSON.parse(startupImage));
            setState((prev) => ({
              ...prev,
              startupImage1: JSON.parse(startupImage),
            }));
          } else {
            setImage1(startupImage);
            setState((prev) => ({
              ...prev,
              startupImage1: startupImage,
            }));
          }
          
        }
  
        
        if (currentTeam[1] && currentTeam[1].startupImage) {
          const {startupImage} = currentTeam[1];
          //console.log('startupLogo: ', startupLogo);
          
          const checkType = (startupImage !== undefined && startupImage !== null && startupImage.constructor == Object);
          //console.log('checkType: ', checkType);
          if(!checkType) {
            setImage2(JSON.parse(startupImage));
            setState((prev) => ({
              ...prev,
              startupImage2: JSON.parse(startupImage),
            }));
          } else {
            setImage2(startupImage);
            setState((prev) => ({
              ...prev,
              startupImage2: startupImage,
            }));
          }
          
        }

        // console.log('state: ', state);
      }
      
      
      
      // eslint-disable-next-line react/prop-types
      props.setLoading(false);
    }
  }, [auth]);
  
  const handleStateChange = (event) => {
    const name = event.target.name;
    //console.log(name, event.target.vale);
    setState({
      ...state,
      [name]: event.target.value,
    }); 
  };

  const handleCloseTermDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };

  const onSave = (e) => {
    // eslint-disable-next-line react/prop-types
    props.setLoading(true);
    e.preventDefault();

    const currentTeam = [];

    currentTeam.push({
      name: state.name1 || '',
      position: state.position1 || '',
      briefProfile: state.briefProfile1 || '',
      startupImage: image1,
    });
    currentTeam.push({
      name: state.name2 || '',
      position: state.position2 || '',
      briefProfile: state.briefProfile2 || '',
      startupImage: image2,
    });

    const startup = {...auth.drafSignup,
      currentTeam: currentTeam,
    };

    //console.log('startup: ', startup);

    startupRegisterApi(common.currentUser.token,startup)
        // eslint-disable-next-line no-unused-vars
        .then((resp) => {
          //console.log(resp);
          dispatch({ type: SAVE_DRAF_SIGNUP, payload: startup });
          props.validToNext(true);
          props.handleComplete(); // next page
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          // eslint-disable-next-line react/prop-types
          props.setLoading(false);
          setErrors(error);
          //dispatch({type: API_RESPONSE_ERROR, payload: error.message})
          return;
        });

    // eslint-disable-next-line react/prop-types
    props.setLoading(false);
  };

  return (
    <div>
      {titleMobile()}
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          {/*<AvatarUpload id={1}
          // eslint-disable-next-line react/prop-types
          setLoading={(r) => props.setLoading(r)}
          folder='team'
          imageName='startupImage1'
          resize
          autoSave
          imagecloud={(state.startupImage1) || undefined}
          clearImage={setImage1}
          result={(d) => {
            setImage1(d);
          }}
        />*/}
        <AvatarUpload2 id={1} 
        // eslint-disable-next-line react/prop-types
        setLoading={(r) => props.setLoading(r)}
        imagecloud={(state.startupImage1) || undefined}
        clearImage={() => {
          setImage1(null);
          setState((prev) => ({
            ...prev,
            startupImage1: null,
          }));
        }}
        handleClick={() =>  setOpenDialog(true)}
        />
        <CropImageDialog folder='team' gid={common.gid} 
        result={(d) => {
          // console.log(d);
          setImage1(d);
          setState((prev) => ({
            ...prev,
            startupImage1: d,
          }));
          dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'Upload Image', message: 'Upload image successful'}});
          setOpenDialog(false);
        }}
        imageName='startupImage1' handle={handleCloseTermDialog} openDialog={openDialog}
        />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='center'
        alignItems='center'
      >
      
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name1',
                type: 'text',
                value: (state.name1) || '',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                //value: state.email,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='center'
        alignItems='center'
      >
      
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Position'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'position1',
                value: (state.position1) || '',
                type: 'text',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                //value: state.email,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='center'
        alignItems='center'
      >
      
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Brief profile'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'briefProfile1',
                value: (state.briefProfile1) || '',
                type: 'text',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                //value: state.email,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          <AvatarUpload2 id={2} 
          // eslint-disable-next-line react/prop-types
          setLoading={(r) => props.setLoading(r)}
          imagecloud={(state.startupImage2) || undefined}
          clearImage={() => {
            setImage2(null);
            setState((prev) => ({
              ...prev,
              startupImage2: null,
            }));
          }}
          handleClick={() =>  setOpenDialog2(true)}
          />
          <CropImageDialog folder='team' gid={common.gid} 
          result={(d) => {
            setImage2(d);
            setState((prev) => ({
              ...prev,
              startupImage2: d,
            }));
            dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'Upload Image', message: 'Upload image successful'}});
            setOpenDialog2(false);
          }}
          imageName='startupImage2' handle={handleCloseDialog2} openDialog={openDialog2}
          />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='center'
        alignItems='center'
      >
      
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'name2',
                value: (state.name2) || '',
                type: 'text',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                //value: state.email,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='center'
        alignItems='center'
      >
      
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Position'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'position2',
                value: (state.position2) || '',
                type: 'text',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                //value: state.email,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='center'
        alignItems='center'
      >
      
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Brief profile'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'briefProfile2',
                value: (state.briefProfile2) || '',
                type: 'text',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                //value: state.email, 
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={2} item>
          <p className={classes.divider}> </p>
          <Button 
            onClick={onSave}
            block
            color='danger'
            size='lg'
          >
            Save
          </Button>
        </Grid>
      </Grid>

      
    </div>
  );
};

export default Team;