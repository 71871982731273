import React from 'react';
import { useSelector } from 'react-redux';
import {investorRegisterApi} from 'api/investor';
import {investorProfileApi} from 'api/investor';
import {updateInvestorProfileApi} from 'api/investor';

function useInvestor() {
  const {common} = useSelector((state) => ({...state}));
  const [dataInvestor, setDataInvestor] = React.useState([]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // error state
  const [error, setError] = React.useState("");

  async function handleUpdateInvestorProfile(investor) {
    return await updateInvestorProfileApi(common.currentUser.token, investor);
  }

  async function postRegister(investor) {
    setLoading(true);
    setError("");
    const response = await investorRegisterApi(common.currentUser.token, investor);
    setDataInvestor((response && response.data) || []);
    setLoading(false);
  }

  async function fetchDataProfile(investor) {
    setLoading(true);
    setError("");
    const response = await investorProfileApi(common.currentUser.token, investor);
    setDataInvestor((response && response.data) || []);
    setLoading(false);
  }

  const onRegisterInvestor = (investor) => {
    setDataInvestor([]);
    postRegister(investor)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  };

  const onGetInvestorProfile = (investorname) => {
    setDataInvestor([]);
    fetchDataProfile(investorname)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  const onUpdateInvestorProfile = async (investor) => {
    await handleUpdateInvestorProfile(investor)
    .then((res) => console.log(res))
    .catch(error => { console.log(error.response || error); setError(error.response?.data || error.response);});
  }

  return {dataInvestor, onGetInvestorProfile, onRegisterInvestor, onUpdateInvestorProfile, loading, error};
}

export default useInvestor;