import axios from 'axios';

export const sendMessageApi = async ({name, email, message}) => {
  return await axios.post(`${process.env.REACT_APP_API}/sendmail`, {name, email, message});
};

export const sendInviteApi = async ({name, email, message, link}, authtoken) => {
  return await axios.post(`${process.env.REACT_APP_API}/invite`, 
  {
    name, email, message, link
  }, 
  {
    headers: {
        authtoken: authtoken,
        "Content-Type": "application/json",
    },
  });
};