import React from 'react';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
// import PinDropIcon from '@material-ui/icons/PinDrop';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
// import IconButton from '@material-ui/core/IconButton';
// eslint-disable-next-line no-unused-vars
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import { STATUS_MODAL_SHOW } from 'constants/actionTypes';
import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';

import useMessage from 'hooks/useMessage';
import validateEmail from 'functions/validations/email';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(styles);

const initialState = {name: '', email: '', message: ''};

export default function WorkSection() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  const [emailValidate, setEmailValidate] = React.useState(false);
  const {error: errorMessage, result, onSendMessage, loading} = useMessage();


  React.useEffect(() => {
    //console.log(result);
    if(result) {
      dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'SEND MESSAGE', message: 'Message sent successful'}});
      setState(initialState);
    }

  }, [result]);

  React.useEffect(() => {
    if(loading){
      dispatch({type: APP_LOAD});
    } else {
      dispatch({type: APP_LOADED});
    }
  }, [loading]);

  React.useEffect(() => {
    errorMessage && console.log('errorMessage', errorMessage);
    errorMessage && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorMessage.error.message}});
  }, [errorMessage]);

  const handleChange = (event) => {
    // console.log(event.target.name, ' : ' , event.target.value)
    if(event.target.name === 'email') {
      const emailValid = validateEmail(event.target.value);
      setEmailValidate(emailValid);
    }
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(state, emailValidate);
    if(emailValidate) {
      onSendMessage(state);
    } else {
      dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: 'email is require'}});
    }
    
  };

  return (
    <div className={classes.section}>
      <GridContainer justify='center'>
        <GridItem cs={12} sm={12} md={8}>
          <h3 className={classes.title}>Contact us</h3>

          <h5 className={classes.title}>
            Address: SYN HUB Co-Innovative Space,
            Pantip Plaza Pratunam, Room 4121, 4125, 4th floor, 604/3 Petchaburi Road, Ratchatavee, Bangkok 10400
          </h5>
          <h6 className={classes.title}>
            Email: contact@yakstart.com <br/>
            Tel: (+66) 098-271-6660
          </h6>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText='Your Name (Option)'
                  id='name'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'name',
                    type: 'text',
                    onChange: (event) => handleChange(event),
                    value: state.name, 
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText='Your Email'
                  id='email2'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: 'email',
                    type: 'email',
                    error: !emailValidate,
                    onChange: (event) => handleChange(event),
                    value: state.email, 
                  }}
                />
              </GridItem>
              <CustomInput
                labelText='Your Message (Option)'
                id='message'
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea,
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  name: 'message',
                  type: 'text',
                  onChange: (event) => handleChange(event),
                  value: state.message,
                }}
              />
              <GridItem xs={12} sm={12} md={4}>
                <Button onClick={handleSubmit} color='primary'>Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
