/* eslint-disable no-debugger */
import React from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
// @material-ui/icons
// import Camera from "@material-ui/icons/Camera";
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
// import Palette from "@material-ui/icons/Palette";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
// import Favorite from "@material-ui/icons/Favorite";
import GroupAddIcon from '@material-ui/icons/GroupAdd';

// core components
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HeaderLinks from "components/Header/HeaderLinks";
import NavPills from "components/NavPills/NavPills";
import Parallax from "components/Parallax/Parallax";
import Paper from '@material-ui/core/Paper';
import CustomDialog from 'components/CustomDialogs/CustomDialogs';
import AlertSignupForm from 'views/StartupInfoPage/components/AlertSignupForm';

import useStartup from 'hooks/useStartup';
import {APP_LOAD, APP_LOADED, ERROR_MODAL_SHOW} from 'constants/actionTypes';

// eslint-disable-next-line no-unused-vars
import profile from "assets/img/faces/christian.jpg";

// eslint-disable-next-line no-unused-vars
import work4 from "assets/img/examples/mariya-georgieva.jpg";

import styles from "assets/jss/material-kit-react/views/startupInfoPage.js";
import imageBg from "assets/img/profile-bg.jpg";

import Summary from 'views/StartupInfoPage/Sections/Summary';
import Financials from 'views/StartupInfoPage/Sections/Financials';
import BusinessModel from 'views/StartupInfoPage/Sections/BusinessModel';
import TeamCard from 'views/StartupInfoPage/Sections/TeamCard';

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      //hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
};

const StartupInfoPage = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { common } = useSelector((state) => ({ ...state }));
  // eslint-disable-next-line no-unused-vars
  const {data, onGetStartup, error: errorUseStartup, loading} = useStartup();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle, // imgRounded imgRoundedCircle
    classes.imgFluid
  );
  // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  //const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [titleDialog, setTitleDialog] = React.useState('');
  const [contentDialog, setContentDialog] = React.useState();

  React.useEffect(() => {
    dispatch({type: APP_LOAD});
  }, []);

  React.useEffect(() => {
    loading || !data.startupName || !common.currentUser && dispatch({type: APP_LOAD});
    !loading && data.startupName && common.currentUser && dispatch({type: APP_LOADED});
    // console.log(data);
  }, [loading, data, common.currentUser]);

  React.useEffect(() => {
    errorUseStartup && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorUseStartup.error.message || errorUseStartup.error}});
  }, [errorUseStartup]);

  React.useEffect(() => {
    if(common.currentUser && !data.startupName) {
      if(window.location && window.location.search && window.location.search.indexOf('?p=') >= 0){
        const startup = window.location.search.replace('?p=', '');
        // console.log(startup);
        onGetStartup(startup);
      }
    }
    //console.log(common.currentUser);
  }, [common.currentUser]);

  const confidenceStatusContent = () => {
    return (
      <React.Fragment>
        <Typography gutterBottom>
          Explain about confidence status
        </Typography>
        
      </React.Fragment>
    );
  };

  const contactInfoContent = () => {
    return (
      <React.Fragment>
        <Typography gutterBottom>
          Contact info
        </Typography>
        <Typography gutterBottom>
          Name: {data.contactName || '...'}
        </Typography>
        <Typography gutterBottom>
          Tel: {data.tel || '...'}
        </Typography>
        <Typography gutterBottom>
          email: {data.email || '...'}
        </Typography>
        <Typography gutterBottom>
          website: {data.linkOfWebsite || '...'}
        </Typography>
      </React.Fragment>
    );
  };

  // React.useEffect(() => {
  //   console.log(openDialog, titleDialog);
  // }, []);

  // const handleChange = (event, newValue) => {
  //   console.log(event.target, newValue);
  //   setValue(newValue);
  // };

  const handleOpenDialog = (e, title) => {
    e.preventDefault();

    console.log(title);
    if(title === 'confidenceStatus') {
      setTitleDialog('Confidence Status');
      setContentDialog(confidenceStatusContent);
    } else if (title === 'contactInfo') {
      setContentDialog(contactInfoContent);
      setTitleDialog('Contact info and website');
      
    }

    setOpenDialog(true);
  };

  const handleCloseDialog = (e) => {
    e.preventDefault();

    setOpenDialog(false);
  };

  return (
    <div>
      <Header
        color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white',
        }}
        {...rest}
      />
      <Parallax small filter image={imageBg} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <CustomDialog
          title={titleDialog}
          isOpen={openDialog}
          handleClose={handleCloseDialog}
          content={contentDialog}
        />
        <div>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img
                      src={
                        data.startupLogo2 && data.startupLogo2[0] ? data.startupLogo2[0].url : `https://www.mintformations.co.uk/blog/wp-content/uploads/2020/05/shutterstock_583717939.jpg`
                      }
                      alt='...'
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{ data.startupName || 'LOADING...'}</h3>
                    <h6></h6>
                    {/* <GridContainer
                      justify='center'
                      alignItems='center'
                      direction='row'
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <Button
                          color='success'
                          className={classes.margin5}
                          onClick={(e) =>
                            handleOpenDialog(e, 'confidenceStatus')
                          }
                        >
                          <i className={'fas fa-flag-checkered'} /> Confidence
                          Status: Pass
                        </Button>
                      </GridItem>
                        </GridContainer> */}
                    <GridContainer
                      justify='center'
                      alignItems='center'
                      direction='row'
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <Button
                          color='info'
                          className={classes.margin5}
                          onClick={(e) => handleOpenDialog(e, 'contactInfo')}
                        >
                          <i className={'fab fa-facebook'} /> Contact info and
                          website
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color='primary'
                  tabs={[
                    {
                      tabButton: 'Summary',
                      tabIcon: FeaturedPlayListIcon,
                      tabContent: (
                        <GridContainer justify='center'>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ margin: 0 }}
                          >
                            <TabPanel index={0}>
                              <Summary data={data} />
                            </TabPanel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}></GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: 'Financials',
                      tabIcon: MonetizationOnIcon,
                      tabContent: (
                        <GridContainer justify='center'>
                          <GridItem xs={12} sm={12} md={12}>
                            <TabPanel index={1}>
                              <Financials data={data} />
                            </TabPanel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}></GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: 'Business Model',
                      tabIcon: BusinessCenterIcon,
                      tabContent: (
                        <GridContainer justify='center'>
                          <GridItem xs={12} sm={12} md={12}>
                            <TabPanel index={1}>
                              <BusinessModel data={data} />
                            </TabPanel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}></GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: 'People',
                      tabIcon: GroupAddIcon,
                      tabContent: (
                        <GridContainer justify='center'>
                          {common.currentUser && !common.currentUser.isInvestor && <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
                          <Paper elevation={5}>
                            <GridContainer>
                              <GridItem>
                                {!common.currentUser.isInvestor && <AlertSignupForm />}
                              </GridItem>
                            </GridContainer>
                          </Paper>
                        </GridItem>}
                          {common.currentUser && common.currentUser.isInvestor && <GridItem xs={12} sm={12} md={4}>
                            <TeamCard
                              image={data.currentTeam && data.currentTeam[0] && data.currentTeam[0].startupImage && data.currentTeam[0].startupImage.url}
                              title={data.currentTeam && data.currentTeam[0] && data.currentTeam[0].name}
                              subheader={data.currentTeam && data.currentTeam[0] && data.currentTeam[0].position}
                              description={data.currentTeam && data.currentTeam[0] && data.currentTeam[0].briefProfile}
                            />
                          </GridItem>}
                          {common.currentUser && common.currentUser.isInvestor && <GridItem xs={12} sm={12} md={4}>
                            <TeamCard
                              image={data.currentTeam && data.currentTeam[1] && data.currentTeam[1].startupImage && data.currentTeam[1].startupImage.url}
                              title={data.currentTeam && data.currentTeam[1] && data.currentTeam[1].name}
                              subheader={data.currentTeam && data.currentTeam[1] && data.currentTeam[1].position}
                              description={data.currentTeam && data.currentTeam[1] && data.currentTeam[1].briefProfile}
                            /> 
                          </GridItem>}
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StartupInfoPage;