const style = {
  // root: {
  //   boxSizing: 'border-box',
  //   fontFamily: '"Overpass", sans-serif',
  //   height: '30vh',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  // tagsInput: {
  //   display: 'flex',
  //   alignItems: 'flex-start',
  //   flexWrap: 'wrap',
  //   minHeight: '48px',
  //   // "@media (min-width: 576px)": {
  //   //   width: 'calc(100vw - 32px)',
  //   // },
  //   // width: '10px',
  //   padding: '0 8px',
  //   border: '1px solid rgb(214, 216, 218)',
  //   borderRadius: '6px',
  //   "&:focus-within": {
  //     border: '1px solid #0052cc',
  //   }
  // },
  // input: {
  //   flex: '1',
	// 	border: 'none',
	// 	height: '46px',
	// 	fontSize: '14px',
	// 	padding: '4px 0 0 0',
	// 	"&:focus": {
	// 		outline: 'transparent',
	// 	},
  // },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0',
    margin: '8px 0 0 0',
  },
  tag: {
    width: 'auto',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    padding: '0 8px',
    fontSize: '14px',
    listStyle: 'none',
    borderRadius: '6px',
    margin: '0 8px 8px 0',
    background: '#0052cc',
  },
  tagTitle: {
    marginTop: '3px',
  },
  tagCloseIcon: {
    display: 'block',
		width: '16px',
		height: '16px',
		lineHeight: '16px',
		textAlign: 'center',
		fontSize: '14px',
		marginLeft: '8px',
		color: '#0052cc',
		borderRadius: '50%',
		background: '#fff',
		cursor: 'pointer',
  },
};

export default style;