import React from 'react';
import PropTypes from 'prop-types';

const CustomTable = (props) => {
  const {tableData, headingColumns, title, breakOn = 'medium'} = props;
  let tableClasses = 'table-container__table';

  if(breakOn === 'small') {
    tableClasses += ' table-container__table--break-sm';
  }else if(breakOn === 'medium') {
    tableClasses += ' table-container__table--break-md';
  }else if(breakOn === 'large') {
    tableClasses += ' table-container__table--break-lg';
  }
 
  return (
    <div className='table-container'>
      <div className='table-container__title'>
        <h5>{title}</h5>
      </div>
      <table className={tableClasses}>
        <thead>
          <tr>
            {headingColumns.map((col, index) => {
              //console.log(col, index);
              return (
                <th key={index}>{col}</th>
              );
            })}
          </tr>
        </thead>
        {tableData ? (<tbody>{
          tableData.map((row, index) => {
    
            let rowData = [];
            let i = 0;
            for(const key in row){
              rowData.push({
                key: headingColumns[i],
                value: row[key],
              });
              i++;
            }
        
            
            return (
              <tr key={index}>
                {rowData.map((data, index) => {
                  //console.log(data, index);
                  return (
                    <td key={index} data-heading={data.key}>
                      {data.value}
                    </td>
                  );
                })}
              </tr>
            );
          })
        }</tbody>) : null}
      </table>
    </div>
  );
};

CustomTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  headingColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  breakOn: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default CustomTable;