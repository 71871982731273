export const APP_LOAD = 'APP_LOAD';
export const APP_LOADED = 'APP_LOADED';
export const REDIRECT = 'REDIRECT';
export const LOGIN = 'LOGIN';
export const LOGGED_IN_USER = 'LOGGED_IN_USER';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const SEARCH_QUERY = 'SEARCH_QUERY';
export const LOAD_GUEST_ID = 'LOAD_GUEST_ID';
export const SAVE_DRAF_SIGNUP = 'SAVE_DRAF_SIGNUP';
export const CLEAR_DRAF_SIGNUP = 'CLEAR_DRAF_SIGNUP';
export const ERROR_MODAL_SHOW = 'ERROR_MODAL_SHOW';
export const ERROR_MODAL_CLOSE = 'ERROR_MODAL_CLOSE';
export const STATUS_MODAL_SHOW = 'STATUS_MODAL_SHOW';
export const STATUS_MODAL_CLOSE = 'STATUS_MODAL_CLOSE';
