/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Primary from "components/Typography/Primary";

const style = {
  card: {
    width: '200px',
    margin: '1rem',
    // backgroundColor: '#373b69',
    borderRadius: '3px',
    boxShadow: '0 3px 5px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    position: 'relative',
  },
  img: {
    maxWidth: '100%',
  },
  title: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
    padding: '0.2rem',
  },
  h6: {
    margin: 0,
  },
  over: {
    backgroundColor: '#fff',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: '#22254b',
    padding: '0.2rem',
    "&:not(:first-child)": {
      transform: "translate3d(0, 100%, 0)"
    },
    "&:hover": {
      transform: "translate3d(0, -50%, 0)"
    }
  },
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({... style,}));

// eslint-disable-next-line no-unused-vars
const StartupCard = (props) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const {title, imageUrl, overview, vote_average, onClick} = props;

  const handleOnclick = () => {
    onClick();
  };

  return (
    <div className={classes.card}>
      <ButtonBase onClick={handleOnclick}>
        <img className={classes.img} src={imageUrl} alt={title} />
      </ButtonBase>
      
      <div className={classes.title}>
        <Primary className={classes.h6}>{title}</Primary>
      </div>

      {/*<div className={classes.over}>
        <p>Overview:</p>
        <p>{overview}</p>
      </div>*/}
    </div>
  );
};

export default StartupCard;