/* eslint-disable react/prop-types */
import React from 'react';
// import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';

import Search from 'views/StartupMarket/Sections/Search';

import useStartupMarket from 'hooks/useStartupMarket';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import typographyStyle from 'assets/jss/material-kit-react/views/componentsSections/typographyStyle.js';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  ...typographyStyle,
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
});

StartupListMobile.propTypes = {
  data: PropTypes.array,
};

// eslint-disable-next-line no-unused-vars
export default function StartupListMobile(props) {
  //const {search} = useSelector((state) => ({...state}));
  const dispatch = useDispatch();
  const classes = useStyles2();

  const {onGetAll, data, onSearch, error: errorStartupMarket} = useStartupMarket();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [startupMarketData, setStartupMarketData] = React.useState([]);

  React.useEffect(() => {
    if(data.length <= 0) {
      onGetAll();
    }
    setStartupMarketData(data);
  }, []);

  React.useEffect(() => {
    setStartupMarketData(data);
  }, [data]);

  React.useEffect(() => {
    errorStartupMarket && console.log('errorStartupMarket', errorStartupMarket)
    errorStartupMarket && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on search startup market', message: errorStartupMarket.error.message}});
  }, [errorStartupMarket]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, startupMarketData.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <div>
      
      <Grid
        container
        item
        xs={12}
        md={12}
        justify='flex-start'
        alignItems='flex-start'
      >
        <Search onSearch={onSearch} />
      </Grid>
      <Grid container item xs={12} md={12} justify='center' alignItems='center'>
        <div className={classes.typo}>
          <h3>Startup market</h3>
        </div>
      </Grid>

      <Grid container direction='row' justify='center' alignItems='center'>
        <Grid container item xs={12} spacing={3}>
          <TableContainer component={Paper}>
            <Table
              style={{ marginTop: '0px' }}
              className={classes.table}
              aria-label='custom pagination table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Company name</StyledTableCell>
                  <StyledTableCell align='left'>Industry</StyledTableCell>
                  <StyledTableCell align='left'>Website</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? startupMarketData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : startupMarketData
                ).map((row) => (
                  <TableRow key={row.startupName}>
                    <TableCell style={{ width: 200 }} component='th' scope='row'>
                      {row.startupName}
                    </TableCell>
                    <TableCell  style={{ width: 200 }} align='left'>
                      {row.industry}
                    </TableCell>
                    <TableCell align='left'>
                    <a href={`${row.website}`} rel="noopener noreferrer" target="_blank">{row.website}</a>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={3}
                    count={startupMarketData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
