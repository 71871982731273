import axios from 'axios';

export const authLoginApi = async ({role, authtoken}) => {

    return await axios.post(`${process.env.REACT_APP_API}/auth`, {role}, {
        headers: {
            authtoken: authtoken,
            role: role,
            "Content-Type": "application/json",
        },
    });
};

export const authSignupApi = async (user) => {

    return await axios.post(`${process.env.REACT_APP_API}/signup`, user, {
        headers: {
            "Content-Type": "application/json",
        },
    });
};

export const authCurrentUserApi = async ({authtoken}) => {

    return await axios.post(`${process.env.REACT_APP_API}/current-user`, {}, {
        headers: {
            authtoken: authtoken,
            "Content-Type": "application/json",
        },
    });
};

export const currentInvestorApi = async (authtoken) => {

    return await axios.post(`${process.env.REACT_APP_API}/current-investor`, {}, {
        headers: {
            authtoken: authtoken,
            "Content-Type": "application/json",
        },
    });
};

export const currentStartupApi = async (authtoken) => {

    return await axios.post(`${process.env.REACT_APP_API}/current-startup`, {}, {
        headers: {
            authtoken: authtoken,
            "Content-Type": "application/json",
        },
    });
};