import React from 'react';
import { useSelector } from 'react-redux';
import {startupMarketsApi} from 'api/startupMarket';
import {deleteStartupMarketApi} from 'api/startupMarket';
import {createStartupMarketApi} from 'api/startupMarket';
import {searchStartupMarketApi} from 'api/startupMarket';

function useStartupMarket() {
  const {common} = useSelector((state) => ({...state}));
  // loading
  const [loading, setLoading] = React.useState(false);
  // error state
  const [error, setError] = React.useState("");
  // result
  const [data, setData] = React.useState([]);

  async function fetchData() {
    setLoading(true);
    setError("");
    const response = await startupMarketsApi();
    setData((response && response.data) || []);
    setLoading(false);
  }

  async function delData(startupName) {
    setLoading(true);
    setError("");
    const delResponse = await deleteStartupMarketApi({token: common.currentUser.token, startupName});

    if(delResponse && delResponse.data.status === 'success') {
      const response = await startupMarketsApi(common.currentUser.token);
      setData((response && response.data) || []);
    }
    
    setLoading(false);
  }

  async function createData(startupMarket) {
    setLoading(true);
    setError("");
    const createResponse = await createStartupMarketApi({token: common.currentUser.token, startupMarket});
    //console.log(createResponse)
    if(createResponse && createResponse.data.status === 'success') {
      const response = await startupMarketsApi(common.currentUser.token);
      setData((response && response.data) || []);
    }
    
    setLoading(false);
  }

  async function searchData({textSearch, enumSearch}) {
    setLoading(true);
    setError("");
    const response = await searchStartupMarketApi({textSearch, enumSearch});
    //console.log(response)
    setData((response && response.data) || []);
    
    setLoading(false);
  }

  const onGetAll = () => {
    setData([]);
    fetchData()
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  const onDelete = (startupName) => {
    delData(startupName)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  };

  const onCreate = (startupMarket) => {
    createData(startupMarket)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  };

  const onSearch = ({textSearch, enumSearch}) => {
    searchData({textSearch, enumSearch})
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  };

  return {data, onCreate, onGetAll, onSearch, onDelete, loading, error};
}

export default useStartupMarket;