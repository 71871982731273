import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import { title } from "assets/jss/material-kit-react.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  section: {
    padding: "10px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...modalStyle,
  modalHeader: {
    borderBottom: "none",
    paddingTop: "14px",
    paddingRight: "4px",
    paddingBottom: "0",
    paddingLeft: "4px",
    minHeight: "16.43px",
    backgroundColor: '#eee',
  },
  modalBody: {
    paddingTop: "4px",
    paddingRight: "4px",
    paddingBottom: "6px",
    paddingLeft: "4px",
    position: "relative",
    backgroundColor: '#eee',
  },
  cardBorderRadius36: {
    borderRadius: "36px",
  },
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
});

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const AlertSignupForm = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.section} style={{
      // backgroundImage: "url(" + image + ")",
      backgroundColor: '#eee',
      padding: "1rem",
      borderRadius: "0px",
    }}>
      <h4 className={classes.title}>This data is exclusively for only investors</h4>
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}></GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card className={classes.cardBorderRadius36}>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
            <h4 className={classes.cardTitle}>
            Sign up, If you are an investor    
            </h4>
            </GridItem>
            
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
            <Button round color="facebook" onClick={() => {
              return history.push(`${process.env.REACT_APP_INVESTOR_SIGNUP_PUSH_URL}?p=signup`);
            }}>
              Sign up
            </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
            <p className={classes.cardTitle}> </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
            <p className={classes.cardTitle}>Already have an account?</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
            <Button round color="google" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
              Login
            </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
            <p className={classes.cardTitle}> </p>
            </GridItem>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}></GridItem>
        

      </GridContainer>
    </div>
  );
};

export default AlertSignupForm;