/* eslint-disable react/prop-types */
import React from 'react';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import TagsInput from 'components/TagsInput/TagsInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

// eslint-disable-next-line no-unused-vars
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import { STATUS_MODAL_SHOW } from 'constants/actionTypes';
import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';

import useMessage from 'hooks/useMessage';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(styles);

const initialState = {name: '', email: '', message: 'Your friend have invite you to join.', link: process.env.REACT_APP_HOME_LINK};

export default function InviteSection(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  // eslint-disable-next-line no-unused-vars
  const {error: errorMessage, result, onSendInvite, loading} = useMessage();
  const [tags, _setTags] = React.useState([]);
  const {data} = props;

  React.useEffect(() => {
    //console.log(result);
    if(result) {
      dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'SEND MESSAGE', message: 'Invite sent successful, please tell your friends to check email inbox or spam/junk email folder'}});
      setState(initialState);
    }

  }, [result]);

  React.useEffect(() => {
    if(loading){
      dispatch({type: APP_LOAD});
    } else {
      dispatch({type: APP_LOADED});
    }
  }, [loading]);

  React.useEffect(() => {
    errorMessage && console.log('errorMessage', errorMessage);
    errorMessage && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorMessage.error.message}});
  }, [errorMessage]);

  const setTags = (v) => {
    // console.log(v);
    _setTags(v);
    setState({ ...state, ['email']: v.toString().replaceAll('\n', '') });
  };
  
  const handleChange = (event) => {
    // console.log(event.target.name, ' : ' , event.target.value);
    setState({ ...state, [event.target.name]: event.target.value });
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(state);
    // eslint-disable-next-line no-constant-condition
    if(!state.name) {
      dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: 'startup name is require'}});
      return;
    }
    if(!tags || tags.length < 1) {
      dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: 'email is require'}});
      return;
    }
    onSendInvite(state);

    setState(null);
    setTags([]);
  };

  return (
    <div className={classes.section}>
      <GridContainer justify='center'>
        <GridItem cs={12} sm={12} md={8}>
          <h3 className={classes.title}>Invite friends</h3>

          <form>
            <GridContainer direction="column" >
              <GridItem xs={12} sm={12} md={6}>
                {data && (
                  <>
                    <FormControl fullWidth margin='dense'>
                      <InputLabel htmlFor='name-native-helper'>
                        Startup name
                      </InputLabel>
                      <NativeSelect
                        value={state.name}
                        onChange={handleChange}
                        inputProps={{
                          name: 'name',
                          id: 'name-native-helper',
                        }}
                      >
                        <option aria-label='None' value='' />
                        {data.map((item, i) => {
                          return (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </NativeSelect>
                    </FormControl>
                  </>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <TagsInput tags={tags} setTags={setTags} />
              </GridItem>
              
              <GridItem xs={12} sm={12} md={4}>
                <Button onClick={handleSubmit} color='primary'>Send Invites</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
