import axios from 'axios';

export const startupMarketsApi = async () => {

  return await axios.get(`${process.env.REACT_APP_API}/startupmarkets`);
};

export const deleteStartupMarketApi = async ({token: authtoken, startupName}) => {

  return await axios.delete(`${process.env.REACT_APP_API}/startupmarket/${startupName}`, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const createStartupMarketApi = async ({token: authtoken, startupMarket}) => {

  return await axios.post(`${process.env.REACT_APP_API}/startupmarket`, {startupMarket}, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const searchStartupMarketApi = async ({textSearch, enumSearch}) => {

  return await axios.post(`${process.env.REACT_APP_API}/startupmarket/search`, {textSearch, enumSearch});
};