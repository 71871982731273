import image from 'assets/img/faces/avatar.jpg';
import image2 from 'assets/img/faces/avatar.jpg';
import image3 from 'assets/img/faces/avatar.jpg';
import image4 from 'assets/img/faces/avatar.jpg';
import image5 from 'assets/img/faces/avatar.jpg';
import image6 from 'assets/img/faces/avatar.jpg';
import image7 from 'assets/img/faces/avatar.jpg';
import image8 from 'assets/img/faces/avatar.jpg';
import image9 from 'assets/img/faces/avatar.jpg';
import image10 from 'assets/img/faces/avatar.jpg';
import image11 from 'assets/img/faces/avatar.jpg';
import image12 from 'assets/img/faces/avatar.jpg';
import image13 from 'assets/img/faces/avatar.jpg';
import image14 from 'assets/img/faces/avatar.jpg';
import image15 from 'assets/img/faces/avatar.jpg';
import image16 from 'assets/img/faces/avatar.jpg';

const tileData = [
  {
    img: image,
    title: 'Image',
    author: 'author',
    // rows: 2,
    // cols: 2,
  },
  {
    img: image2,
    title: 'Image',
    author: 'author',
  },
  {
    img: image3,
    title: 'Image',
    author: 'author',
  },
  {
    img: image4,
    title: 'Image',
    author: 'author',
  },
  {
    img: image5,
    title: 'Image',
    author: 'author',
  },
  {
    img: image6,
    title: 'Image',
    author: 'author',
  },
  {
    img: image7,
    title: 'Image',
    author: 'author',
  },
  {
    img: image8,
    title: 'Image',
    author: 'author',
  },
  {
    img: image9,
    title: 'Image',
    author: 'author',
  },
  {
    img: image10,
    title: 'Image',
    author: 'author',
  },
  {
    img: image11,
    title: 'Image',
    author: 'author',
  },
  {
    img: image12,
    title: 'Image',
    author: 'author',
  },
  {
    img: image13,
    title: 'Image',
    author: 'author',
  },
  {
    img: image14,
    title: 'Image',
    author: 'author',
  },
  {
    img: image15,
    title: 'Image',
    author: 'author',
  },
  {
    img: image16,
    title: 'Image',
    author: 'author',
  },
];

export default tileData;
