/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import AvatarUpload from 'components/AvatarUpload/AvatarUpload';

import {parseToName} from 'functions/country';

import { container } from "assets/jss/material-kit-react.js";
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  ... container,
  ... styles,  
  container: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  root: {
    flexShrink: 0,
    //marginLeft: theme.spacing(0),
    padding: '20px',
    //backgroundColor: '#fff',
    //width: '90%',
    //margin: '10px',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: '20px',
    paddingLeft: '30px',
  },
  gridContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#ddd',
  },
}));

// eslint-disable-next-line no-unused-vars
const GeneralInfoSection = (props) => {
  const {data} = props;

  // console.log(data);

  const classes = useStyles();

  return (
    <React.Fragment>
    <Grid container spacing={3} className={classes.gridContainer}>
      <Grid item xs={12} sm={12}>
        <AvatarUpload 
          displayOnly 
          imagecloud={data.startupLogo2 && data.startupLogo2.length > 0 ? data.startupLogo2[0] : undefined} 
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="startupName"
          name="startupName"
          label="Business/CompanyName"
          fullWidth
          value={data.startupName || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="industry"
          name="industry"
          label="Industry of business"
          fullWidth
          value={data.startupIndustry || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="contactName"
          name="contactName"
          label="Contact name"
          fullWidth
          value={data.contactName || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="keyTechnology"
          name="keyTechnology"
          label="Key of technology"
          fullWidth
          value={data.keyTechnology || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="email"
          name="email"
          label="Email"
          fullWidth
          value={data.email || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="operatingCountry"
          name="operatingCountry"
          label="The current operating country"
          fullWidth
          value={parseToName(data.operatingCountry) || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="tel"
          name="tel"
          label="Tel"
          fullWidth
          value={data.tel || '...'}
          //autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="foundedDate"
          name="foundedDate"
          label="Founded Date"
          fullWidth
          value={data.foundedDate && moment.unix(data.foundedDate).format('DD MMMM YYYY') || '...'}
          //autoComplete="family-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="startupLastFundingType"
          name="startupLastFundingType"
          label="Last funding type"
          fullWidth
          value={data.startupLastFundingType || '...'}
          //autoComplete="family-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="numberOfEmployee"
          name="numberOfEmployee"
          label="Number of employee"
          fullWidth
          value={data.numberOfEmployee || '...'}
          //autoComplete="family-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          //required
          id="statusOfBusiness"
          name="statusOfBusiness"
          label="State of business"
          fullWidth
          value={data.statusOfBusiness || '...'}
          //autoComplete="family-name"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          //required
          id="aboutus"
          name="aboutus"
          label="About us"
          fullWidth
          value={data.aboutus || '...'}
          //autoComplete="shipping address-line1"
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          id="businessIntroPdf2"
          name="businessIntroPdf2"
          label={`Business introduction (PDF) ${data.businessIntroPdf2 && data.businessIntroPdf2.length > 0 ? '[click to open link]' : ''}`}
          fullWidth
          onClick={() => data.businessIntroPdf2 && data.businessIntroPdf2.length > 0 && data.businessIntroPdf2[0] ? window.open(data.businessIntroPdf2[0].downloadUrl) : null}
          value={data.businessIntroPdf2 && data.businessIntroPdf2.length > 0 && data.businessIntroPdf2[0].downloadUrl || '...'}
        />
      </Grid>
      
      <Grid item xs={12}>
        <TextField
          //required
          id="linkOfWebsite"
          name="linkOfWebsite"
          label={`Link of website ${data.linkOfWebsite ? '[click to open link]' : ''}`}
          fullWidth
          value={data.linkOfWebsite || '...'}
          onClick={() => data.linkOfWebsite ? window.open(data.linkOfWebsite) : null}
          //autoComplete="shipping address-line1"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          //required
          id="linkOfFacebook"
          name="linkOfFacebook"
          label={`Link of Facebook ${data.linkOfFacebook ? '[click to open link]' : ''}`}
          fullWidth
          value={data.linkOfFacebook || '...'}
          onClick={() => data.linkOfFacebook ? window.open(data.linkOfFacebook) : null}
          //autoComplete="shipping address-line1"
        />
      </Grid>
      
    </Grid>
    </React.Fragment>
  );
};

export default GeneralInfoSection;