import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
//import {auth, googleAuthProvider} from 'firebase';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Face from "@material-ui/icons/Face";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// core components
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTabs from 'components/CustomTabs/CustomTabs';

import styles from 'assets/jss/material-kit-react/views/loginPage.js';

// import image from 'assets/img/bg7.jpg';
import image from "assets/img/HeaderDatacenterV.3.jpg";

// functions validate
import validateEmail from 'functions/validations/email';

import { authFirebase } from '../../firebase';
import { authCurrentUserApi, authSignupApi } from 'api/auth';
import {REDIRECT, LOGGED_IN_USER} from 'constants/actionTypes';

const useStyles = makeStyles((theme) => ({
  ...styles,
  noti: {
    background: 'red',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmpassword: '',
};

export default function LoginPage(props) {
  // eslint-disable-next-line no-unused-vars
  const { common, auth } = useSelector((state) => ({ ...state }));
  const history = useHistory();
  const dispatch = useDispatch();
  // const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  // const [touch, setTouch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emailNotValid, setEmailNotValid] = React.useState(false);
  const [state, setState] = React.useState(initialState);
  const [activeTab, setActiveTab] = React.useState(1);
  
  React.useEffect(() => {
    if(window.location && window.location.search && window.location.search.indexOf('?p=') >= 0){
      const mode = window.location.search.replace('?p=', '');
      setActiveTab((mode === 'signup') ? 0 : 1);
    } else {
      setActiveTab(1);
    }
  }, []);


  const classes = useStyles();
  const { ...rest } = props;

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setEmailNotValid(!validateEmail(event.target.value));
    //console.log('emailValidate', emailValidate);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    // setTouch(true);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [snackPack, setSnackPack] = React.useState([]);
  const [confirmpassword, setConfirmPassword] = React.useState('');
  const [confirmpasswordvalidate, setConfirmPasswordValidate] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState('');
  const [redirect, setRedirect] = React.useState(false);
  const [formSignupNotValid, setFormSignupNotValid] = React.useState(true);
  // const [emailSent, setEmailSent] = React.useState(false);
  
  const passNotValid = () => {
    return password.length < 6;
  };

  React.useEffect(() => {
    if(!passNotValid() && (password === confirmpassword) ) {
      setConfirmPasswordValidate(true);
    } else {
      setConfirmPasswordValidate(false);
    }
  }, [password, confirmpassword]);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  React.useEffect(() => {
    
    setFormSignupNotValid(false);
    
    if(state.firstname.length <= 0 || state.lastname.length <= 0 || state.email.length <= 0 || state.password.length <= 0 || state.confirmpassword.length <= 0) {
      setFormSignupNotValid(true);
    } 

    if(emailNotValid) {
      setFormSignupNotValid(true);
    }

    if(state.password.length !== state.confirmpassword.length) {
      setFormSignupNotValid(true);
    } else if (state.password.length === state.confirmpassword.length && state.password !== state.confirmpassword) {
      setFormSignupNotValid(true);
    }
    
    // console.log(state, emailNotValid, confirmpasswordvalidate);
  }, [state]);

  const onRedirect = () =>
    dispatch({ type: REDIRECT });

  React.useEffect(() => {
    if (common.redirectTo) {
      //console.log('common.redirectTo: ', common.redirectTo);
      history.push(common.redirectTo);
      onRedirect();
    }
  },[common]);

  // eslint-disable-next-line no-unused-vars
  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const clearFormSignup = () => {
    setState(initialState);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setLoading(false);
  };

  const sendEmailVerification = (currentUser) => {

    currentUser.sendEmailVerification().then(function() {
      // Email sent.
      setErrorResponse(null);
      // setSnackPack((prev) => [...prev, { message: `please check your email inbox!. [${email}] 
      // ps. If you can not find your confirmation email in your normal inbox, 
      // it is worth checking in your spam or junk mail section.`, key: new Date().getTime() }]);
      // setEmailSent(true);
      clearFormSignup();

      setRedirect(true);

    }).catch(function(error) {
      // An error happened.
      console.log(error);
      setErrorResponse(error);
      setSnackPack((prev) => [...prev, { message: `${(error && error.message) || 'found error' }`, key: new Date().getTime() }]);
      setLoading(false);
    });

  };

  const formSignup = () => {

    const handleSignup = (event) => {
      setLoading(true);
      event.preventDefault();

      authFirebase.createUserWithEmailAndPassword(email, password)
        // eslint-disable-next-line no-unused-vars
        .then((userCredential) => {
          // Signed in
          //const user = userCredential.user;
          //console.log(userCredential, user);
          
          const currentUser = authFirebase.currentUser;
          // console.log('authFirebase.currentUser: ', currentUser);
          const user = {firstname: state.firstname, lastname: state.lastname, email: state.email, gid: common.gid};
          // eslint-disable-next-line no-unused-vars
          authSignupApi(user).then(res => {
            // console.log(res);
          }).catch(error => {
            console.log(error);
          });
          sendEmailVerification(currentUser);

        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(error, errorCode, errorMessage);
          // const currentUser = authFirebase.currentUser;

          if (errorCode === 'auth/email-already-in-use') {
            // console.log(currentUser);
            // sendEmailVerification(currentUser);
            setErrorResponse(error);
            setSnackPack((prev) => [...prev, { message: `${(error && error.message) || 'found error' }`, key: new Date().getTime() }]);
          } else {
            setErrorResponse(error);
            setSnackPack((prev) => [...prev, { message: `${(error && error.message) || 'found error' }`, key: new Date().getTime() }]);
          }
          
          setLoading(false);
        });
      // authFirebase.sendSignInLinkToEmail(email, {
      //   url: process.env.REACT_APP_REGISTER_REDIRECT_URL,
      //   handleCodeInApp: true,
      // })
      // .then(() => {
      //   setSnackPack((prev) => [...prev, { message: `Thanks for your registration, please check your email inbox!. [${email}] ps. If you can not find your confirmation email in your normal inbox, it is worth checking in your spam or junk mail section.`, key: new Date().getTime() }]);
      //   clearFormSignup();
      // })
      // .catch(error => {
      //   console.log(error);
      //   setErrorResponse(error);
      //   setSnackPack((prev) => [...prev, { message: `${(error && error.message) || 'found error' }`, key: new Date().getTime() }]);
      
      // });

      //console.log('state: ', state);
    };

    // const formSignupNotValid = () => {
    //   // console.log(state, emailNotValid, confirmpasswordvalidate)
    //   if(state.firstname.length <= 0 || state.lastname.length <= 0 || state.email.length <= 0) {
    //     return true;
    //   }
    //   // if(emailNotValid) {
    //   //   return true;
    //   // }
    //   // return !confirmpasswordvalidate;
    //   return false;
    // };

    const handleStateChange = (event) => {
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
    };

    const handleConfirmPassword = (event) => {
      setConfirmPassword(event.target.value);
      // setTouch(true);
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
    };

    return (
      <div>
      
        <br />
        <CustomInput
          labelText='Name...'
          id='firstname'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'firstname',
            type: 'text',
            onChange: (event) => handleStateChange(event),
            value: state.firstname,
          }}
        />
        <CustomInput
          labelText='Last name...'
          id='lastname'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'lastname',
            type: 'text',
            onChange: (event) => handleStateChange(event),
            value: state.lastname,
          }}
        />
        <CustomInput
          labelText='Email...'
          id='email'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'email',
            type: 'email',
            error: emailNotValid,
            onChange: (event) => handleEmail(event),
            value: state.email,
            endAdornment: (
              <InputAdornment position='end'>
                <Email className={classes.inputIconsColor} />
              </InputAdornment>
            ),
          }}
        />
        <CustomInput
          labelText='Password'
          id='pass'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'password',
            type: 'password',
            error: passNotValid(),
            onChange: (event) => handlePassword(event),
            value: state.password,
            endAdornment: (
              <InputAdornment position='end'>
                <LockOutlined className={classes.inputIconsColor} />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
        />
        <CustomInput
          labelText='Confirm Password'
          id='confirmpassword'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'confirmpassword',
            type: 'password',
            error: !confirmpasswordvalidate,
            onChange: (event) => handleConfirmPassword(event),
            value: state.confirmpassword,
            endAdornment: confirmpasswordvalidate ? (
              <InputAdornment position='end'>
                <CheckCircleOutlineIcon className={classes.inputIconsColor} />
              </InputAdornment>
            ) : undefined,
            autoComplete: 'off',
          }}
        />
        <Button
          disabled={formSignupNotValid}
          onClick={handleSignup}
          block
          color='facebook'
          size='lg'
        >
          Sign up
        </Button>
      </div>
    );
  };

  const formLoginNotValid = () => {
    // eslint-disable-next-line no-debugger
    //debugger;
    if(emailNotValid) {
      return true;
    }

    if(passNotValid()){
      return true;
    }

    //console.log(auth.inProgress);
    //return auth.inProgress ? true : false;
    return false;
  };

  const formLogin = () => {
    const handleLogin = async (event) => {
      setLoading(true);
      event.preventDefault();
      // eslint-disable-next-line no-debugger
      // debugger;
      try{
        const result = await authFirebase.signInWithEmailAndPassword(email, password);
        //console.log('signInWithEmailAndPassword: ', result);
        const { user } = result;
        const idTokenResult = await user.getIdTokenResult();
        authCurrentUserApi({ authtoken: idTokenResult.token })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // eslint-disable-next-line no-debugger
          //console.log(response);debugger;
          dispatch({
            type: LOGGED_IN_USER,
            payload: {
              user: {
                name: user.email,
                email: user.email,
                role: response.data.role,
                token: idTokenResult.token,
              },
              redirect: (response.data.role === 'admin') ? '/admin' : '/profile',
            },
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-debugger
          // debugger;
          console.log(error);
          setErrorResponse(error);
          if(error && error.response.data.error === 'email verification not complete') {
            const currentUser = authFirebase.currentUser;
            // console.log('authFirebase.currentUser: ', currentUser);
            sendEmailVerification(currentUser);
            setLoading(false);
            return;
          }

          if(error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
            console.log(error.response.data.error.message);
            setSnackPack((prev) => [...prev, { message: error.response.data.error.message, key: new Date().getTime() }]);
            setLoading(false);
          } else {
            setSnackPack((prev) => [...prev, { message: error.message, key: new Date().getTime() }]);
            setLoading(false);
          }
          
        });
      } catch(error){
        // eslint-disable-next-line no-debugger
        // debugger;
        console.log(error);
        setErrorResponse(error);
        setSnackPack((prev) => [...prev, { message: error.message, key: new Date().getTime() }]);
        setLoading(false);
      }
    };

    const passNotValid = () => {
      return password.length < 6;
    };

    return (
      <div>
        
        <br/>
        <CustomInput
          labelText='Email...'
          id='email'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'email',
            error: emailNotValid,
            onChange: (event) => handleEmail(event),
            value: email,
            endAdornment: (
              <InputAdornment position='end'>
                <Email className={classes.inputIconsColor} />
              </InputAdornment>
            ),
          }}
        />
        <CustomInput
          labelText='Password'
          id='pass'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'password',
            error: passNotValid(),
            onChange: (event) => handlePassword(event),
            value: password,
            endAdornment: (
              <InputAdornment position='end'>
                <LockOutlined className={classes.inputIconsColor} />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
        />
        <Button disabled={formLoginNotValid()} onClick={handleLogin} block 
        color='facebook' 
          size='lg'>
          Login
        </Button>
        
      </div>
    );
  };

  const handleForgotPassword = () => {
      history.push('/forgot/password');
  };

  if(redirect){
    return <Redirect to={'/signupcompleted'} />;
  }
  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        //autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        message={messageInfo ? messageInfo.message : undefined}
      >
        <SnackbarContent
          style={{
            backgroundColor: errorResponse ? 'red' : 'green',
            width: '100%',
          }}
          message={messageInfo ? messageInfo.message : undefined}
        />
      </Snackbar>
      <Header
        absolute
        color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={7}>
              <Card //className={classes[cardAnimaton]}
              >
                <form className={classes.form + ' ' + classes.margintop1rem}>
                  <p className={classes.divider}></p>
                  <CustomTabs
                    headerColor='primary'
                    variant='fullWidth'
                    defaultTab={activeTab}
                    activeTabIndex={activeTab}
                    tabs={[
                      {
                        tabName: 'Signup',
                        tabIcon: ContactMailIcon,
                        tabContent: formSignup(),
                      },
                      {
                        tabName: 'Login',
                        tabIcon: Face,
                        tabContent: formLogin(),
                      },
                    ]}
                  />

                  <CardFooter className={classes.cardFooter}>
                    <Button onClick={handleForgotPassword} link  color='transparent'  size='sm'>
                      Forgot password!
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
