import React from "react";

import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import banner01 from 'assets/img/DataCenterV.1.jpeg';
// import banner02 from 'assets/img/Startup.jpg';

const useStyles = makeStyles((theme) => ({
  ...styles,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function ProductSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    //classes.imgRaised,
    //classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <img src={banner01} alt='...' className={imageClasses} />
        </GridItem>
      </GridContainer>
      <br />
      {/*<GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <img src={banner02} alt='...' className={imageClasses} />
        </GridItem>
  </GridContainer>*/}
      {/*<div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Free Chat"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verified Users"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fingerprint"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>*/}
    </div>
  );
}
