/* eslint-disable no-unused-vars */
import React from 'react';
import {useSelector} from 'react-redux';
//import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Email from '@material-ui/icons/Email';
import LockOutlined from '@material-ui/icons/LockOutlined';
import Face from "@material-ui/icons/Face";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardFooter from 'components/Card/CardFooter';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import { authFirebase } from '../../firebase';
import validateEmail from 'functions/validations/email';
// import styles from 'assets/css/forgot-password-style.module.css'; 
import styles from 'assets/jss/material-kit-react/views/loginPage.js';
import image from "assets/img/HeaderDatacenterV.3.jpg";

const useStyles = makeStyles((theme) => ({
  ...styles,
  noti: {
    background: 'red',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const forgotPassword = (props) => {
  const { ...rest } = props;
  const classes = useStyles();
  //const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState('');
  const [touch, setTouch] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emailNotValid, setEmailNotValid] = React.useState(false);
  const [state, setState] = React.useState({email: '',});

  React.useEffect(() => {
    // authFirebase.signOut().then(() => {
    //   // Sign-out successful.
    //   console.log('Sign-out successful');
    //   dispatch({
    //     type: LOGOUT,
    //   });
    //   //history.push(process.env.REACT_APP_LOGIN_PUSH_URL);

    // }).catch((error) => {
    //   // An error happened.
    //   console.log(location, error);
    // });
    
  }, []);

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setEmailNotValid(!validateEmail(event.target.value));
    //console.log('emailValidate', emailValidate);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
    setTouch(true);
  };

  const formLoginNotValid = () => {
    // eslint-disable-next-line no-debugger
    //debugger;
    if(emailNotValid) {
      return true;
    }

    if(!touch) {
      return true;
    }

    return false;
  };

  const handleResetPassword = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    const config = {
      url: process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL,
      handleCodeInApp: true,
    };

    // setState({
    //   ...state,
    //   ['submit']: true,
    //   ['email']: '',
    // });

    await authFirebase.sendPasswordResetEmail(email, config)
    .then(() => {
      setState({
        ...state,
        ['submit']: true,
        ['email']: '',
      });
    }).catch((error) => {
      console.log(error);
      dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error send Password Reset Email', message: error.message}});
    });

    setLoading(false);
  };

  const form = () => {
    if (state.submit === true) {
      return (
        <div>
          <span style={{color: 'green', fontWeight: 'bold'}}>
            <p>
              Please check your email address, we
              sent you information to change your
              password.
            </p>
          </span>
        </div>
      );
    }
    return (<div>
      <p>
        Please enter your email address below and we
        will send you information to change your
        password.
      </p>
      <br />
      <CustomInput
        labelText='Email...'
        id='email'
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          type: 'email',
          error: emailNotValid,
          onChange: (event) => handleEmail(event),
          value: email,
          endAdornment: (
            <InputAdornment position='end'>
              <Email
                className={classes.inputIconsColor}
              />
            </InputAdornment>
          ),
        }}
      />
      <Button
        disabled={formLoginNotValid()}
        onClick={handleResetPassword}
        block
        color='facebook'
        size='lg'
      >
        RESET PASSWORD
      </Button>
    </div>);
  };

  return (
    <React.Fragment>
      <div>
        <Header
          absolute
          color='transparent'
          brand='YAK START'
          rightLinks={<HeaderLinks />}
          {...rest}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
          }}
        >
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color='inherit' />
          </Backdrop>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={7}>
                <Card //className={classes[cardAnimaton]}
                >
                  <form className={classes.form + ' ' + classes.margintop1rem}>
                    <p className={classes.divider}></p>
                    <CustomTabs
                      headerColor='primary'
                      variant='fullWidth'
                      defaultTab={0}
                      activeTabIndex={0}
                      tabs={[
                        {
                          tabName: 'Forgot Password',
                          tabIcon: Face,
                          tabContent: form(),
                        },
                      ]}
                    />
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    </React.Fragment>
  );

};

export default forgotPassword;