import {
  APP_LOAD,
  APP_LOADED,
  REDIRECT,
  LOGOUT,
  LOGIN,
  LOGGED_IN_USER,
  REGISTER,
  LOAD_GUEST_ID,
  ERROR_MODAL_SHOW,
  ERROR_MODAL_CLOSE,
  STATUS_MODAL_SHOW,
  STATUS_MODAL_CLOSE,
} from '../constants/actionTypes';

const defaultState = {
  appName: 'YakStart',
  appLoaded: true,
  errorModalShow: false,
  errorTitle: '',
  errorMessage: '',
  statusModalShow: false,
  statusTitle: '',
  statusMessage: '',
  alertType: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        appLoaded: false,
      };
    case APP_LOADED:
      return {
        ...state,
        appLoaded: true,
      };
    case STATUS_MODAL_CLOSE: return {...state, statusModalShow: false, statusTitle: '', statusMessage: ''};
    case STATUS_MODAL_SHOW: return {...state, alertType: 'success', statusModalShow: true, statusTitle: action.payload.title, statusMessage: action.payload.message};
    case ERROR_MODAL_SHOW: return {...state, alertType: 'danger' , errorModalShow: true, errorTitle: action.payload.title, errorMessage: action.payload.message};
    case ERROR_MODAL_CLOSE: return {...state, errorModalShow: false, errorTitle: '', errorMessage: ''};
    case REDIRECT:
      return { ...state, redirectTo: null};
    case LOGOUT:
      return { ...state, redirectTo: '/', token: null, currentUser: null };
    case LOGIN:
    case LOGGED_IN_USER:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : action.payload.redirect ? action.payload.redirect : '/',
        //token: action.error ? null : action.payload.user.token,
        currentUser: action.error ? null : action.payload.user
      };
    case LOAD_GUEST_ID:
      return { ...state, gid: action.gid ? action.gid : null };
      default:
      return state;
  }
};