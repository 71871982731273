const validateEmail = (emailAddr) => {
  if (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
      emailAddr
    )
  ) {
    return true;
  }
  return false;
};

export default validateEmail;
