export const styles = {
  appBar: {
    backgroundColor: '#343A40',
    height: '50px',
    '& .MuiToolbar-regular': {
      minHeight: '50px',
    },
  },
  name: {
    marginRight: '15px',
  },
  link: {
    textTransform: 'unset',
    color: '#a5a5a5',
    margin: '0 20px',
    textDecoration: 'unset',
    "&:hover": {
      color: "#fff"
    },
    "&:active": {
      color: "#fff"
    },
    "&:visited": {
      color: "#fff"
    },
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 'auto',
    width: '99%',
    padding: '1em',
  },
  paper2: {
    height: 'auto',
    width: '99%',
    padding: '1em',
    marginBottom: '1em',
    color: "#fff",
    background: "linear-gradient(60deg, #f7bebd, #e53935)", 
  },
  paper3: {
    height: 'auto',
    width: '99%',
    padding: '1em',
    marginBottom: '1em',
    color: "#fff",
    background: "linear-gradient(170deg, #3B5998 , #f1f4fa)",  
  },
  paperWhite: {
    height: 'auto',
    width: '99%',
    padding: '1em',
    marginBottom: '1em',
    color: "#fff",
    background: "linear-gradient(60deg, #fff , #fff)",  
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    //zIndex: "3"
  },
  mainRaised: {
    margin: "11px 15px 15px",
    //borderRadius: "6px",
    // boxShadow:
    //   "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
};
