import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import notificationsStyles from "assets/jss/material-kit-react/views/componentsSections/notificationsStyles.js";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ... notificationsStyles,
});

const useStyles = makeStyles(notificationsStyles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="caption">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function CustomizedDialogs(props) {
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const {isOpen, handleConfirm, handleClose, title, content, alertType} = props;

  return (
    <div>
      <Dialog fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen} maxWidth='sm'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {`${title} - ${new Date().toLocaleString('en-GB')}` || 'Untitled'}
        </DialogTitle>
        <DialogContent dividers>
          
          <div className={classes.section} id="notifications">
            {alertType === 'info' && <SnackbarContent
              message={
                <span>
                  <b>INFO: </b> {content || undefined}
                </span>
              }
              
              color="info"
              icon="info_outline"
            />}
            {alertType === 'success' && <SnackbarContent
              message={
                <span>
                  <b>SUCCESS: </b> {content || undefined}
                </span>
              }
              
              color="success"
              icon={Check}
            />}
            {alertType === 'warning' && <SnackbarContent
              message={
                <span>
                  <b>WARNING: </b> {content || undefined}
                </span>
              }
              
              color="warning"
              icon={Warning}
            />}
            {alertType === 'error' || alertType === 'danger' && <SnackbarContent
              message={
                <span>
                  <b>ERROR: </b> {content || undefined}
                </span>
              }
              
              color="danger"
              icon="info_outline"
            />}
            {alertType === undefined && (content || undefined)}
          </div>
        </DialogContent>
        {false && <DialogActions>
          {handleConfirm && <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>}
          <Button autoFocus onClick={handleClose} color="default">
            Close
          </Button>
        </DialogActions>}
      </Dialog>
    </div>
  );
}
