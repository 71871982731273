import React from 'react';
import PropTypes from 'prop-types';

const CustomHeaderTable = (props) => {
  const {title} = props;
  return (
    <header className='header'>
      <h4>{title}</h4>
    </header>
  );
};

CustomHeaderTable.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CustomHeaderTable;