/* eslint-disable no-debugger */
import React from 'react';
import { useSelector } from 'react-redux';

import {startupsApi} from 'api/admin/startup';

function useAdmin() {
  const {common} = useSelector((state) => ({...state}));
  // const [query, setQuery] = React.useState('');
  const [data, setData] = React.useState([]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // error state
  const [error, setError] = React.useState("");

  async function fetchList(query) {
    setLoading(true);
    setError("");
    const response = await startupsApi({currentUser: common.currentUser, query});
    setData((response && response.data) || []);
    setLoading(false);
  }

  const onGetStartups = (query) => {
    setData([]);
    fetchList(query)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  return {data, loading, error, onGetStartups};
}

export default useAdmin;