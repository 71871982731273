/* eslint-disable react/prop-types */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

import AvatarUpload from 'components/AvatarUpload/AvatarUpload';
import TextField from '@material-ui/core/TextField';

import { container } from "assets/jss/material-kit-react.js";
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  ... container,
  ... styles,  
  container: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  root: {
    flexShrink: 0,
    //marginLeft: theme.spacing(0),
    padding: '20px',
    //backgroundColor: '#fff',
    //width: '90%',
    //margin: '10px',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: '20px',
    paddingLeft: '30px',
  },
  gridContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#ddd',
  },
}));

const TeamSection = (props) => {
  const {data} = props;
  const classes = useStyles();

  const member = (idx) => {
    return data.currentTeam && data.currentTeam.length > 0 ? data.currentTeam[idx] : null;
  };

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridContainer}>
        {member(0) && (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <AvatarUpload 
                displayOnly 
                imagecloud={member(0).startupImage1} 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name1"
                label="Name"
                fullWidth
                value={member(0).name1 || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="position1"
                label="Position"
                fullWidth
                value={member(0).position1 || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="briefProfile1"
                label="Brief profile"
                fullWidth
                value={member(0).briefProfile1 || '...'}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12}></Grid>
        {member(1) && (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <AvatarUpload 
                displayOnly 
                imagecloud={member(1).startupImage2} 
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="name1"
                label="Name"
                fullWidth
                value={member(1).name2 || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="position1"
                label="Position"
                fullWidth
                value={member(1).position2 || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="briefProfile1"
                label="Brief profile"
                fullWidth
                value={member(1).briefProfile2 || '...'}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default TeamSection;