/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Devider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import AppsIcon from '@material-ui/icons/Apps';
import CakeIcon from '@material-ui/icons/Cake';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import AttachMoneyOutlined from '@material-ui/icons/AttachMoneyOutlined';
import {parseToName} from 'functions/country';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  itemGrid: {
    marginLeft: "0",
    marginRight: "0",
    
  },

}));

// eslint-disable-next-line no-unused-vars
const Summary = (props) => {
  const classes = useStyles();
  const {data} = props;

  return (
    <React.Fragment>
      <GridContainer
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
          <Paper elevation={5}>
            <GridContainer
              direction='column'
              justify='flex-start'
              alignItems='flex-start'
            >
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <InfoIcon />
                      <span>&nbsp;About:&nbsp;</span>
                    </div>
                  </Box>
                </Typography>
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    {data.aboutus || '...'}
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <AppsIcon />
                      <span>&nbsp;Industries:&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    {data.startupIndustry || '...'}
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <CakeIcon />
                      <span>&nbsp;Founded Date:&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    {data.foundedDate && moment.unix(data.foundedDate).format('DD MMMM YYYY') || '...'}
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <BusinessIcon />
                      <span>&nbsp;Headquater:&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                  {parseToName(data.operatingCountry) || '...'}
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <PeopleAltIcon />
                      <span> &nbsp;People :&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                  {data.numberOfEmployee || '...'}
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <PlayCircleOutlineIcon />
                      <span> &nbsp;State of business :&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    {data.statusOfBusiness || '...'}
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <AttachMoneyOutlined />
                      <span> &nbsp;Last funding type :&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    {data.startupLastFundingType || '...'}
                  </Box>
                </Typography>
              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default Summary;