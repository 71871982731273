import React from 'react';
import { useSelector } from 'react-redux';
import {sendMessageApi} from 'api/message';
import {sendInviteApi} from 'api/message';

function useMessage() {
  const {common} = useSelector((state) => ({...state}));
  // loading
  const [loading, setLoading] = React.useState(false);
  // error state
  const [error, setError] = React.useState("");
  // result
  const [result, setResult] = React.useState(null);

  async function postData({name, email, message}) {
    setLoading(true);
    setError("");
    const postResponse = await sendMessageApi({name, email, message});
    //console.log(postResponse)
    if(postResponse && postResponse.data) {
      setResult((postResponse && postResponse.data) || null);
    }
    
    setLoading(false);
  }

  async function postInviteData({name, email, message, link}) {
    setLoading(true);
    setError("");
    const postResponse = await sendInviteApi({name, email, message, link}, common.currentUser.token);
    //console.log(postResponse)
    if(postResponse && postResponse.data) {
      setResult((postResponse && postResponse.data) || null);
    }
    
    setLoading(false);
  }

  const onSendInvite = ({name, email, message, link}) => {
    setResult(null);
    postInviteData({name, email, message, link})
    .catch(error => { console.log(error); setError(error.response?.data || error.response); setLoading(false);});
  }

  const onSendMessage = ({name, email, message}) => {
    setResult(null);
    postData({name, email, message})
    .catch(error => { console.log(error); setError(error.response?.data || error.response); setLoading(false);});
  }

  return {result, onSendMessage, onSendInvite, loading, error};
}

export default useMessage;