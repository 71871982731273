/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import BusinessModelTable from 'views/StartupInfoPage/Sections/BusinessModelTable';
import AlertSignupForm from 'views/StartupInfoPage/components/AlertSignupForm';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  itemGrid: {
    marginLeft: "0",
    marginRight: "0",
    
  },

}));

const BusinessModel = (props) => {
  const { common } = useSelector((state) => ({ ...state }));
  const classes = useStyles();

  return (
    <React.Fragment>
    <GridContainer
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
          <Paper elevation={5}>
            <GridContainer>
              <GridItem>
                {common.currentUser && common.currentUser.isInvestor && <BusinessModelTable data={props.data} />}
                {common.currentUser && !common.currentUser.isInvestor && <AlertSignupForm />}
              </GridItem>
              <Divider />
            </GridContainer>
          </Paper>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default BusinessModel;