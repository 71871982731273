/* eslint-disable react/prop-types */
import React from 'react';

import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

// core components
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';

import {startupRegisterApi} from 'api/startup';

import {SAVE_DRAF_SIGNUP} from 'constants/actionTypes';

import styles from 'assets/jss/material-kit-react/views/startupSections/finacial';

const useStyles = makeStyles(styles);

const CustomCheckbox = withStyles({
  root: {
    color: deepOrange[400],
    '&$checked': {
      color: deepOrange[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const titleMobile = () =>
  window.innerWidth < 450 ? (
    <Typography style={{ color: '#999', textAlign: 'center' }} variant='h5'>
      Financial
    </Typography>
  ) : null;

const statusOfBusiness = ['Ideas state',
'Already Luach Product (MVP)',
'Already have active users',
'Already have revenue',
'Profitable'];

const Financial = (props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  const {common, auth} = useSelector((state) => ({...state}));
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    statusOfBusiness: '',
    fundraisingStatus: '',
    fundraisingHistory: [],
    numberOfUser: '',
    revenueHistory: [],
  });
  const [fundraisingFields, setFundraisingFields] = React.useState([]);
  const [revenueFields, setRevenueFields] = React.useState([]);
  const [checkboxState, setCheckboxState] = React.useState({
    checkedOpen: false,
    checkedNot: false,
  });
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    errors && errors.length > 0 && console.log('errors', errors);
  }, [errors]);
  
  React.useEffect(() => {
    if (auth.drafSignup && auth.drafSignup.startupName) {
      let initialState = {...auth.drafSignup,
        statusOfBusiness: auth.drafSignup.statusOfBusiness || '',
        fundraisingStatus: auth.drafSignup.fundraisingStatus || '',
        fundraisingHistory: auth.drafSignup.fundraisingHistory || [],
        numberOfUser: auth.drafSignup.numberOfUser || '',
        revenueHistory: auth.drafSignup.revenueHistory || [],
      };

      if(auth.drafSignup.fundraisingStatus && auth.drafSignup.fundraisingStatus === "Open"){
        setCheckboxState({
          checkedOpen: true,
          checkedNot: false,
        });
      } else if(auth.drafSignup.fundraisingStatus && auth.drafSignup.fundraisingStatus === "Not Open"){
        setCheckboxState({
          checkedOpen: false,
          checkedNot: true,
        });
      }

      setState(initialState);
    } else {
      let initialState = {
        statusOfBusiness: '',
        fundraisingStatus: '',
        fundraisingHistory: [],
        numberOfUser: '',
        revenueHistory: [],
      };
      setState(initialState);
    }
  }, [auth]);

  const handleStateChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    }); 
  };

  const handleAddFundraisingFieldsInput = () => {
    const values = [...fundraisingFields];
    values.push({
      investmentYear: '',
      investorNumber: '',
      fundAmount: '',
      postMoneyValuation: '',
    });
    //console.log('handleAddFundraisingFieldsInput', values)
    setFundraisingFields(values);
  };

  const handleAddFundraisingFieldsInputWithState = () => {
    const values = [...fundraisingFields];
    auth.drafSignup.fundraisingHistory.map(value => {
      values.push({
        investmentYear: value.investmentYear || '',
        investorNumber: value.investorNumber || '',
        fundAmount: value.fundAmount || '',
        postMoneyValuation: value.postMoneyValuation || '',
      });
      // console.log(value, values)
      setFundraisingFields(values);
    });

    
  };

  const handleAddRevenueFieldsInput = () => {
    const values = [...revenueFields];
    values.push({
      revenueYear: '',
      annualRevenue: '',
      annualProfit: '',
    });
    setRevenueFields(values);
  };

  const handleAddRevenueFieldsInputWithState = () => {
    const values = [...revenueFields];
    auth.drafSignup.revenueHistory.map(value => {
      values.push({
        revenueYear: value.revenueYear || '',
        annualRevenue: value.annualRevenue || '',
        annualProfit: value.annualProfit || '',
      });
      // console.log(value, values)
      setRevenueFields(values);
    });

    
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    
    if (fundraisingFields && fundraisingFields.length <= 0) {
      if(auth.drafSignup.fundraisingHistory && auth.drafSignup.fundraisingHistory.length > 0) {
        handleAddFundraisingFieldsInputWithState();
      } else {
        handleAddFundraisingFieldsInput();
      }
      
    }
    if (revenueFields && revenueFields.length <= 0) {
      if(auth.drafSignup.revenueHistory && auth.drafSignup.revenueHistory.length > 0) {
        handleAddRevenueFieldsInputWithState();
      } else {
        handleAddRevenueFieldsInput();
      }
    }
  }, []);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      fundraisingHistory: fundraisingFields,
    }));
  }, [fundraisingFields]);
  
  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      revenueHistory: revenueFields,
    }));
  }, [revenueFields]);
  
  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      fundraisingStatus: (checkboxState && checkboxState.checkedOpen ? 'Open' : 'Not Open'),
    }));
  }, [checkboxState]);

  const handleChangeFundraisingFieldsInput = (i, event) => {
    const values = [...fundraisingFields];
    const { name, value } = event.target;
    values[i][name] = value;
    setFundraisingFields(values);
    //console.log(fundraisingFields);
  };

  const handleChangeRevenueFieldsInput = (i, event) => {
    const values = [...revenueFields];
    const { name, value } = event.target;
    values[i][name] = value;
    setRevenueFields(values);
    //console.log(revenueFields);
  };

  const handleRemoveFundraisingFieldsInput = () => {
    if (fundraisingFields && fundraisingFields.length <= 1) return;

    const values = [...fundraisingFields];
    // console.log(values);
    values.splice(fundraisingFields.length - 1, 1);
    setFundraisingFields(values);
  };

  const handleRemoveRevenueFieldsInput = () => {
    if (revenueFields && revenueFields.length <= 1) return;

    const values = [...revenueFields];
    // console.log(values);
    values.splice(revenueFields.length - 1, 1);
    setRevenueFields(values);
  };

  const handleChange = (event) => {
    
    //setCheckboxState({ ...checkboxState, [event.target.name]: event.target.checked });

    if(event.target.name === 'checkedOpen' && event.target.checked) {
      setCheckboxState({ ...checkboxState, checkedOpen: true,  checkedNot: false});
      //console.log('1', event.target.name, event.target.checked);
    }

    if(event.target.name === 'checkedNot' && event.target.checked) {
      setCheckboxState({ ...checkboxState, checkedNot: true, checkedOpen: false });
      // console.log('2', event.target.name, event.target.checked);
    }
  };

  const onSave = (e) => {
    // eslint-disable-next-line react/prop-types
    props.setLoading(true);
    e.preventDefault();

    const startup = {...auth.drafSignup,
      statusOfBusiness: state.statusOfBusiness,
      fundraisingStatus: state.fundraisingStatus,
      fundraisingHistory: state.fundraisingHistory,
      numberOfUser: state.numberOfUser,
      revenueHistory: state.revenueHistory,
    };

    //console.log('startup: ', startup);

    startupRegisterApi(common.currentUser.token,startup)
        // eslint-disable-next-line no-unused-vars
        .then((resp) => {
          //console.log(resp);
          dispatch({ type: SAVE_DRAF_SIGNUP, payload: startup });
          props.validToNext(true);
          props.handleComplete(); // next page
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          // eslint-disable-next-line react/prop-types
          props.setLoading(false);
          setErrors(error);
          //dispatch({type: API_RESPONSE_ERROR, payload: error.message})
          return;
        });

    // eslint-disable-next-line react/prop-types
    props.setLoading(false);
  };

  const fundraisingHistory = () => {
    return (
      <div>
        <p className={classes.divider}> </p>
        {fundraisingFields.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <FormHelperText>Fundraising history Round: {idx + 1}</FormHelperText>
              <Grid xs={12} sm={12} md={12} container spacing={1} item>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='number'
                      label='Year of investment'
                      name='investmentYear'
                      value={fundraisingFields[idx]['investmentYear'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeFundraisingFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Number of investor'
                      name='investorNumber'
                      value={fundraisingFields[idx]['investorNumber'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeFundraisingFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Fund amount'
                      name='fundAmount'
                      value={fundraisingFields[idx]['fundAmount'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeFundraisingFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Post - money valuation'
                      name='postMoneyValuation'
                      value={fundraisingFields[idx]['postMoneyValuation'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeFundraisingFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          );
        })}
        <Grid container xs={12} sm={12} md={12} item justify='flex-end'>
          <Button
            simple
            color='facebook'
            type='button'
            onClick={() => handleAddFundraisingFieldsInput()}
          >
            <i className='fas fa-plus-circle' aria-hidden='true'></i>
          </Button>
          <Button
            simple
            color='danger'
            type='button'
            onClick={() => handleRemoveFundraisingFieldsInput()}
          >
            <i className='fas fa-trash' aria-hidden='true'></i>
          </Button>
        </Grid>
      </div>
    );
  };

  const revenueHistory = () => {
    return (
      <div>
        <p className={classes.divider}> </p>
        {revenueFields.map((field, idx) => {
          return (
            <div key={`${field}-${idx}`}>
              <FormHelperText>Revenue history: {idx + 1}</FormHelperText>
              <Grid xs={12} sm={12} md={12} container spacing={1} item>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='number'
                      label='Year'
                      name='revenueYear'
                      value={revenueFields[idx]['revenueYear'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeRevenueFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Annual revenue'
                      name='annualRevenue'
                      value={revenueFields[idx]['annualRevenue'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeRevenueFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={3} item>
                  <FormControl fullWidth margin='dense'>
                    <TextField
                      variant='outlined'
                      required
                      type='text'
                      label='Annual profit'
                      name='annualProfit'
                      value={revenueFields[idx]['annualProfit'] || ''}
                      size='small'
                      className='name'
                      onChange={(e) => handleChangeRevenueFieldsInput(idx, e)}
                    />
                  </FormControl>
                </Grid>
                
              </Grid>
            </div>
          );
        })}
        <Grid container xs={12} sm={12} md={12} item justify='flex-end'>
          <Button
            simple
            color='facebook'
            type='button'
            onClick={() => handleAddRevenueFieldsInput()}
          >
            <i className='fas fa-plus-circle' aria-hidden='true'></i>
          </Button>
          <Button
            simple
            color='danger'
            type='button'
            onClick={() => handleRemoveRevenueFieldsInput()}
          >
            <i className='fas fa-trash' aria-hidden='true'></i>
          </Button>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      {titleMobile()}
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={1} item></Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='statusofbusiness-native-helper'>
              Status of business
            </InputLabel>
            <NativeSelect
              value={state.statusOfBusiness}
              onChange={handleStateChange}
              inputProps={{
                name: 'statusOfBusiness',
                id: 'statusofbusiness-native-helper',
              }}
            >
              <option aria-label='None' value='' />
              {statusOfBusiness.map((item) => {
                return (
                  <option key={item} value={item}>{item}</option>
                );
              })}
            </NativeSelect>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
          </FormControl>
        </Grid>
      </Grid>
      <p className={classes.divider}> </p>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={1} item></Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl component='fieldset' className={classes.formControl}>
            <FormLabel component='legend'>Fundraising Status</FormLabel>
            <FormGroup row>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={checkboxState.checkedOpen}
                    onChange={handleChange}
                    name='checkedOpen'
                    //color='secondary'
                  />
                }
                label='Open'
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={checkboxState.checkedNot}
                    onChange={handleChange}
                    name='checkedNot'
                    //color='secondary'
                  />
                }
                label='Not Open'
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='flex-start'
      >
        <Grid xs={12} sm={12} md={1} item></Grid>
        <Grid xs={12} sm={12} md={11} item>
          {fundraisingHistory()}
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={1} item></Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Number of user'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'numberOfUser',
                type: 'number',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                value: state.numberOfUser,
                // endAdornment: (
                //   <InputAdornment position='end'>
                //     <Email className={classes.inputIconsColor} />
                //   </InputAdornment>
                // ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='flex-start'
        alignItems='flex-start'
      >
        <Grid xs={12} sm={12} md={1} item></Grid>
        <Grid xs={12} sm={12} md={11} item>
          {revenueHistory()}
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={2} item>
          <p className={classes.divider}> </p>
          <Button 
            onClick={onSave}
            block
            color='danger'
            size='lg'
          >
            Save
          </Button>
        </Grid>
      </Grid>

    </div>
  );
};

export default Financial;
