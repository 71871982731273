/* eslint-disable react/prop-types */
import React, { Component } from 'react';
// import { browserHistory } from 'react-router';
import { Redirect } from "react-router-dom";
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import SignIn from 'components/SignIn/SignIn';
import SignUp from 'components/SignUp/SignUp';
import { authFirebase } from '../../firebase';

export default class Auth extends Component {
  componentDidMount() {
    
    authFirebase.onAuthStateChanged(user => {
      if (user) {
        // browserHistory.push('/home');
        return <Redirect to={'/home'} />;
      }
    });
  }

  render() {
    return (
      <div className="Landing">

        <h1>Sign In</h1>
        <SignIn />

        <h1>Forgot Password</h1>
        <ForgotPassword />

        <h1>Sign Up</h1>
        <SignUp />

      </div>
    );
  }
}
