import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import StartupGridListSection from 'views/OurStartupsPage/Sections/StartupGridListSection';

import styles from "assets/jss/material-kit-react/views/ourStartupsPage.js";

const stylesContainer = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  bodyContainer: {
    // backgroundColor: '#22254b',
    backgroundColor: '#fff',
    color: '#22254b',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  ... styles,
});

const useStyles = makeStyles(stylesContainer);

export default function OurStartupsPage(props) {
  const classes = useStyles();
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        //color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      {/*<div className={classNames(classes.main, classes.mainRaised, classes.bodyContainer)}>
        {matches ? <StartupCardListMobile /> : <StartupGridListSection />}
        </div>*/}
      <div className={classNames(classes.bodyContainer, classes.mainRaised,)}>
        <StartupGridListSection />
      </div>
      <Footer />
    </div>
  );
}
