/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import LoadingToRedirect from 'views/routes/LoadingToRedirect';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const AdminRoute = (props) => {
  
  const { common } = useSelector((state) => ({ ...state }));
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const { children, location, history, ...rest } = props; 
  
  React.useEffect(() => {
    // eslint-disable-next-line no-debugger
    //debugger;
    //console.log(location);
    setLoading(true);

  }, []);

  return common.currentUser && common.currentUser.role === 'admin' ? (
    <Route {...rest} />
  ) : (
    <div style={{ marginTop: '20%' }}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <LoadingToRedirect
        to={{
          pathname: '/login-page',
          state: { type: 'admin' },
        }}
      />
    </div>
  );
};

export default AdminRoute;