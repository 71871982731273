import React from 'react';
import {getKeyTechnologiesEnum} from 'api/enums/keyTechnology';

function useKeyTechnologyEnum() {
  // loading
  const [loading, setLoading] = React.useState(false);
  // error state
  const [error, setError] = React.useState("");
  // result
  const [enumKeyTechnologies, setEnumKeyTechnologies] = React.useState([]);

  async function fetchData() {
    setLoading(true);
    setError("");
    const response = await getKeyTechnologiesEnum();
    setEnumKeyTechnologies((response && response.data) || []);
    setLoading(false);
  }

  const onGetKeyTechnologiesAll = () => {
    setEnumKeyTechnologies([]);
    fetchData()
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  return {enumKeyTechnologies, onGetKeyTechnologiesAll, loading, error};
}

export default useKeyTechnologyEnum;