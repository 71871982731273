/* eslint-disable react/prop-types */
import React from 'react';
// import { useHistory } from 'react-router-dom';
import {useDispatch } from 'react-redux';
// import { userSelector } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import {SEARCH_QUERY} from 'constants/actionTypes';
import styles from "assets/jss/material-kit-react/components/searchStyle";

const useStyles = makeStyles(styles);

const CustomSearch = (props) => {
  const classes = useStyles();

  // const {search} = userSelector((state) => ({...state}));
  // const {text} = search;
  const dispatch = useDispatch();
  // const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = React.useState(null);

  const handleChange = (event) => {
    // console.log(event);
    dispatch({
      type: SEARCH_QUERY,
      payload: {text: event.target.value},
    });

    setSearchText(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // eslint-disable-next-line react/prop-types
    props.onSearch();
    
  };

  return (
    <div>
      <CustomInput
        //white
        inputRootCustomClasses={classes.inputRootCustomClasses}
        formControlProps={{
          className: classes.formControl,
        }}
        inputProps={{
          onChange: (e) => handleChange(e),
          value: `${props.textSearch ? props.textSearch : '' }`,
          placeholder: 'Search',
          inputProps: {
            'aria-label': 'Search',
            className: classes.searchInput,
          },
        }}
      />
      <Button justIcon round color='white' onClick={handleSubmit}
      >
        <Search className={classes.searchIcon} />
      </Button>
    </div>
  );
};

export default CustomSearch;
