/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { Settings } from '@material-ui/icons';
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import List from "@material-ui/core/List";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Header from "components/Header/Header";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import Button from "components/CustomButtons/Button";

import { withStyles } from '@material-ui/core/styles';
// import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

import navbarsStyle from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";

const styles = () => ({
  ... navbarsStyle,
});

const useStyles = makeStyles(navbarsStyle);

//https://bezkoder.com/react-material-ui-examples-crud/
const AdminNav = (props) => {
  // const { classes } = props;
  const classes = useStyles();

  return (  
    <div>
    <div className={classes.container}>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Header
          brand="Menu"
          color="primary"
          leftLinks={
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Link to={'/admin/startup-markets'} className={classes.navLink}>
                  <Typography variant='body2'>Startup Market</Typography>
                </Link>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Link to={'/admin/startups'} className={classes.navLink}>
                  <Typography variant='body2'>Our Startups</Typography>
                </Link>
              </ListItem>
              {false && <ListItem className={classes.listItem}>
                <CustomDropdown
                  buttonText="Dropdown"
                  dropdownHeader="Dropdown Header"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                  }}
                  dropdownList={[
                    "Action",
                    "Another action",
                    "Something else here",
                    { divider: true },
                    "Separated link",
                    { divider: true },
                    "One more separated link"
                  ]}
                />
              </ListItem>}
              {false && <ListItem className={classes.listItem}>
                <CustomDropdown
                  left
                  hoverColor="info"
                  dropdownHeader="Dropdown Header"
                  buttonIcon="settings"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                  }}
                  dropdownList={[
                    "Action",
                    "Another action",
                    "Something else here",
                    { divider: true },
                    "Separated link",
                    { divider: true },
                    "One more separated link"
                  ]}
                />
              </ListItem>}
            </List>
          }
        />
      </GridItem>
      {/*<GridItem xs={12} sm={12} md={6}>
        <Header
          brand="Icons"
          color="primary"
          rightLinks={
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <Button color="transparent" className={classes.navLink}>
                  <Email className={classes.icons} />
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <Button color="transparent" className={classes.navLink}>
                  <Face className={classes.icons} />
                </Button>
              </ListItem>
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  left
                  hoverColor="info"
                  dropdownHeader="Dropdown Header"
                  buttonIcon="settings"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                  }}
                  dropdownList={[
                    "Action",
                    "Another action",
                    "Something else here",
                    { divider: true },
                    "Separated link",
                    { divider: true },
                    "One more separated link"
                  ]}
                />
              </ListItem>
            </List>
          }
        />
        </GridItem>*/}
    </GridContainer>
  </div>


      {/*<AppBar className={classes.appBar} position='static'>
        <Toolbar>
          <Link to={'/'} className={classes.link}>
            <Typography className={classes.name} variant='h6'>
              yak <span style={{color: 'red'}}>start</span>
            </Typography>
          </Link>
          <Link to={'/admin/startup-markets'} className={classes.link}>
            <Typography variant='body2'>Startup Market</Typography>
          </Link>
          <ListItem className={classes.listItem}>
            <CustomDropdown
              noLiPadding
              buttonText={'My profile'}
              buttonProps={{
                className: classes.navLink,
                color: 'transparent',
              }}
              buttonIcon={Settings}
              dropdownList={[
                <Link 
                  key={'1'}
                  to={'/profile'}
                  className={classes.dropdownLink}
                >
                {'My profile'}
                </Link>,
                <Link key={'2'} to={'/invite-friends'} className={classes.dropdownLink}>
                  {'Invite friends'}
                </Link>,
                <Link key={'3'} to={'/logout'} className={classes.dropdownLink}>
                  {'Logout'}
                </Link>,
              ]}
            />
          </ListItem>
        </Toolbar>
            </AppBar>*/}
    </div>
  );
  // return (
  //   <div>
  //   <span className='menu' style={{ fontSize: '30px', cursor: 'pointer' }} onClick={toggleNav}>
  //     &#9776;
  //   </span>
  //     <nav id='mySidenav' className={!isOpenNav ? 'sidenavClose' : 'sidenav'}>
      
  //       <ul className='nav flex-column'>
  //         {window.innerWidth < 900 && (
  //           <li className='nav-item ' style={{ height: '40px' }}>
  //             <Link
  //               to='#'
  //               className='nav-link'
  //               onClick={toggleNav}
  //               style={{
  //                 position: 'absolute',
  //                 top: '30',
  //                 right: '5px',
  //                 fontSize: '36px',
  //                 marginLeft: '50px',
  //               }}
  //             >
  //               <p className='float-right'>&times;</p>
  //             </Link>
  //           </li>
  //         )}
  //         {window.innerWidth < 900 ? (
  //           <li className='nav-item' style={{ height: '40px' }}>
  //             <Link
  //               to='/admin'
  //               className='nav-link'
  //               onClick={() => setIsOpenNav(false)}
  //             >
  //               Home
  //             </Link>
  //           </li>
  //         ) : (
  //           <li className='nav-item' style={{ height: '40px' }}>
  //             <Link to='/admin' className='nav-link'>
  //               Home
  //             </Link>
  //           </li>
  //         )}
  //         {window.innerWidth < 900 ? (
  //           <li className='nav-item' style={{ height: '40px' }}>
  //             <Link
  //               to='/admin/user'
  //               className='nav-link'
  //               onClick={() => setIsOpenNav(false)}
  //             >
  //               User Management
  //             </Link>
  //           </li>
  //         ) : (
  //           <li className='nav-item' style={{ height: '40px' }}>
  //             <Link to='/admin/user' className='nav-link' onClick={toggleNav}>
  //               User Management
  //             </Link>
  //           </li>
  //         )}

  //         {window.innerWidth < 900 ? (
  //           <li className='nav-item' style={{ height: '40px' }}>
  //             <Link
  //               to='/admin/industry-type'
  //               className='nav-link'
  //               onClick={() => setIsOpenNav(false)}
  //             >
  //               Industry Type
  //             </Link>
  //           </li>
  //         ) : (
  //           <li className='nav-item' style={{ height: '40px' }}>
  //             <Link to='/admin/industry-type' className='nav-link'>
  //               Industry Type
  //             </Link>
  //           </li>
  //         )}
  //         <li className='nav-item' style={{ height: '40px' }}>
  //           <Link to='/admin/preferred-industry' className='nav-link'>
  //             Preferred Industry
  //           </Link>
  //         </li>
  //         <li className='nav-item' style={{ height: '40px' }}>
  //           <Link to='/admin/key-technology' className='nav-link'>
  //             Key Technology
  //           </Link>
  //         </li>
  //       </ul>
  //     </nav>

      
  //   </div>
  // );
};

export default withStyles(styles)(AdminNav);