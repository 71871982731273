/* eslint-disable no-debugger */
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from 'components/CustomButtons/Button.js';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import FilterListIcon from '@material-ui/icons/FilterList';

import CustomSearch from 'components/CustomSearch/CustomSearch';
import { getIndustryTypesEnum } from 'api/enums/industryType';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const { enumState, search } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const [industryFilter, setIndsutryFilter] = React.useState([]);
  const [state, setState] = React.useState({
    left: false,
  });
  const [industry, setIndsutry] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [openIndustry, setOpenIndustry] = React.useState(true);

  React.useEffect(() => {
    let isMounted = true; // note this flag denote mount status

    if (!enumState || !enumState.industryTypes) {
      getIndustryTypesEnum()
        .then((resp) => {
          dispatch({
            type: 'SET_INDUSTRY_TYPE_ENUM',
            payload: { industryTypes: resp.data },
          });

          const filter = resp.data.filter(v => v.name !== 'Others');

          if (isMounted) setIndsutry(filter.concat({code: '99', name: 'Others'}));
        }) 
        .catch((error) => {
          console.log(error);
          return () => {
            isMounted = false;
          };
        });
    }

    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, []);

  // React.useEffect(() => {
  //   console.log('industryFilter:', industryFilter);
  // }, [industryFilter]);

  const handleClickIndustry = () => {
    setOpenIndustry(!openIndustry);
  };

  const handleIndustryChange = (event) => {
    // console.log(event.target.name, event.target.checked);
    const values = [...industryFilter];
    const index = values.indexOf(event.target.name);

    if (index === -1 && event.target.checked) {
      values.push(event.target.name);
    } else if (index >= 0 && !event.target.checked) {
      values.splice(index, 1);
    }

    setIndsutryFilter(values);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleApply = (event) => {
    event && event.preventDefault();

    // console.log('textSearch', search.text, 'enumSearch: ', industryFilter);
    setState({ ...state, ['left']: false });
    // eslint-disable-next-line react/prop-types
    props.onSearch({textSearch: search.text || '', enumSearch: industryFilter});
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // console.log('toggleDrawer: ', anchor, open);
    // console.log('toggleDrawer: ', event.type, event.key);
    if (
      event.type === 'keydown' && event.key !== 'Enter'
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role='presentation'
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List disablePadding style={{ margin: '0.9rem' }}>
        <CustomSearch textSearch={search.text} onSearch={handleApply} />
      </List>
      
      <Divider />
      <List>
        {/** Industry */}
        <ListItem button onClick={handleClickIndustry}>
          <ListItemText primary='Industry' disableTypography />
          {openIndustry ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openIndustry} timeout='auto'>
          <List component='div' disablePadding>
            {industry.map((item, idx) => {
              return (
                <ListItem
                  key={item + idx}
                  button
                  className={classes.nested}
                  disableGutters
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={industryFilter.some((v) => v === item.name)}
                        value={item.code}
                        onChange={handleIndustryChange}
                        name={item.name} ///'Ecommerce'
                        color='primary'
                      />
                    }
                    label={
                      <Box component='div' fontSize={15}>
                        {item.name}
                      </Box>
                    } //'Ecommerce'
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          spacing={1}
        >
          <Grid item xs={6} sm={6} md={6}>
            <Button onClick={handleApply} color='primary' size='sm' round>
              <FilterListIcon className={classes.icons} /> Apply
            </Button>
          </Grid>
        </Grid>
      </List>
      <Divider />
      
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button link onClick={toggleDrawer(anchor, true)}>
            <i className={'fas fa-bars'} /> Search & Filtering
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

