import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import InvestorCardListSection from 'views/InvestorPage/Sections/InvestorCardListSection';
import InvestorCardListMobile from 'views/InvestorPage/Sections/InvestorCardListMobile';

import styles from "assets/jss/material-kit-react/views/ourStartupsPage.js";

const useStyles = makeStyles(styles);

export default function InvestorCardList(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        //color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <div className={classNames(classes.main, classes.mainRaised)}>
        {matches ? <InvestorCardListMobile /> : <InvestorCardListSection />}
      </div>
      <Footer />
    </div>
  );
}
