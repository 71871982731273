/* eslint-disable react/prop-types */
import React from 'react';
import Cropper from 'react-easy-crop';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';
import {useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

// import Slider from '@material-ui/core/Slider';

import {generateDownload} from 'functions/utils/cropImage';
import getCroppedImg from 'functions/utils/cropImage';
// eslint-disable-next-line no-unused-vars
import {dataURLtoFile} from 'functions/utils/dataURLtoFile';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  modalContainer: {
    height: '70vh',
    width: '80vw',
    // overflow: 'hidden',
    // position: 'relative',
  },
  cropper: {
    height: '90%',
    position: 'relative',
  },
  slider: {
    height: '10%'
  },
  buttonContainer: {
    height: '10%',
    border: '1px solid #f5f5f5',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  button: {
    marginRight: '10px',
  },
  imgPreview: {
    width: '200px',
    height: '200px',
    float: 'left',
    marginLeft: '10px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CropImageDialog(props) {
  const {result} = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [image, _setImage] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({
    unit: '%', // default, can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100
  });
  const [zoom, setZoom] = React.useState(1);

  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => {
    setLoading(true);
    inputRef.current.click();
  }

  React.useEffect(() => {
    // console.log(inputRef.current);
    inputRef.current && image && setLoading(false);
  }, [inputRef.current, image]);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
    
  };

  const onCropComplete = (croppedAreaPercentage, cropedAreaPixels) => {
    // console.log(croppedAreaPercentage, cropedAreaPixels);
    setCroppedArea(cropedAreaPixels);
  };

  const onSelectFile = (event) => {

    const {files} = event.target;

    if(files && files.length > 0){
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.addEventListener('load', () => setImage(reader.result));
    }
  };

  const onDownload = async () => {
    try{
      if(!image) {
        dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: 'Please choose an image'}});
        return;
      }
      setLoading(true);
      await generateDownload(image, croppedArea);
      setLoading(false);
    }catch(error) {
      console.log(error);
      setLoading(false);
    }
    
  };

  const onUpload = async () => {
    console.log('onUpload');
    setLoading(true);
    if(!image) {
      dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: 'Please choose an image'}});
      setLoading(false);
      return;
    }

    const canvas = await getCroppedImg(image, croppedArea);

    canvas.toBlob((blob) => {
      Resizer.imageFileResizer(blob // the file from input
        , 200 // width
        , 200 // height
        , 'JPEG' // 'JPEG' // compress format WEBP, JPEG, PNG // safari not support webp
        , 100 // quality
        , 0 // rotation
        , (uri) => { 
          
          // console.log('uri: ', uri);
          // const convertedUrlToFile = dataURLtoFile(uri, props.imageName);
          // console.log('convertedUrlToFile: ', convertedUrlToFile);

          axios.post(`${process.env.REACT_APP_API}/uploadimage`, { 
            image: uri, gid: props.gid, folder: props.folder, name: props.imageName || '' })
            .then((res) => {
              // console.log('uploadimage: ', res);
              console.log('onUpload completed');
              setImage(null);
              setLoading(false);
              result(res.data);
            })
            .catch((error) => {
              console.log(error);
              console.log(error.response.data);
              dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: error.response.data}});
              setLoading(false);
              result(null);
            });

         }, 'base64' // blob or base64 default base64
      );
  
    }, 'image/jpeg', 0.66); // 0.66 (recommend)== qualiy of image 66% if 1 means 100%
  
  };

  const handleClose = () => {
    // console.log(open, props.openDialog, props.handle);
    props.handle();
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullScreen open={props.openDialog} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} style={{ background: '#2E3B55' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" className={classes.title}>
              Crop Image and upload
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        
        <Container>
        <Box my={2}>
          <Container className={classes.buttonContainer}>
            <input ref={inputRef} accept="image/*" hidden type="file" onChange={onSelectFile} />
            <Button
              onClick={() => setImage(null)}
              className={classes.button} 
              variant='contained' color='default'>Clear</Button>
            <Button 
              className={classes.button}
              onClick={triggerFileSelectPopup}
              variant='contained' color='primary'>Choose</Button>
            <Button 
              className={classes.button}
              onClick={onDownload}
              variant='contained' color='primary'>Download</Button>
            <Button variant='contained' color='primary' onClick={onUpload}>Upload</Button>
            
            </Container>
          <Container >
            {/*image ? (
              <div className={classes.modalContainer}>
                {false && <div className={classes.slider}>
                  <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
                </div>}
                <div className={classes.cropper}>
                  <Cropper zoomWithScroll={false} image={image} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
                </div>
            </div>) : (<></>)*/}
            
            {image ? <div className={classes.modalContainer}>
              <div className={classes.cropper}>
                <Cropper zoomWithScroll={false} image={image} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
              </div>
            </div> : (<><br/>Please choose image</>)}
          </Container>

        </Box>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Container>

      </Dialog>
    </div>
  );
}
