import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {APP_LOAD,} from 'constants/actionTypes';
import {APP_LOADED} from 'constants/actionTypes';
// import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import useStartup from 'hooks/useStartup';
import StartupCard from 'views/OurStartupsPage/components/StartupCard';
import SignupDialog from "views/OurStartupsPage/components/SignupDialog";
import Search from 'views/OurStartupsPage/Sections/Search';
import no_img from 'assets/img/no_img.png';
import { makeStyles } from '@material-ui/core/styles';

const style = {
  gridContainer: {
    display: 'flex',
    //alignItem: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({... style,}));

// eslint-disable-next-line no-unused-vars
const StartupGridListSection = (props) => {
  const { common } = useSelector((state) => ({ ...state }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const {error: errorSearchStartup, data: ourStartups, onGetAll, onSearchOurStartup, loading: loadingSearchStartup} = useStartup();

  React.useEffect(() => {
    onGetAll();
  }, []);

  React.useEffect(() => {
    loadingSearchStartup && dispatch({type: APP_LOAD});
    if(!loadingSearchStartup) {
      dispatch({type: APP_LOADED});
      // console.log('ourStartups: ', ourStartups);
      setLoading(false);
    }
    
  }, [loadingSearchStartup]);

  const handleOnClick = (v) => {
    // console.log('common.currentUser', common.currentUser)
    if(!common.currentUser) {
      setOpenDialog(true);
      return;
    }
    //console.log(v);
    window.open(`${process.env.REACT_APP_STARTUP_INFO_URL}?p=${v.slug}`);
  };

  return (
    <div>
      <SignupDialog openSignup={openDialog} onCloseSignup={() => setOpenDialog(false)} />
      <div>
        <Search onSearch={onSearchOurStartup} />
        <h4 id='back-to-top-anchor' style={{ textAlign: 'center' }}>Our startups</h4>
        <br/>
      </div>
      <div className={classes.gridContainer}>
        
        {!loading &&
          ourStartups.map((v) => (
            <StartupCard
              key={v.startupName}
              onClick={() => handleOnClick(v)}
              title={v.startupName}
              imageUrl={v.startupLogo2 && v.startupLogo2[0] ? v.startupLogo2[0].url : no_img}
              {...v}
            />
          ))}
      </div>
    </div>
    
  );
};

export default StartupGridListSection;
