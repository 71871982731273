import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import CssBaseline from '@material-ui/core/CssBaseline';

import tileData from './tileData';

const drawerWidth = 250;

const useDrawerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    height: "100%",
    // height: 1500,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: 1200,
    marginTop: '0.5em',
    overflow: "auto",
    //height: "100%",
    [theme.breakpoints.up("md")]: {
      overflow: "auto",
      width: drawerWidth,
      position: "relative",
      //height: "100%",
      height: 1200,
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(2),
    paddingTop:0,
    paddingBottom:0,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    height: 1000,
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.up('sm')]: {
      width: 800,
    },
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  nested: {
    paddingLeft: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  rootNumberEmployee: {
    width: 200,
    margin: '0.9rem',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */


export default function TitlebarGridList() {
  const classes = useStyles();

  const matches = useMediaQuery('(max-width:960px)');
  const matchesMax = useMediaQuery('(min-width:1160px)');
  // const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classesDrawer = useDrawerStyles();


  return (
    <div className={classesDrawer.root}>
      <CssBaseline />
      <main className={classesDrawer.content}>
        <div>
          <div className={classes.typo}>
            <h4 style={{ textAlign: 'center' }}>All of investor</h4>
          </div>
          <div className={classes.root}>
            <GridList
              cellHeight={200}
              cols={matches ? 2 : matchesMax ? 4 : 3}
              className={classes.gridList}
            >
              {/*<GridListTile key='Subheader' cols={4} style={{ height: 'auto' }}>
                <ListSubheader component='div'>Our startups</ListSubheader>
              </GridListTile>*/}
              {tileData.map((tile, idx) => (
                <GridListTile
                  key={tile.img + idx}
                  rows={tile.rows || 1}
                  cols={tile.cols || 1}
                >
                  <img src={tile.img} alt={tile.title} />
                  <GridListTileBar
                    title={tile.title}
                    subtitle={<span>by: {tile.author}</span>}
                    actionIcon={
                      <IconButton
                        //onClick={() => history.push('/startup-info')}
                        href={`${process.env.REACT_APP_INVESTOR_INFO_URL}?id=${idx}`}
                        target='_blank'
                        aria-label={`info about ${tile.title}`}
                        className={classes.icon}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </div>
      </main>
    </div>
  );
}
