/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import AdminNav from 'components/AdminNav/AdminNav';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import Footer from "components/Footer/Footer";

import useStartupMarket from 'hooks/useStartupMarket';
import useCurrentUser from 'hooks/useCurrentUser';
import CustomizedDialogs from 'components/CustomDialogs/CustomDialogs';
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

// const rows = [
//   createData('Cupcake', 'Bakery Market', 'www.bluchic.com'),
//   createData('Donut', 452, 25.0),
//   createData('Eclair', 262, 16.0),
//   createData('Frozen yoghurt', 159, 6.0),
//   createData('Gingerbread', 356, 16.0),
//   createData('Honeycomb', 408, 3.2),
//   createData('Ice cream sandwich', 237, 9.0),
//   createData('Jelly Bean', 375, 0.0),
//   createData('KitKat', 518, 26.0),
//   createData('Lollipop', 392, 0.2),
//   createData('Marshmallow', 318, 0),
//   createData('Nougat', 360, 19.0),
//   createData('Oreo', 437, 18.0),
// ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StartupMarketList = (props) => {
  const { classes } = props;
  const {error: errorCurrentUser} = useCurrentUser();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [search, setSearch] = React.useState("");
  //const [startupMarkets, setStartupMarkets] = React.useState([]);
  const [currentItem, setCurrentItem] = React.useState(null);
  const [confirmDelModel, setConfirmDelModel] = React.useState(false);
  const [errorModel, setErrorModel] = React.useState(false);

  const {onCreate, onGetAll, onDelete, onSearch, data, error: errorStartupMarket} = useStartupMarket();
 
  // React.useEffect(() => {
  //   currentItem && console.log('currentItem: ', currentItem);
  // }, [currentItem]);

  React.useEffect(() => {
    errorCurrentUser && console.log('errorCurrentUser: ', errorCurrentUser);
  }, [errorCurrentUser]);

  React.useEffect(() => {
    errorStartupMarket && console.log('errorStartupMarket: ', errorStartupMarket);
    if(errorStartupMarket) {
      setErrorModel(true);
    }
  }, [errorStartupMarket]);

  // React.useEffect(() => {
  //   data && console.log('data: ', data);
  // }, [data]);

  React.useEffect(() => {
    onGetAll();
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    // console.log(emptyRows, rowsPerPage, data.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const onChangeSearch = (event) => {
  //   event.preventDefault();

  //   if(event.target.value) {
  //     setSearch(event.target.value);
  //   }
    
  // };

  // eslint-disable-next-line no-unused-vars
  const handleEnterSearch = (event) => {
    event.preventDefault();

    // console.log(event.key);
    
    onSearch({textSearch: event.target.value, enumSearch: null});

  };

  const handleCellClick = (e) => {
    // console.log(e);
    const found = data.find(element => element.startupName === e.target.textContent);
    //console.log(found);
    setCurrentItem(found);
  }

  const handleNewItem = (e) => {
    e.preventDefault();
    setCurrentItem({startupName: "", industry: "", website: ""});
  }; 

  const handleSaveItem = (e) => {
    e.preventDefault();
    if(!currentItem || !currentItem.startupName || !currentItem.industry || !currentItem.website) {
      return;
    }
    onCreate(currentItem);
    setCurrentItem({startupName: "", industry: "", website: ""});
  }; 

  const handleDelItem = (e) => {
    e.preventDefault();
    setConfirmDelModel(true);
  };

  const handleConfirmDel = (e) => {
    e.preventDefault();
    setConfirmDelModel(false);
    console.log('confirm delete:', currentItem);

    if(currentItem && currentItem.startupName){
      onDelete(currentItem.startupName);
      setCurrentItem({startupName: "", industry: "", website: ""});
    }
    
  };

  const handleStateChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    setCurrentItem({
      ...currentItem,
      [name]: event.target.value,
    });
  };

  const renderList = () => {
    return (
      <div>
      <TableContainer  component={Paper}>
            <Table
              className={classes.table}
              aria-label='custom pagination table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Industry</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row) => (
                  <TableRow hover key={row.startupName} onClick={handleCellClick}>
                    <TableCell
                      //style={{ width: 260 }}
                      component='th'
                      scope='row'
                      // onClick={handleCellClick} 
                    >
                      {row.startupName}
                    </TableCell>
                    <TableCell
                      //style={{ width: 260 }}
                      component='th'
                      scope='row'
                      // onClick={handleCellClick} 
                    >
                      {row.industry}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 },
                    ]}
                    colSpan={3}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </div>
    );
  };

  return (
    <div>
      <AdminNav />
      {currentItem && <CustomizedDialogs
        handleConfirm={handleConfirmDel}
        title={'Startup Market'}
        content={`Delete "${currentItem?.startupName}" ?`}
        isOpen={confirmDelModel}
        handleClose={() => setConfirmDelModel(false)}
      />}
      {errorStartupMarket && <CustomizedDialogs
        title={'Error'}
        content={`${errorStartupMarket?.error?.message || ''}`}
        isOpen={errorModel}
        handleClose={() => setErrorModel(false)}
      />}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='center' spacing={2}>
              <Grid xs={12} sm={12} md={6} item>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    justify='center'
                    alignItems='center'
                    direction='row'
                  >
                    <Grid xs={12} sm={12} md={12} item>
                      <CustomInput
                        labelText='Search...'
                        id='search'
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: 'search',
                          type: 'text',
                          onKeyUp: (event) => event.key === "Enter" ? handleEnterSearch(event) : null,
                          //value: state.firstname,
                        }}
                      />
                    </Grid>

                    <Grid xs={10} sm={8} item></Grid>
                    <Grid xs={12} sm={12} md={12} item>
                      <h4>Startup Market List</h4>
                      {renderList()}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid xs={12} sm={12} md={6} item>
                <Paper className={classes.paper}>
                  <Grid container justify='center' direction='row'>
                    <Grid xs={12} sm={12} md={8} item>
                      <Paper className={classes.paper3}>
                        <Typography align='center' variant='h6'>
                          Startup Market
                        </Typography>

                        <Button
                          color='facebook'
                          simple
                          fullWidth
                          // eslint-disable-next-line react/prop-types
                          onClick={handleNewItem}
                        >
                          Click here to new
                        </Button>
                      </Paper>
                    </Grid>
                    <Grid xs={12} sm={12} md={8} item>
                      <Paper className={classes.paperWhite}>
                        <Typography align='center' variant='h6'></Typography>
                        {!currentItem && (
                          <Typography
                            color='textPrimary'
                            align='center'
                            variant='subtitle1'
                            paragraph
                          >
                            You haven’t click a startup name yet.
                          </Typography>
                        )}
                        {currentItem && (
                          <Grid container justify='center' direction='row'>
                            <Grid xs={12} sm={12} md={12} item>
                              <CustomInput
                                labelText='StartupName...'
                                id='startupname'
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  name: 'startupName',
                                  type: 'text',
                                  onChange: (event) => handleStateChange(event),
                                  value: currentItem?.startupName || '',
                                }}
                              />
                            </Grid>
                            <Grid xs={12} sm={12} md={12} item>
                              <CustomInput
                                labelText='industry...'
                                id='industry'
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  name: 'industry',
                                  type: 'text',
                                  onChange: (event) => handleStateChange(event),
                                  value: currentItem?.industry || '',
                                }}
                              />
                            </Grid>
                            <Grid xs={12} sm={12} md={12} item>
                              <CustomInput
                                labelText='website...'
                                id='website'
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  name: 'website',
                                  type: 'text',
                                  onChange: (event) => handleStateChange(event),
                                  value: currentItem?.website || '',
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {currentItem && <Grid container justify='space-around' direction='row'>
                          <Grid xs={4} sm={4} md={4} item>
                            <Button
                              color='facebook'
                              block
                              disabled={!currentItem || !currentItem.startupName || !currentItem.industry || !currentItem.website}
                              onClick={handleSaveItem}
                            >
                              save
                            </Button>
                          </Grid>
                          {currentItem.startupName !== '' && <Grid xs={4} sm={4} md={4} item>
                            <Button
                              color='danger'
                              block
                              onClick={handleDelItem}
                            >
                              delete
                            </Button>
                          </Grid>}
                        </Grid>}
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );

  // return (
  //   <div className='container-fluid'>
  //     <div className='col-md-1'>
  //       <AdminNav />
  //     </div>
  //     <div className={classes.form}>
  //       <Grid container>
  //         <Grid className={classes.search} item md={12}>
  //           <TextField
  //             label='Search'
  //             value={search}
  //             onChange={onChangeSearch}
  //           />
  //           <Button
  //             size='small'
  //             variant='outlined'
  //             className={classes.textField}
  //             //onClick={this.searchTitle}
  //           >
  //             Search
  //           </Button>
  //         </Grid>
  //         <Grid item md={4}>
  //           <h2>Startup Market List</h2>
  //           <div className='list-group'>
  //             {startupMarkets &&
  //               startupMarkets.map((tutorial, index) => (
  //                 <ListItem
  //                   selected={index === currentIndex}
  //                   onClick={() => this.setActiveTutorial(tutorial, index)}
  //                   divider
  //                   button
  //                   key={index}
  //                 >
  //                   {tutorial.title}
  //                 </ListItem>
  //               ))}
  //           </div>

  //         </Grid>
  //         <Grid item md={8}>
  //           {currentStartupMarket ? (
  //             <div className={classes.tutorial}>
  //               <h4>Startup Market</h4>
  //               <div className={classes.detail}>
  //                 <label>
  //                   <strong>Name:</strong>
  //                 </label>{' '}
  //                 {currentStartupMarket.startupName}
  //               </div>

  //               <div className={classes.detail}>
  //                 <label>
  //                   <strong>Industry:</strong>
  //                 </label>{' '}
  //                 {currentStartupMarket.industry}
  //               </div>

  //               <div className={classes.detail}>
  //                 <label>
  //                   <strong>Website:</strong>
  //                 </label>{' '}
  //                 {currentStartupMarket.website}
  //               </div>

  //               <Link
  //                 to={'/admin/startup-market/' + currentStartupMarket.name}
  //                 className={classes.edit}
  //               >
  //                 Edit
  //               </Link>
  //             </div>
  //           ) : (
  //             <div>
  //               <br />
  //               <p className={classes.tutorial}>
  //                 Please click on a Tutorial...
  //               </p>
  //             </div>
  //           )}
  //         </Grid>
  //       </Grid>
  //     </div>
  //   </div>
  // );
};

export default withStyles(styles)(StartupMarketList)