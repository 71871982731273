/* eslint-disable react/prop-types */
import React from 'react';

import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';

// @material-ui/core components
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Typography from  '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// @material-ui/icons
import PersonPinIcon from '@material-ui/icons/PersonPin';
//import SettingsIcon from '@material-ui/icons/Settings';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
// import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

// core components
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';
import Footer from 'components/Footer/Footer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import SnackbarContent from "components/Snackbar/SnackbarContent";

import GeneralInformaton from 'views/StartupPage/Sections/GeneralInformaton';
import BusinessModel from 'views/StartupPage/Sections/BusinessModel';
import Financial from 'views/StartupPage/Sections/Financial';
import Team from 'views/StartupPage/Sections/Team';
import TermAndCondition from 'views/StartupPage/Sections/TermAndCondition';

// import {startupProfileApi} from 'api/startup';
import useCurrentUser from 'hooks/useCurrentUser';
// import useStartup from 'hooks/useStartup';
// import {SAVE_DRAF_SIGNUP} from 'constants/actionTypes';
import {CLEAR_DRAF_SIGNUP} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
// import getQueryVariable from 'functions/getQueryVariable';
import styles from 'assets/jss/material-kit-react/views/startupSignup';

import image from 'assets/img/bg7.jpg';

const useStyles = makeStyles((theme) => ({
  ...styles,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const getStepsLabel = () => {
  return [
    'General information',
    'Business Model',
    'Financial',
    'Team',
    'Term & condition',
  ];
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  
  const { active, completed } = props;

  const icons = {
    1: <ContactMailIcon />,
    2: <BusinessCenterIcon />,
    3: <MonetizationOnIcon />,
    4: <GroupAddIcon />,
    5: <PlaylistAddCheckIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useMobileStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

export default function StartupSignupPage(props) {
  const {common, auth} = useSelector((state) => ({...state}));
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const classesMobile = useMobileStyles();
  // const {data: dataStartupProfile, error: errorStartupHook, onGetStartup} = useStartup();
  const { ...rest } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [completed, setCompleted] = React.useState({});
  const [isValidToNext, setIsValidToNext] = React.useState(false);
  const [isNewProfile, setIsNewProfile] = React.useState(false);
  //const [startupProfile, setStartupProfile] = React.useState(null);
  const {error: errorUseCurrentUser} = useCurrentUser();

  React.useEffect(() => {
    dispatch({ type: CLEAR_DRAF_SIGNUP, payload: null });
    setIsNewProfile(true);
    // const startupname = getQueryVariable('p');

    // if(startupname){
    //   //console.log(startupname);
    //   //console.log(window.location.search);

    //   if (!common.currentUser || !common.currentUser.token) {
    //     props.history.push('/profile');
    //     return;
    //   }
      
    //   setIsNewProfile(false);
    //   onGetStartup(startupname);
    //   // startupProfileApi(common.currentUser.token, startupname)
    //   // .then(resp => dispatch({ type: SAVE_DRAF_SIGNUP, payload: resp.data }))
    //   // .catch(error => {
    //   //   console.log(error);
    //   //   props.history.push('/profile');
    //   //   return;
    //   // });
    // } else {
      
    //   setIsNewProfile(true);
    //   dispatch({ type: CLEAR_DRAF_SIGNUP, payload: null });
    // }
  }, []);

  // React.useEffect(() => {
  
  //   if(dataStartupProfile) {
  //     dispatch({ type: SAVE_DRAF_SIGNUP, payload: dataStartupProfile });
  //   }
  // }, [dataStartupProfile]);

  React.useEffect(() => {
    errorUseCurrentUser && console.log('errorUseCurrentUser', errorUseCurrentUser)
    errorUseCurrentUser && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on retrieve current user', message: errorUseCurrentUser.error.message}});
  }, [errorUseCurrentUser]);

  // React.useEffect(() => {
  //   if (errorStartupHook) {
  //     console.log('errorStartupHook', errorStartupHook);
  //     props.history.push('/profile');
  //     return;
  //   } //dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error on retrieve current user', message: errorUseCurrentUser.error.message}});
  // }, [errorStartupHook]);

  const steps = getStepsLabel();

  // eslint-disable-next-line no-unused-vars
  const getStepContent = (step) => {
    // console.log(step)
    switch (step) {
      case 0:
        return <GeneralInformaton isNewProfile={isNewProfile} handleComplete={handleComplete} validToNext={setIsValidToNext} setLoading={setLoading} />;
      case 1:
        return <BusinessModel setLoading={setLoading} handleComplete={handleComplete} validToNext={setIsValidToNext} />;
      case 2:
        return <Financial setLoading={setLoading} handleComplete={handleComplete} validToNext={setIsValidToNext} />;
      case 3:
        return <Team setLoading={setLoading} handleComplete={handleComplete} validToNext={setIsValidToNext} />;
      case 4:
        return <TermAndCondition setLoading={setLoading} handleComplete={handleComplete} validToNext={setIsValidToNext} />;
      case 5:
        return <TermAndCondition setLoading={setLoading} handleComplete={handleComplete} validToNext={setIsValidToNext} />;
      default:
        return 'Unknown step';
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep+1) > 4 ? 4 : prevActiveStep + 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  // eslint-disable-next-line no-unused-vars
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // eslint-disable-next-line no-unused-vars
  const handleReset = () => {
    setActiveStep(0);
  };

  const handleToMyProfile = () => {
    props.history.push('/profile');
  };

  setTimeout(() => {
    setCardAnimation('');
  }, 700);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const stepContent = () => {
    return (
      <div>
        {allStepsCompleted() ? (
          <div>All steps completed - you&apos;re finished</div>
        ) : (
          <div>{getStepContent(activeStep)}</div>
        )}
      </div>
    );
  };

  const mobileStep = () => {
    return (
      <div>
        <MobileStepper
          variant='dots'
          steps={steps.length}
          position='static'
          activeStep={activeStep}
          className={classesMobile.root}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
              disabled={!isValidToNext || activeStep === 5}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
        {stepContent()}
      </div>
    );
  };

  const handleStep = (step) => () => {
    isValidToNext && setActiveStep(step);
    setIsNewProfile(false);
  };

  const stepLarge = () => {
    return (
      <div className={classes.root}>
        <Stepper
          nonLinear
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={handleStep(index)}
                completed={completed[index]}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {stepContent()}
        
        
      </div>
    );
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Header
        absolute
        color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes[cardAnimaton]}>
                <form
                  className={classes.form + ' ' + classes.margintop1rem}
                  encType='multipart/form-data'
                  method='POST'
                >
                  <p className={classes.divider}></p>
                  <CustomTabs
                    headerColor='danger'
                    tabs={[
                      {
                        tabName: 'Full Fill information',
                        tabIcon: PersonPinIcon,
                        tabContent: (
                          <CardBody className={classes.cardBodyNoPadding}>
                            {!matches && stepLarge()}
                            {matches && mobileStep()}
                            {common.apiResponseError && (<SnackbarContent
                              message={
                                <span>
                                  <b>DANGER ALERT:</b> {common.apiResponseError}
                                </span>
                              }
                              //close
                              color="danger"
                              icon="info_outline"
                            />)}
                          </CardBody>
                        ),
                      },
                    ]}
                  />

                  <CardFooter className={classes.cardFooter}>
                    <Grid container>
                      <Grid item container direction='row'>
                        <Grid item xs={12} sm={12} md={12}>
                          <Button
                            size='small'
                            onClick={handleBack}
                            disabled={activeStep === 0}
                          >
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                            Back
                          </Button>

                          {activeStep < 4 && <Button
                            style={{ float: 'right' }}
                            size='small'
                            onClick={handleNext}
                            disabled={!isValidToNext || activeStep === steps.length - 1}
                          >
                            Next
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>}
                          {activeStep >= 4 && <Button
                            style={{ float: 'right' }}
                            size='small'
                            onClick={handleToMyProfile}
                            disabled={!auth.drafSignup.termAndCondition}
                          >
                            complete
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
