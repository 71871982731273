import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Devider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import BusinessIcon from '@material-ui/icons/Business';
import RoomIcon from '@material-ui/icons/Room';
import AirplayIcon from '@material-ui/icons/Airplay';
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  itemGrid: {
    marginLeft: "0",
    marginRight: "0",
    
  },

}));

const Summary = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <GridContainer
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        <GridItem xs={12} sm={12} md={12} className={classes.itemGrid}>
          <Paper elevation={5}>
            <GridContainer
              direction='column'
              justify='flex-start'
              alignItems='flex-start'
            >
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <InfoIcon />
                      <span>&nbsp;ABOUT:&nbsp;</span>
                    </div>
                  </Box>
                </Typography>
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    Every landing page needs a small description after the big
                    bold title, that&apos;s why we added this text here. Add
                    here all the information that can make you or your product
                    create the first impression.
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <RoomIcon />
                      <span>&nbsp;LOCATION:&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                  9/227, KHLONG NUNG, KHLONG LUANG, PATHUM THANI 12120
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <BusinessIcon />
                      <span>&nbsp;INVESTMENT TYPES:&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                  Software / Platform services
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <NetworkCheckIcon />
                      <span>&nbsp;INVESTMENT SATGE:&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                  Series A
                  </Box>
                </Typography>
                <Devider />
                <Typography component='div'>
                  <Box textAlign='left' fontWeight='fontWeightBold' m={1}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <AirplayIcon />
                      <span> &nbsp;WEBSITE :&nbsp;</span>
                    </div>
                  </Box>
                  <Box textAlign='left' fontWeight='fontWeightLight' m={1}>
                    www.mintformations.co.uk/
                  </Box>
                </Typography>
                <Devider />

              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default Summary;