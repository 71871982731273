import React from "react";
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
// @material-ui/icons
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';

// core components
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HeaderLinks from "components/Header/HeaderLinks";
import NavPills from "components/NavPills/NavPills";
import Parallax from "components/Parallax/Parallax";


// eslint-disable-next-line no-unused-vars
import profile from "assets/img/faces/christian.jpg";

// eslint-disable-next-line no-unused-vars
import work4 from "assets/img/examples/mariya-georgieva.jpg";

import styles from "assets/jss/material-kit-react/views/startupInfoPage.js";
import imageBg from "assets/img/profile-bg.jpg";

import Summary from 'views/InvestorPage/Sections/Summary';

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      //hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
};

const InvestorInfoPage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div>
      <Header
        color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white',
        }}
        {...rest}
      />
      <Parallax small filter image={imageBg} />
      <div className={classNames(classes.main, classes.mainRaised)}>

        <div>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img
                      src={
                        'https://www.mintformations.co.uk/blog/wp-content/uploads/2020/05/shutterstock_583717939.jpg'
                      }
                      alt='...'
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Mint Formations Limited</h3>
                    <h6></h6>
                    <GridContainer
                      justify='center'
                      alignItems='center'
                      direction='row'
                    >
                      <GridItem xs={12} sm={12} md={6}>

                      </GridItem>
                    </GridContainer>
                    <GridContainer
                      justify='center'
                      alignItems='center'
                      direction='row'
                    >
                      <GridItem xs={12} sm={12} md={6}>
   
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color='primary'
                  tabs={[
                    {
                      tabButton: 'Summary',
                      tabIcon: FeaturedPlayListIcon,
                      tabContent: (
                        <GridContainer justify='center'>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ margin: 0 }}
                          >
                            <TabPanel index={0}>
                              <Summary />
                            </TabPanel>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}></GridItem>
                        </GridContainer>
                      ),
                    },
                    
                    
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default InvestorInfoPage;