/* eslint-disable react/prop-types */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { container } from "assets/jss/material-kit-react.js";
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  ... container,
  ... styles,  
  container: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  root: {
    flexShrink: 0,
    //marginLeft: theme.spacing(0),
    padding: '20px',
    //backgroundColor: '#fff',
    //width: '90%',
    //margin: '10px',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: '20px',
    paddingLeft: '30px',
  },
  gridContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#ddd',
  },
}));

const FinancialSection = (props) => {
  const {data} = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="statusOfBusiness"
            label="Status of business"
            fullWidth
            value={data.statusOfBusiness || '...'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="fundraisingStatus"
            name="fundraisingStatus"
            label="Fundraising Status"
            fullWidth
            value={data.fundraisingStatus || '...'}
          />
        </Grid>
        
        {data.fundraisingHistory && data.fundraisingHistory.length > 0 && data.fundraisingHistory.map((v, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={12} sm={12}>
              <Typography variant="caption" gutterBottom>
                Fundraising history Round: {(idx+1)}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={3}>
              <TextField
                name="investmentYear"
                label="Year of investment"
                fullWidth
                value={data.fundraisingHistory[idx].investmentYear || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="investorNumber"
                label="Number of investor"
                fullWidth
                value={data.fundraisingHistory[idx].investorNumber.toLocaleString() || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="fundAmount"
                label="Fund amount"
                fullWidth
                value={data.fundraisingHistory[idx].fundAmount.toLocaleString() || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                name="postMoneyValuation"
                label="Post-money valuation"
                fullWidth
                value={data.fundraisingHistory[idx].postMoneyValuation.toLocaleString() || '...'}
              />
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12} sm={6}>
          <TextField
            id="numberOfUser"
            name="numberOfUser"
            label="Number of user"
            fullWidth
            value={data.numberOfUser && data.numberOfUser.toLocaleString() || '...'}
          />
        </Grid>

        {data.revenueHistory && data.revenueHistory.length > 0 && data.revenueHistory.map((v, idx) => (
          <React.Fragment key={idx}>
            <Grid item xs={12} sm={12}>
              <Typography variant="caption" gutterBottom>
                Revenue history: {(idx+1)}
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField
                name="revenueYear"
                label="Year"
                fullWidth
                value={data.revenueHistory[idx].revenueYear || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="annualRevenue"
                label="Annual revenue"
                fullWidth
                value={data.revenueHistory[idx].annualRevenue.toLocaleString() || '...'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="annualProfit"
                label="Annual profit"
                fullWidth
                value={data.revenueHistory[idx].annualProfit.toLocaleString() || '...'}
              />
            </Grid>

          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default FinancialSection;