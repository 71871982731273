import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {startupUserProfileApi} from 'api/startup';
import {updateUserProfileApi} from 'api/startup';
import {startupProfilesApi} from 'api/startup';
import {investorProfilesApi} from 'api/investor';

async function fetchUserProfile({token}) {
  const response = await startupUserProfileApi(token);
  return response;
}

async function fetchStartupProfile({setLoading, setError, setStartupProfiles, token}) {
  setLoading(true);
  setError("");
  const resp = await startupProfilesApi(token);
  setStartupProfiles(resp.data.name || []);
  setLoading(false);
}

async function fetchInvestorProfile({setLoading, setError, setInvestorProfiles, token}) {
  setLoading(true);
  setError("");
  const resp = await investorProfilesApi(token);
  setInvestorProfiles(resp.data.name || []);
  setLoading(false);
}

async function handleUpdateMyProfile({state, token}) {

  const myMomentObject = moment(state.birthdate, 'DD/MM/YYYY');
  // const dateString = moment(state.birthdate).format('DD/MM/YYYY');
  return await updateUserProfileApi(token, {
    firstname: state.firstname,
    lastname: state.lastname,
    email: state.email,
    tel: state.tel,
    birthdate: myMomentObject.unix() || '',
  });
}

function useMyProfiles() {
  const {common} = useSelector((state) => ({...state}));
  // loading
  const [loading, setLoading] = React.useState(false);
  // error state
  const [error, setError] = React.useState("");
  const [startupProfiles, setStartupProfiles] = React.useState([]);
  const [investorProfiles, setInvestorProfiles] = React.useState([]);
  const [myProfiles, setMyProfiles] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    tel: '',
    birthdate: '',
  });

  const onGetUserProfile = async () => {
    setMyProfiles({});
    setLoading(true);
    setError("");
    await fetchUserProfile({token: common.currentUser.token})
    .then((resp) => {
      const dateString = (resp.data && resp.data.birthdate) ? moment.unix(resp.data.birthdate).format('DD/MM/YYYY') : '';
      setMyProfiles({
        firstname: resp.data.firstname,
        lastname: resp.data.lastname,
        email: resp.data.email,
        tel: resp.data.tel,
        birthdate: dateString, //resp.data.birthdate,
      });
    })
    .catch(error => { console.log(error.response || error); setError(error.response?.data || error.response);});
    setLoading(false);
  }

  const onGetStartupProfiles = async () => {
    setStartupProfiles([]);
    await fetchStartupProfile({setLoading, setError, setStartupProfiles, token: common.currentUser.token})
    .catch(error => { console.log(error.response || error); setError(error.response?.data || error.response);});
  }
  
  const onGetInvestorProfiles = async () => {
    setInvestorProfiles([]);
    await fetchInvestorProfile({setLoading, setError, setInvestorProfiles, token: common.currentUser.token})
    .catch(error => { console.log(error.response || error); setError(error.response?.data || error.response);});
  }

  const onUpdateMyProfile = async (state) => {
    await handleUpdateMyProfile({state, token: common.currentUser.token})
    .then((res) => console.log(res))
    .catch(error => { console.log(error.response || error); setError(error.response?.data || error.response);});
  }

  return {loading, error, myProfiles, startupProfiles, investorProfiles, onGetUserProfile, onGetStartupProfiles, onGetInvestorProfiles, onUpdateMyProfile};
}

export default useMyProfiles;