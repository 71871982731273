import React from 'react';
import AdminNav from 'components/AdminNav/AdminNav';

const AdminDashboard = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-md-1'>
          <AdminNav />
        </div>
        <div className='col'></div>
      </div>
    </div>
  );
};

export default AdminDashboard;
