/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, {lazy, Suspense} from 'react';
import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import { createLogger } from 'redux-logger';
//redux setup
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { localStorageMiddleware } from 'middleware';
import rootReducer from 'reducers';

import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from "history";

// eslint-disable-next-line no-unused-vars
import {ThemeProvider} from '@material-ui/styles';
import { Router, Route, Switch, } from 'react-router-dom';
import { push } from 'react-router-redux';
import reportWebVitals from './reportWebVitals';
import 'assets/scss/material-kit-react.scss?v=1.9.0';
import {LOAD_GUEST_ID} from 'constants/actionTypes';
import {REDIRECT} from 'constants/actionTypes';
import {LOGGED_IN_USER} from 'constants/actionTypes';
import {APP_LOAD, APP_LOADED, ERROR_MODAL_CLOSE, STATUS_MODAL_CLOSE} from 'constants/actionTypes';

import guestId from 'functions/guestId';
import agent from 'agent';
import { authFirebase } from './firebase';
import { authCurrentUserApi } from 'api/auth';

import theme from 'assets/jss/theme';

// pages for this product
// import Components from 'views/Components/Components';
const Components = lazy(() => import('views/Components/Components'));
import LandingPage from 'views/LandingPage/LandingPage';
import ContactPage from 'views/LandingPage/ContactPage';
import VerifyPage from 'views/LandingPage/VerifyPage';
import Action from 'components/Action/Action';
import Auth from 'components/Auth/Auth';
import Home from 'components/Home/Home';
import InviteFriends from 'views/LandingPage/InviteFriends';
import ProfilePage from 'views/ProfilePage/ProfilePage';
import MyProfile from 'views/ProfilePage/MyProfile';
import LoginPage from 'views/LoginPage/LoginPage';
import Logout from 'views/LoginPage/Logout';
import ForgotPassword from 'views/LoginPage/ForgotPassword';
import InvestorSignup from 'views/InvestorPage/InvestorSignup';
import StartupSignup from 'views/StartupPage/StartupSignup';
import StartupProfile from 'views/StartupPage/StartupProfile';
import StartupMarket from 'views/StartupMarket/StartupMarket';
import OurStartupsPage from 'views/OurStartupsPage/OurStartupsPage';
import StartupInfoPage from 'views/StartupInfoPage/StartupInfoPage';
import SearchStartup from 'views/StartupPage/SearchStartup';
import SearchInvestor from 'views/InvestorPage/SearchInvestor';
import InvestorCardList from 'views/InvestorPage/InvestorCardList';
import InvestorInfoPage from 'views/InvestorPage/InvestorInfoPage';
import InvestorProfile from 'views/InvestorPage/InvestorProfile';
import UserRoute from 'views/routes/UserRoute';
import AdminRoute from 'views/routes/AdminRoute';
import AdminDashboard from 'views/AdminPage/AdminDashboard';
import StartupMarketList from 'views/AdminPage/StartupMarketList';
import AdminStartups from 'views/AdminPage/manage/Startups';
import AdminStartupDetail from 'views/AdminPage/manage/StartupDetail';

import Spinner from 'components/Spinner/Spinnes';
import CustomDialog from 'components/CustomDialogs/CustomDialogs';

const history = createBrowserHistory();
// Build the middleware for intercepting and dispatching navigation actions
const myRouterMiddleware = routerMiddleware(history);

const getMiddleware = () => {

  if (process.env.REACT_APP_MODE !== 'prod' && process.env.REACT_APP_MODE !== 'uat') {
    console.log('process.env: ', process.env);
    // Enable additional logging in non-production environments.
    return applyMiddleware(myRouterMiddleware, localStorageMiddleware, createLogger());
  } else {
    return applyMiddleware(myRouterMiddleware, localStorageMiddleware);
  }

  // return applyMiddleware(myRouterMiddleware, localStorageMiddleware);
};

// store reducer
const store = createStore(rootReducer, composeWithDevTools(getMiddleware()));

const App = () => {

  const { common } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    loading && dispatch({type: APP_LOAD});
    !loading && dispatch({type: APP_LOADED});
  }, [loading]);

  const onRedirect = () =>
    dispatch({ type: REDIRECT });

  const getCurrentUser = (user, token) => {
    setLoading(true);
    authCurrentUserApi({authtoken: token})
          .then((response) => {
            // eslint-disable-next-line no-debugger
            //debugger;
            // console.log('authCurrentUserApi:' , response);

            const gid = window.localStorage.getItem('gid');
            if (!gid) {
              window.localStorage.setItem('gid', response.data.gid || guestId());
            } else if (gid && gid !== response.data.gid) {
              console.log('replace gid');
              window.localStorage.setItem('gid', response.data.gid || guestId());
            }

            dispatch({
              type: LOGGED_IN_USER,
              payload: {
                user: {
                  name: user.name || user.email,
                  email: user.email,
                  role: response.data.role,
                  token: token,
                  isInvestor: response.data.isInvestor,
                  isStartup: response.data.isStartup,
                },
              },
            });

            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
  };

  React.useEffect(() => {
    setLoading(true);

    // for all new customer in the first time
    const gid = window.localStorage.getItem('gid');

    if (!gid) {
      window.localStorage.setItem('gid', guestId());
      setLoading(false);
    } else if (gid) {
      setLoading(false);
      dispatch({type: LOAD_GUEST_ID, gid: gid});
    }

    // to check firebase auth state
    const unsubscribe = authFirebase.onAuthStateChanged(async (user) => {
      //console.log('onAuthStateChanged: ', user);
      // eslint-disable-next-line no-debugger
      //debugger;
      if (user) {
        try {
          setLoading(true);
          const idTokenResult = await user.getIdTokenResult();
          const jwtjson = window.localStorage.getItem('jwt');
          if(jwtjson) {
            const token = JSON.parse(jwtjson);
            agent.setToken(idTokenResult.token); // set new token
            // eslint-disable-next-line no-debugger
            //debugger;console.log(token.r, token.t);
            getCurrentUser(user, idTokenResult.token);
          }
        } catch (error) {
          console.log('onAuthStateChanged-error', error);
          setLoading(false);
        } finally {
          setLoading(false);
        }
        
      }

      
    });

    // cleanup
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    //console.log(common);
    // common && common.errorMessage && console.log(common)

    if (common.redirectTo) {
      //console.log('redirectTo: ', common.redirectTo);
      push(common.redirectTo);
      onRedirect();
    }


    // if(common && common.appLoaded) {
    //   setLoading(false);
    // }
  },[common]);

  const handleCloseDialog = (e) => {
    e.preventDefault();

    // dispatch setOpenDialog(false);
    dispatch({type: ERROR_MODAL_CLOSE});
  };

  const handleCloseStatusModal = (e) => {
    e.preventDefault();

    // dispatch setOpenDialog(false);
    dispatch({type: STATUS_MODAL_CLOSE});
  };

  return (
    <div>
      <Spinner 
      // show={true}
      show={!common.appLoaded}
      />
      <CustomDialog
          title={common.statusTitle}
          isOpen={common.statusModalShow}
          handleClose={handleCloseStatusModal}
          content={common.statusMessage}
          alertType={common.alertType}
        />
      <CustomDialog
          title={common.errorTitle}
          isOpen={common.errorModalShow}
          handleClose={handleCloseDialog}
          content={common.errorMessage}
          alertType={common.alertType}
        />
        
      <Switch>
        <AdminRoute
          path='/admin'
          render={({ match: { url } }) => (
            <>
              <Route path={`${url}/`} component={AdminDashboard} exact />
              <Route path={`${url}/home`} component={AdminDashboard} />
              <Route path={`${url}/startupdetail/:slug`} component={AdminStartupDetail} />
              <Route path={`${url}/startups`} component={AdminStartups} />
              <Route path={`${url}/startup-markets`} component={StartupMarketList} />
            </>
          )}
        />
 
        <Route exact path='/profile-page' component={ProfilePage} />
        <UserRoute exact path='/profile' component={MyProfile} />
        <UserRoute exact path='/logout' component={Logout} />
        <Route exact path='/invite-friends' component={InviteFriends} />
        <Route exact path='/contactus' component={ContactPage} />
        <Route exact path='/signupcompleted' component={VerifyPage} />
        <Route exact path="/action" component={Action} />
        <Route exact path='/login-page' component={LoginPage} />
        <Route exact path='/components' component={Components} />
        <Route exact path='/investor-signup' component={InvestorSignup} />
        <Route exact path='/investor-info' component={InvestorInfoPage} />
        <Route exact path='/investors' component={InvestorCardList} />
        <Route exact path='/investor-profile' component={InvestorProfile} />
        <Route exact path='/startup-signup' component={StartupSignup} />
        <Route exact path='/startup-profile' component={StartupProfile} />
        <UserRoute exact path='/investor-search' component={SearchInvestor} />
        <UserRoute exact path='/startup-search' component={SearchStartup} />
        <Route exact path='/startup-market' component={StartupMarket} />
        <Route exact path='/our-startups' component={OurStartupsPage} />
        <Route exact path='/startup-info' component={StartupInfoPage} />
        <Route exact path='/forgot/password' component={ForgotPassword} />
        <Route exact path='/' component={LandingPage} />
      </Switch>
      
    </div>
  );
};

ReactDOM.render(
  <Suspense
    fallback={
      <div className='col text-center p-5'>
        __ <h5>Loading</h5> __ {process.env.REACT_APP_API}
      </div>
    }
  >
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route path='/' component={App} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
    {/*<Provider store={store}>
      <Router history={hist}>
        <Switch>
          <Route path='/profile-page' component={ProfilePage} />
          <Route path='/login-page' component={LoginPage} />
          <Route path='/components' component={Components} />
          <Route path='/investor-signup' component={InvestorSignup} />
          <Route path='/startup-signup' component={StartupSignup} />
          <Route path='/startup-market' component={StartupMarket} />
          <Route path='/our-startups' component={OurStartupsPage} />
          <Route path='/' component={LandingPage} />
        </Switch>
      </Router>
</Provider>*/}
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
