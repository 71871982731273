import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import VerifySection from "./Sections/VerifySection.js";

import landingBg from "assets/img/HeaderDatacenterV.3.jpg";
import landingMBg from "assets/img/Header-mV.2.jpg";
const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function VerifyPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const matcheSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="YAK START"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 100,
          color:  "white"
        }}
        {...rest}
      />
      <Parallax small filter image={!matcheSm ? landingBg : landingMBg}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h2 className={classes.title}></h2>
              <h4>
                
              </h4>
              <br />
              
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <VerifySection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
