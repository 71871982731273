import {
  LOGIN,
  REGISTER,
  SAVE_DRAF_SIGNUP,
  CLEAR_DRAF_SIGNUP,
} from 'constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SAVE_DRAF_SIGNUP:
      return {
        ...state,
        drafSignup: { ...state.drafSignup, ...action.payload },
      };
    case CLEAR_DRAF_SIGNUP:
      // console.log(CLEAR_DRAF_SIGNUP)
      return {
        ...state,
        drafSignup: {},
      };
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        inProgress: false,
        errors: action.error ? action.payload.errors : null,
      };
    default:
      return state;
  }

};
