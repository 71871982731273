import React from 'react';
//import {useHistory} from 'react-router-dom';
import {useDispatch } from 'react-redux';

import { authFirebase } from '../../firebase';
import {LOGOUT} from '../../constants/actionTypes';

const Logout = () => {
  //const history = useHistory();
  const dispatch = useDispatch();

  console.log('Sign-out...');
    authFirebase.signOut().then(() => {
      // Sign-out successful.
      console.log('Sign-out successful');
      dispatch({
        type: LOGOUT,
      });
      //history.push(process.env.REACT_APP_LOGIN_PUSH_URL);

    }).catch((error) => {
      // An error happened.
      console.log(location, error);
    });

  return (<div></div>);
};

export default Logout;