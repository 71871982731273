import axios from 'axios';

export const saveDrafSignup = async (user) => {
  await axios.post(`${process.env.REACT_APP_API}/users`, {user});
};

export const startupRegisterApi = async (authtoken, startup) => {

  return await axios.post(`${process.env.REACT_APP_API}/startup/register`, startup, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const startupProfilesApi = async (authtoken) => {

  return await axios.get(`${process.env.REACT_APP_API}/startup/profiles`, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const startupProfileApi = async (authtoken, startupanme) => {

  return await axios.get(`${process.env.REACT_APP_API}/startup/profile/${startupanme}`, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const searchStartupApi = async (authtoken, query) => {

  const token = axios.CancelToken.source();
  return await axios.get(`${process.env.REACT_APP_API}/startup/search/${query}`, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
      
  }, {cancelToken: token.token});
};

export const startupUserProfileApi = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/user/profile`, {
    headers: {
      authtoken: authtoken,
      'Content-Type': 'application/json',
    },
  });
};

export const updateUserProfileApi = async (authtoken, profile) => {

  return await axios.put(`${process.env.REACT_APP_API}/user/profile`, profile, {
      headers: {
          authtoken: authtoken,
          "Content-Type": "application/json",
      },
  });
};

export const ourstartupsApi = async () => {

  return await axios.get(`${process.env.REACT_APP_API}/ourstartups`);
};

export const searchOurStartupsApi = async ({
  textSearch,
  industrySearch,
  lastFundingTypeSearch,
  keyTechnologySearch,
  startupStateSearch,
  fundraisingStatusSearch,
}) => {
  return await axios.post(`${process.env.REACT_APP_API}/ourstartups/search`, {
    textSearch,
    industrySearch,
    lastFundingTypeSearch,
    keyTechnologySearch,
    startupStateSearch,
    fundraisingStatusSearch,
  });
};