import React from 'react';
import { useSelector } from 'react-redux';
import {searchStartupApi} from 'api/startup';
import {ourstartupsApi} from 'api/startup';
import {searchOurStartupsApi} from 'api/startup';
import {startupProfileApi} from 'api/startup';
import {startupRegisterApi} from 'api/startup';

function useStartup() {
 
  const {common} = useSelector((state) => ({...state}));
  const [query, setQuery] = React.useState('');
  const [data, setData] = React.useState([]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // error state
  const [error, setError] = React.useState("");

  // cancel token
  //const [token, setToken] = React.useState(undefined);

  async function postRegister(startup) {
    setLoading(true);
    setError("");
    const response = await startupRegisterApi(common.currentUser.token, startup);
    setData((response && response.data) || []);
    setLoading(false);
  }

  async function fetchDataProfile(startupanme) {
    setLoading(true);
    setError("");
    const response = await startupProfileApi(common.currentUser.token, startupanme);
    setData((response && response.data) || []);
    setLoading(false);
  }

  async function fetchData() {
    setLoading(true);
    setError("");
    if(!query || query === ''){
      setError({error: {
        message: 'Startup Name is require',
      }});
      setLoading(false);
      return;
    }
    const response = await searchStartupApi(common.currentUser.token, query);
    setData((response && response.data) || []);
    setLoading(false);
  }

  async function fetchOurstartupData() {
    setLoading(true);
    setError("");
    const response = await ourstartupsApi();
    setData((response && response.data) || []);
    setLoading(false);
  }

  async function searchOurStartup({
    textSearch,
    industrySearch,
    lastFundingTypeSearch,
    keyTechnologySearch,
    startupStateSearch,
    fundraisingStatusSearch,
  }) {
    setLoading(true);
    setError('');
    const response = await searchOurStartupsApi({
      textSearch,
      industrySearch,
      lastFundingTypeSearch,
      keyTechnologySearch,
      startupStateSearch,
      fundraisingStatusSearch,
    });
    //console.log(response)
    setData((response && response.data) || []);

    setLoading(false);
  }

  const onSearchStartup = () => {
    //query && query.length > 0 && 
    setData([]);
    fetchData()
    .catch(error => { setError(error.response.data)});
  }

  const onGetStartup = (startupname) => {
    setData([]);
    fetchDataProfile(startupname)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  const onGetAll = () => {
    setData([]);
    fetchOurstartupData()
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  const onRegisterStartup = (startup) => {
    setData([]);
    postRegister(startup)
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  };

  const onSearchOurStartup = ({
    textSearch,
    industrySearch,
    lastFundingTypeSearch,
    keyTechnologySearch,
    startupStateSearch,
    fundraisingStatusSearch,
  }) => {
    searchOurStartup({
      textSearch,
      industrySearch,
      lastFundingTypeSearch,
      keyTechnologySearch,
      startupStateSearch,
      fundraisingStatusSearch,
    }).catch((error) => {
      console.log(error);
      setError(error.response?.data || error.response);
    });
  };
  
  return {data, query, setQuery, onGetAll, onGetStartup, onSearchStartup, onSearchOurStartup, onRegisterStartup, loading, error};
}

export default useStartup;