const cardBodyStyle = {
  cardBody: {
    padding: "0.9375rem 1.875rem",
    flex: "1 1 auto"
  },
  cardBodyNoPadding: {
    padding: "0.23435rem 0.46875rem",
    flex: "1 1 auto"
  }
};

export default cardBodyStyle;
