import React from "react";

import axios from 'axios';
/* eslint-disable react/prop-types */
import { Avatar, Button as MuiButton } from "@material-ui/core";
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";

import styled from "styled-components";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);


const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
width: 120px;
height: 120px;
margin: 0 auto 20px;
`;
// const AvatarUpload = () => {
//   const [image, _setImage] = useState(null);
//   const inputFileRef = createRef(null);

//   const BigAvatar = styled(Avatar)`
//   width: 120px;
//   height: 120px;
//   margin: 0 auto 20px;
//   ${({ $withBorder }) =>
//     $withBorder &&
//     `border: 1px solid ${grey[500]};
//      box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};`}
// `;

const AvatarUpload = (props) => {

  const [image, _setImage] = React.useState(null);
  const [cloudinaryImage, setCloudinaryImage] = React.useState(null);
  // const inputFileRef = createRef(null);
  // eslint-disable-next-line no-unused-vars
  const {setLoading, imagecloud, clearImage, displayOnly} = props;
  //console.log(props);
 
  // React.useEffect(() => {
  //   console.log('image: ', image);
  //   setImage("/static/img/avatars/default-profile.svg");
  //   if(!image) {
  //     setImage("/static/img/avatars/default-profile.svg");
  //   }
  // }, []);

  React.useEffect(() => {
    // console.log(imagecloud);
    if(imagecloud) {
      setImage(imagecloud.url);
      setCloudinaryImage(imagecloud);
    }
  }, [imagecloud]);

  const setImage = (newImage) => {

    _setImage(newImage);
    
  };

  
  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
   const handleClick = (event) => {
    if (image) {
      setLoading(true);
      event.preventDefault();
      setImage(null);
      // console.log('image: ', cloudinaryImage.public_id);
      // send remove image on cloud
      axios.post(`${process.env.REACT_APP_API}/removeimage`, {public_id: cloudinaryImage.public_id})
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        // console.log('removeimage: ', res);
        setCloudinaryImage(null);
        clearImage(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }
  };

  return (
    <CenteredContent>
      <BigAvatar
        //variant="square"
        $withBorder
        alt="Avatar"
        src={image || "/static/img/avatars/default-profile.svg"}
        imgProps={{
          style: {
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
            float: "right",
          },
        }}
      />

      {!displayOnly && <label htmlFor={`avatar-image-upload${props.id || ''}`}>
        <Button
          variant="contained"
          color="default"
          component="span"
          mb={2}
          onClick={!image ? props.handleClick : handleClick}
        >
          {image ? <DeleteIcon mr={1} /> : <UploadIcon mr={1} />}
          {image ? "Clear" : "Upload"}
        </Button>
      </label>}
      {/*<Typography variant="caption" display="block" gutterBottom>
        For best results, use an image of at least 128x
        128 pixels in .jpg format
      </Typography> */}
    </CenteredContent>
  );
};

export default AvatarUpload;
