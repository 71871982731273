/* eslint-disable react/jsx-key */
import React from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/icons https://material-ui.com/components/material-icons/
import { Settings, Home } from '@material-ui/icons';

// core components
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import Button from 'components/CustomButtons/Button.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardFooter from 'components/Card/CardFooter.js';

import styles from 'assets/jss/material-kit-react/components/headerLinksStyle.js';

import image from "assets/img/ana2.jpg";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function HeaderLinks() {
  const { common } = useSelector((state) => ({ ...state }));
  const classes = useStyles();
  const history = useHistory();

  const [classicModal, setClassicModal] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);

  React.useEffect(() => {
    !common.currentUser && setCurrentUser(false);
    common.currentUser && setCurrentUser(true);
  }, [common.currentUser]);

  const dialog = () => {
    return (
      <div className={classes.section} style={{
        backgroundImage: "url(" + image + ")",
        padding: "1rem",
        borderRadius: "36px",
      }}>
        <h4 className={classes.title}>YakStart insights & analysis</h4>
        <p className={classes.title}>Intelligently search and target the right opportunities with advanced search filters</p>
        <GridContainer>
  
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.cardBorderRadius36}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                
              </GridItem>
              <h4 className={classes.cardTitle}>
                For Investor
                <br />
                <Button round color="facebook" onClick={() => history.push(process.env.REACT_APP_INVESTOR_SIGNUP_PUSH_URL)}>
                  Sign up
                </Button>
              </h4>
              <CardFooter className={classes.justifyCenter}>
              <p className={classes.cardTitle}>Already have an account? </p>
              <Button simple color="facebook" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
                Login
              </Button>
              </CardFooter>
            </Card>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={6}>
            <Card className={classes.cardBorderRadius36}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                
              </GridItem>
              <h4 className={classes.cardTitle} onClick={() => history.push(process.env.REACT_APP_STARTUP_SIGNUP_PUSH_URL)}>
                For Startup
                <br />
                <Button round color="danger">
                Sign up
              </Button>
              </h4>
              <CardFooter className={classes.justifyCenter}>
              <p className={classes.cardTitle}>Already have an account? </p>
              <Button simple color="facebook" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
                Login
              </Button>
              </CardFooter>
            </Card>
          </GridItem>
  
        </GridContainer>
      </div>
    );
  };

  return (
    <List className={classes.list}>
      { // history && history.location.pathname !== process.env.REACT_APP_INVESTOR_SIGNUP_PUSH_URL &&
        <Dialog
        fullWidth
        maxWidth='lg'
        className={classes.modalBodyNopadding}
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setClassicModal(false)}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={() => setClassicModal(false)}
          >
            <Close className={classes.modalClose26} />
          </IconButton>
          <h4 className={classes.modalTitle}></h4>
        </DialogTitle>
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          {dialog()}
        </DialogContent>
      </Dialog>}
      <ListItem dense disableGutters selected={false} className={classes.listItem}>
        <Button onClick={() => history.push('/')} color='transparent' className={classes.navLink}>
          <Home className={classes.icons} /> Home
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button onClick={() => history.push('/startup-market')} color='transparent' className={classes.navLink}>
          Startup thailand market
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button onClick={() => history.push('/our-startups')} color='transparent' className={classes.navLink}>
          Our startups
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button onClick={() => history.push('/contactus')} color='transparent' className={classes.navLink}>
          Contact us
        </Button>
      </ListItem>
      {common.currentUser && common.currentUser.role === 'user' ?
        <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={'My profile'}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Settings}
          dropdownList={[
            <Link
              to={'/profile'}
              className={classes.dropdownLink}
            >
            {'My profile'}
            </Link>,
            <Link to={'/invite-friends'} className={classes.dropdownLink}>
              {'Invite friends'}
            </Link>,
            <Link to={'/logout'} className={classes.dropdownLink}>
              {'Logout'}
            </Link>,
          ]}
        />
      </ListItem> : undefined
      }
      {currentUser && common.currentUser.role === 'admin' ?
        <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={'Admin'}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Settings}
          dropdownList={[
            <Link
              to={'/admin'}
              className={classes.dropdownLink}
            >
            {'Admin'}
            </Link>,
            <Link to={'/logout'} className={classes.dropdownLink}>
              {'Logout'}
            </Link>,
          ]}
        />
      </ListItem> : undefined
      }
      {currentUser === false ?
        <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={'Signup/Login'}
          buttonProps={{
            className: classes.navLink,
            color: 'transparent',
          }}
          buttonIcon={Settings}
          dropdownList={[
            // <Link
            //   to={'#'}
            //   className={classes.dropdownLink}
            //   onClick={() => setClassicModal(true)}
            // >
            // {'Signup'}
            // </Link>,
            <Link to={'/login-page?p=signup'} className={classes.dropdownLink}>
              {'Signup'}
            </Link>,
            <Link to={'/login-page'} className={classes.dropdownLink}>
              {'Login'}
            </Link>,
          ]}
        />
      </ListItem> : undefined
      }
      
      {/*<ListItem className={classes.listItem}>
        <Button
          href='https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar'
          color='transparent'
          target='_blank'
          className={classes.navLink}
        >
          <CloudDownload className={classes.icons} /> Download
        </Button>
        </ListItem> */}
      {/*<ListItem className={classes.listItem}>
        <Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          id='instagram-twitter'
          title='Follow us on twitter'
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href='https://twitter.com/CreativeTim?ref=creativetim'
            target='_blank'
            color='transparent'
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-twitter'} />
          </Button>
        </Tooltip>
      </ListItem>*/}
      {/*<ListItem className={classes.listItem}>
        <Tooltip
          id='instagram-facebook'
          title='Follow us on facebook'
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color='transparent'
            href='https://www.facebook.com/CreativeTim?ref=creativetim'
            target='_blank'
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-facebook'} />
          </Button>
        </Tooltip>
    </ListItem>*/}
      {/*<ListItem className={classes.listItem}>
        <Tooltip
          id='instagram-tooltip'
          title='Follow us on instagram'
          placement={window.innerWidth > 959 ? 'top' : 'left'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color='transparent'
            href='https://www.instagram.com/CreativeTimOfficial?ref=creativetim'
            target='_blank'
            className={classes.navLink}
          >
            <i className={classes.socialIcons + ' fab fa-instagram'} />
          </Button>
        </Tooltip>
  </ListItem>*/}
    </List>
  );
}
