/* eslint-disable no-constant-condition */
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom';
// nodejs library that concatenates classes
// import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
// import IconButton from "@material-ui/core/IconButton";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Face from "@material-ui/icons/Face";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Email from '@material-ui/icons/Email';
import LockOutlined from '@material-ui/icons/LockOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
// import Card from 'components/Card/Card';
// import CardBody from 'components/Card/CardBody';
// import CardFooter from 'components/Card/CardFooter';
import CustomTabs from "components/CustomTabs/CustomTabs";
import CustomInput from 'components/CustomInput/CustomInput';
//import Spinner from 'components/Spinner/Spinnes';

// functions validate
import validateEmail from 'functions/validations/email';

import { authFirebase } from '../../../firebase';
import { authCurrentUserApi, authSignupApi } from 'api/auth';
import {REDIRECT, LOGGED_IN_USER} from 'constants/actionTypes';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/teamStyle.js';

// import banner03 from 'assets/img/Footer.jpg';
// import team0 from 'assets/img/faces/marc.jpg';
// import team1 from 'assets/img/faces/avatar.jpg';
// import team2 from 'assets/img/faces/christian.jpg';
// import team3 from 'assets/img/faces/kendall.jpg';
// import image from "assets/img/ana2.jpg";

const useStyles = makeStyles((theme) => ({
  ...styles,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmpassword: '',
};

export default function TeamSection() {
  
  const classes = useStyles();
  const history = useHistory();
  
  // const imageClasses = classNames(
  //   //classes.imgRaised,
  //   //classes.imgRoundedCircle,
  //   classes.imgFluid
  // );
  
  // const [classicModal, setClassicModal] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const { common, auth } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState(initialState);
  const [email, setEmail] = React.useState('');
  const [emailNotValid, setEmailNotValid] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [confirmpassword, setConfirmPassword] = React.useState('');
  const [confirmpasswordvalidate, setConfirmPasswordValidate] = React.useState(false);
  const [touch, setTouch] = React.useState(false);
  const [snackPack, setSnackPack] = React.useState([]);
  const [errorResponse, setErrorResponse] = React.useState('');
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    //console.log('snackPack: ', snackPack);
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  React.useEffect(() => {
    if(touch && !passNotValid() && (password === confirmpassword) ) {
      setConfirmPasswordValidate(true);
    } else {
      setConfirmPasswordValidate(false);
    }
  }, [password, confirmpassword]);

  const onRedirect = () =>
    dispatch({ type: REDIRECT });

  React.useEffect(() => {
    if (common.redirectTo) {
      //console.log('common.redirectTo: ', common.redirectTo);
      history.push(common.redirectTo);
      onRedirect();
    }
  },[common]);

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setEmailNotValid(!validateEmail(event.target.value));
    //console.log('emailValidate', emailValidate);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const passNotValid = () => {
    return touch && password.length < 6;
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setTouch(true);
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const clearFormSignup = () => {
    setState(initialState);
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setLoading(false);
  };

  const sendEmailVerification = (currentUser) => {

    currentUser.sendEmailVerification().then(function() {
      // Email sent.
      setSnackPack((prev) => [...prev, { message: `please check your email inbox!. [${email}] 
      ps. If you can not find your confirmation email in your normal inbox, 
      it is worth checking in your spam or junk mail section.`, key: new Date().getTime() }]);

      clearFormSignup();

    }).catch(function(error) {
      // An error happened.
      console.log(error);
      setErrorResponse(error);
      setSnackPack((prev) => [...prev, { message: `${(error && error.message) || 'found error' }`, key: new Date().getTime() }]);
      setLoading(false);
    });

  };

  const formSignup = () => {
    const handleSignup = (event) => {
      setLoading(true);
      event.preventDefault();

      authFirebase.createUserWithEmailAndPassword(email, password)
        // eslint-disable-next-line no-unused-vars
        .then((userCredential) => {
          // Signed in
          //const user = userCredential.user;
          // console.log(userCredential);
          const currentUser = authFirebase.currentUser;

          const user = {firstname: state.firstname, lastname: state.lastname, email: state.email, gid: common.gid};
          authSignupApi(user)
          // .then(res => {
          //   console.log(res);
          // })
          .catch(error => {
            console.log(error);
          });
          sendEmailVerification(currentUser);

        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(error, errorCode, errorMessage);
          const currentUser = authFirebase.currentUser;

          if (errorCode === 'auth/email-already-in-use') {
            sendEmailVerification(currentUser);
          } else {
            setErrorResponse(error);
            setSnackPack((prev) => [...prev, { message: `${(error && error.message) || 'found error' }`, key: new Date().getTime() }]);
          }
          
          setLoading(false);
        });

    };

    const formSignupNotValid = () => {
      if(state.firstname.length <= 0 || state.lastname.length <= 0 || state.email.length <= 0) {
        return true;
      }
      if(emailNotValid) {
        return true;
      }
      return touch ? !confirmpasswordvalidate : true;
    };

    const handleStateChange = (event) => {
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
    };

    const handleConfirmPassword = (event) => {
      setConfirmPassword(event.target.value);
      setTouch(true);
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
    };

    return (
      <div>
        
        <br />
        <CustomInput
          labelText='Name...'
          id='firstname'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'firstname',
            type: 'text',
            onChange: (event) => handleStateChange(event),
            value: state.firstname,
          }}
        />
        <CustomInput
          labelText='Last name...'
          id='lastname'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'lastname',
            type: 'text',
            onChange: (event) => handleStateChange(event),
            value: state.lastname,
          }}
        />
        <CustomInput
          labelText='Email...'
          id='email'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'email',
            type: 'email',
            error: emailNotValid,
            onChange: (event) => handleEmail(event),
            value: email,
            endAdornment: (
              <InputAdornment position='end'>
                <Email className={classes.inputIconsColor} />
              </InputAdornment>
            ),
          }}
        />
        <CustomInput
          labelText='Password'
          id='pass'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'password',
            type: 'password',
            error: passNotValid(),
            onChange: (event) => handlePassword(event),
            value: password,
            endAdornment: (
              <InputAdornment position='end'>
                <LockOutlined className={classes.inputIconsColor} />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
        />
        <CustomInput
          labelText='Confirm Password'
          id='confirmpassword'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            name: 'confirmpassword',
            type: 'password',
            error: touch ? !confirmpasswordvalidate : false,
            onChange: (event) => handleConfirmPassword(event),
            value: confirmpassword,
            endAdornment: confirmpasswordvalidate ? (
              <InputAdornment position='end'>
                <CheckCircleOutlineIcon className={classes.inputIconsColor} />
              </InputAdornment>
            ) : undefined,
            autoComplete: 'off',
          }}
        />
        <Button
          disabled={formSignupNotValid()}
          onClick={handleSignup}
          block
          color='facebook'
          size='lg'
        >
          Sign up
        </Button>
      </div>
    );
  };

  const formLoginNotValid = () => {
    // eslint-disable-next-line no-debugger
    //debugger;
    if(emailNotValid) {
      return true;
    }

    if(!touch) {
      return true;
    }

    if(passNotValid()){
      return true;
    }

    //console.log(auth.inProgress);
    //return auth.inProgress ? true : false;
    return false;
  };

  const formLogin = () => {
    const handleLogin = async (event) => {
      setLoading(true);
      event.preventDefault();

      try{
        const result = await authFirebase.signInWithEmailAndPassword(email, password);
        // console.log('signInWithEmailAndPassword: ', result);
        const { user } = result;
        const idTokenResult = await user.getIdTokenResult();
        authCurrentUserApi({ authtoken: idTokenResult.token })
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          // eslint-disable-next-line no-debugger
          //console.log(response);debugger;
          dispatch({
            type: LOGGED_IN_USER,
            payload: {
              user: {
                name: user.email,
                email: user.email,
                role: response.data.role,
                token: idTokenResult.token,
                isInvestor: response.data.isInvestor,
                isStartup: response.data.isStartup,
              },
              redirect: (response.data.role === 'admin') ? '/admin' : '/profile',
            },
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-debugger
          //debugger;
          console.log(error);
          setErrorResponse(error);
          if(error && error.response.data.error === 'email verification not complete') {
            const currentUser = authFirebase.currentUser;
            sendEmailVerification(currentUser);
            setLoading(false);
            return;
          }
          setSnackPack((prev) => [...prev, { message: error.message, key: new Date().getTime() }]);
          setLoading(false);
        });
      } catch(error){
        console.log(error);
        setErrorResponse(error);
        setSnackPack((prev) => [...prev, { message: error.message, key: new Date().getTime() }]);
        setLoading(false);
      }

      

    };

    const passNotValid = () => {
      return touch && password.length < 6;
    };

    return (
      <div>
        <br />
        <CustomInput
          labelText='Email...'
          id='email'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'email',
            error: emailNotValid,
            onChange: (event) => handleEmail(event),
            value: email,
            endAdornment: (
              <InputAdornment position='end'>
                <Email className={classes.inputIconsColor} />
              </InputAdornment>
            ),
          }}
        />
        <CustomInput
          labelText='Password'
          id='pass'
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            type: 'password',
            error: passNotValid(),
            onChange: (event) => handlePassword(event),
            value: password,
            endAdornment: (
              <InputAdornment position='end'>
                <LockOutlined className={classes.inputIconsColor} />
              </InputAdornment>
            ),
            autoComplete: 'off',
          }}
        />
        <Button
          disabled={formLoginNotValid()}
          onClick={handleLogin}
          block
          color='facebook'
          size='lg'
        >
          Login
        </Button>
      </div>
    );
  };

  // const dialog = () => {
  //   return (
  //     <div className={classes.section} style={{
  //       backgroundImage: "url(" + image + ")",
  //       padding: "1rem",
  //       borderRadius: "36px",
  //     }}>
  //       <h4 className={classes.title}>YakStart insights & analysis</h4>
  //       <p className={classes.title}>Intelligently search and target the right opportunities with advanced search filters</p>
  //       <GridContainer>
  
  //         <GridItem xs={12} sm={12} md={6}>
  //           <Card className={classes.cardBorderRadius36}>
  //             <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                
  //             </GridItem>
  //             <h4 className={classes.cardTitle}>
  //               For Investor
  //               <br />
  //               <Button round color="facebook" onClick={() => {
  //                 return history.push(process.env.REACT_APP_INVESTOR_SIGNUP_PUSH_URL);
  //               }}>
  //                 Sign up
  //               </Button>
  //             </h4>
  //             <CardFooter className={classes.justifyCenter}>
  //             <p className={classes.cardTitle}>Already have an account? </p>
  //             <Button simple color="facebook" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
  //               Login
  //             </Button>
  //             </CardFooter>
  //           </Card>
  //         </GridItem>
          
  //         <GridItem xs={12} sm={12} md={6}>
  //           <Card className={classes.cardBorderRadius36}>
  //             <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                
  //             </GridItem>
  //             <h4 className={classes.cardTitle}>
  //               For Startup
  //               <br />
  //               <Button round color="danger" onClick={() => history.push(process.env.REACT_APP_STARTUP_SIGNUP_PUSH_URL)}>
  //                 Sign up
  //               </Button>
  //             </h4>
  //             <CardFooter className={classes.justifyCenter}>
  //             <p className={classes.cardTitle}>Already have an account? </p>
  //             <Button simple color="facebook" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
  //               Login
  //             </Button>
  //             </CardFooter>
  //           </Card>
  //         </GridItem>
  
  //       </GridContainer>
  //     </div>
  //   );
  // };

  // eslint-disable-next-line no-unused-vars
  const handleClose = (event, reason) => {
    //console.log(event, reason);
    // if (reason === 'clickaway') {
    //   return;
    // }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <div className={classes.section}>
      
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <div>
        
        
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          //autoHideDuration={6000}
          onClose={handleClose}
          onExited={handleExited}
          message={messageInfo ? messageInfo.message : undefined}
        >
          <SnackbarContent
            style={{
              backgroundColor: errorResponse ? 'red' : 'green',
              width: '100%',
            }}
            message={messageInfo ? messageInfo.message : undefined}
          />
        </Snackbar>
        {/*<GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12}>
            <img src={banner03} alt='...' className={imageClasses} />
          </GridItem>}
          </GridContainer>*/}
        <br/>
        <GridContainer justify='center' alignItems='center'>
          <GridItem xs={12} sm={12} md={8}>
            <CustomTabs
              headerColor='primary'
              variant='fullWidth'
              tabs={[
                {
                  tabName: 'Signup',
                  tabIcon: ContactMailIcon,
                  tabContent: formSignup(),
                },
                {
                  tabName: 'Login',
                  tabIcon: Face,
                  tabContent: formLogin(),
                },
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );

  // return (
  //   <div className={classes.section}>
  //     <h2 className={classes.title}>Our Partner</h2>
  //     <Backdrop className={classes.backdrop} open={loading}>
  //       <CircularProgress color='inherit' />
  //     </Backdrop>
  //     <div>
  //       <GridContainer>
  //         <GridItem xs={12} sm={12} md={3}>
  //           <Card plain>
  //             <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
  //               <img src={team0} alt='...' className={imageClasses} />
  //             </GridItem>
  //             <h4 className={classes.cardTitle}>
  //               Marc Obriton
  //               <br />
  //               <Button
  //                 color='facebook'
  //                 simple
  //                 onClick={() => setClassicModal(true)}
  //               >
  //                 <LibraryBooks className={classes.icon} />
  //                 Get information
  //               </Button>
  //             </h4>
  //             <CardBody>
  //               <p className={classes.description}></p>
  //             </CardBody>
  //             <CardFooter className={classes.justifyCenter}></CardFooter>
  //           </Card>
  //         </GridItem>
  //         <GridItem xs={12} sm={12} md={3}>
  //           <Card plain>
  //             <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
  //               <img src={team1} alt='...' className={imageClasses} />
  //             </GridItem>
  //             <h4 className={classes.cardTitle}>
  //               Gigi Hadid
  //               <br />
  //               <Button
  //                 color='facebook'
  //                 simple
  //                 onClick={() => setClassicModal(true)}
  //               >
  //                 <LibraryBooks className={classes.icon} />
  //                 Get information
  //               </Button>
  //             </h4>
  //             <CardBody>
  //               <p className={classes.description}></p>
  //             </CardBody>
  //             <CardFooter className={classes.justifyCenter}></CardFooter>
  //           </Card>
  //         </GridItem>
  //         <GridItem xs={12} sm={12} md={3}>
  //           <Card plain>
  //             <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
  //               <img src={team2} alt='...' className={imageClasses} />
  //             </GridItem>
  //             <h4 className={classes.cardTitle}>
  //               Christian Louboutin
  //               <br />
  //               <Button
  //                 color='facebook'
  //                 simple
  //                 onClick={() => setClassicModal(true)}
  //               >
  //                 <LibraryBooks className={classes.icon} />
  //                 Get information
  //               </Button>
  //             </h4>
  //             <CardBody>
  //               <p className={classes.description}></p>
  //             </CardBody>
  //             <CardFooter className={classes.justifyCenter}></CardFooter>
  //           </Card>
  //         </GridItem>
  //         <GridItem xs={12} sm={12} md={3}>
  //           <Card plain>
  //             <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
  //               <img src={team3} alt='...' className={imageClasses} />
  //             </GridItem>
  //             <h4 className={classes.cardTitle}>
  //               Kendall Jenner
  //               <br />
  //               <Button
  //                 color='facebook'
  //                 simple
  //                 onClick={() => setClassicModal(true)}
  //               >
  //                 <LibraryBooks className={classes.icon} />
  //                 Get information
  //               </Button>
  //             </h4>
  //             <CardBody>
  //               <p className={classes.description}></p>
  //             </CardBody>
  //             <CardFooter className={classes.justifyCenter}></CardFooter>
  //           </Card>
  //           <Dialog
  //             fullWidth={true}
  //             maxWidth='lg'
  //             className={classes.modalBodyNopadding}
  //             classes={{
  //               root: classes.center,
  //               paper: classes.modal,
  //             }}
  //             open={classicModal}
  //             TransitionComponent={Transition}
  //             keepMounted
  //             onClose={() => setClassicModal(false)}
  //             aria-labelledby='classic-modal-slide-title'
  //             aria-describedby='classic-modal-slide-description'
  //           >
  //             <DialogTitle
  //               id='classic-modal-slide-title'
  //               disableTypography
  //               className={classes.modalHeader}
  //             >
  //               <IconButton
  //                 className={classes.modalCloseButton}
  //                 key='close'
  //                 aria-label='Close'
  //                 color='inherit'
  //                 onClick={() => setClassicModal(false)}
  //               >
  //                 <Close className={classes.modalClose26} />
  //               </IconButton>
  //               <h4 className={classes.modalTitle}></h4>
  //             </DialogTitle>
  //             <DialogContent
  //               id='classic-modal-slide-description'
  //               className={classes.modalBody}
  //             >
  //               {dialog()}
  //             </DialogContent>
  //           </Dialog>
  //         </GridItem>
  //       </GridContainer>

  //       <div
  //         style={{
  //           backgroundImage: 'url(' + image + ')',
  //           padding: '1rem',
  //           borderRadius: '36px',
  //         }}
  //       >
  //         <h2 className={classes.title}>YakStart insights & analysis</h2>
  //         <h3 className={classes.title}>
  //           Intelligently search and target the right opportunities with
  //           advanced search filters
  //         </h3>
  //       </div>
  //       <br />
  //       <Snackbar
  //         key={messageInfo ? messageInfo.key : undefined}
  //         anchorOrigin={{
  //           vertical: 'bottom',
  //           horizontal: 'center',
  //         }}
  //         open={open}
  //         //autoHideDuration={6000}
  //         onClose={handleClose}
  //         onExited={handleExited}
  //         message={messageInfo ? messageInfo.message : undefined}
  //       >
  //         <SnackbarContent
  //           style={{
  //             backgroundColor: errorResponse ? 'red' : 'green',
  //             width: '100%',
  //           }}
  //           message={messageInfo ? messageInfo.message : undefined}
  //         />
  //       </Snackbar>
  //       <GridContainer justify='center' alignItems='center'>
  //         <GridItem xs={12} sm={12} md={8}>
  //           <CustomTabs
  //             headerColor='primary'
  //             variant='fullWidth'
  //             tabs={[
  //               {
  //                 tabName: 'Signup',
  //                 tabIcon: ContactMailIcon,
  //                 tabContent: formSignup(),
  //               },
  //               {
  //                 tabName: 'Login',
  //                 tabIcon: Face,
  //                 tabContent: formLogin(),
  //               },
  //             ]}
  //           />
  //         </GridItem>
  //       </GridContainer>
  //     </div>
  //   </div>
  // );
}
