/* eslint-disable react/prop-types */
import React from 'react';

// import {useHistory} from 'react-router-dom';
import {useDispatch } from 'react-redux';
import {useParams} from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

// import Devider from '@material-ui/core/Divider';

import AdminNav from 'components/AdminNav/AdminNav';
import Footer from "components/Footer/Footer";

import GeneralInfoSection from 'views/AdminPage/manage/Sections/GeneralInfo';
import BusinessModelSection from 'views/AdminPage/manage/Sections/BusinessModel';
import FinancialSection from 'views/AdminPage/manage/Sections/Financial';
import TeamSection from 'views/AdminPage/manage/Sections/Team';

import { container } from "assets/jss/material-kit-react.js";
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

import useStartup from 'hooks/useStartup';
import useCurrentUser from 'hooks/useCurrentUser';

import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  ... container,
  ... styles,  
  container: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  root: {
    flexShrink: 0,
    //marginLeft: theme.spacing(0),
    padding: '20px',
    //backgroundColor: '#fff',
    //width: '90%',
    //margin: '10px',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: '20px',
    paddingLeft: '30px',
  },
  gridContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#ddd',
  },
}));

// eslint-disable-next-line no-unused-vars
const StartupDetail = (props) => {
  // const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  let { slug } = useParams();
  const {data, error, loading, onGetStartup} = useStartup();
  const {error: errorCurrentUser} = useCurrentUser();

  React.useEffect(() => {
    dispatch({type: APP_LOAD});
    onGetStartup(slug);
  }, []);

  React.useEffect(() => {
    loading && dispatch({type: APP_LOAD});
    !loading && dispatch({type: APP_LOADED});
  }, [loading]);

  React.useEffect(() => {
    error && console.log('error', error);
    error && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: error.error.message}});

    errorCurrentUser && console.log('errorCurrentUser', errorCurrentUser);
    errorCurrentUser && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorCurrentUser.error.message}});

  }, [error, errorCurrentUser]);

  // React.useEffect(() => {
  //   console.log('data: ', data);
  // }, [data]);

  return (
    <React.Fragment>
      
      <div className={classes.container}>
        <AdminNav />
        <div className={classes.root}>
          <Typography variant="h6" gutterBottom>
            general information
          </Typography>

          <br/>
          <GeneralInfoSection data={data} />
          <br/>
          <br/>
  
          <Typography variant="h6" gutterBottom>
            business model
          </Typography>

          <br/>
          <BusinessModelSection data={data} />
          <br/>
          <br/>
  
          <Typography variant="h6" gutterBottom>
            Financial
          </Typography>

          <br/>
          <FinancialSection data={data} />
          <br/>
          <br/>
  
          <Typography variant="h6" gutterBottom>
            Team
          </Typography>

          <br/>
          <TeamSection data={data} />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default StartupDetail;