// import * as firebase from "firebase/app"; // old way, wont work anymore
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// var firebaseConfig = {
//     apiKey: "AIzaSyATqVNDFMiXbImwgRCIt3_6iaExP0IQyCI",
//     authDomain: "yakstartdc.firebaseapp.com",
//     projectId: "yakstartdc",
//     storageBucket: "yakstartdc.appspot.com",
//     messagingSenderId: "697722482163",
//     appId: "1:697722482163:web:1c04eae6297ff4e3f5b686",
//     measurementId: "G-NV6K9SVL0B"
//   };

var firebaseConfig = {
  apiKey: "AIzaSyA9zoUer4o-uC29xLteZU6SBFtSVpOt8HY",
  authDomain: "yakstartdc-test2021.firebaseapp.com",
  projectId: "yakstartdc-test2021",
  storageBucket: "yakstartdc-test2021.appspot.com",
  messagingSenderId: "559800386772",
  appId: "1:559800386772:web:52f466078df382b1762741"
};  

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const authFirebase = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const firebaseStorage = firebase.storage();

export {authFirebase, googleAuthProvider, firebaseStorage, firebase as default};