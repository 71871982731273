import React from 'react';
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'red',
  },
}));

export default function Spinner(props) {
  // eslint-disable-next-line react/prop-types
  const {show} = props;
  const classes = useStyles();
  const { className } = props;
  const spinnerClasses = classNames({
    [className]: className !== undefined
  });

  return (
    <div className={spinnerClasses}>
      {show && <Backdrop className={classes.backdrop} open={show}>
        <CircularProgress color="inherit" />
      </Backdrop>}
    </div>
  );
}


Spinner.propTypes = {
  className: PropTypes.string,
  //show: PropTypes.func,
};