/* eslint-disable react/prop-types */
import React from 'react';

import 'assets/scss/businessModelTableStyle.scss';

import CustomHeaderTable from 'components/CustomTable/CustomHeaderTable';
import CustomBodyTable from 'components/CustomTable/CustomBodyTable';

// let tracksData = [
//   {
//     keyPartners: 'The strategic relationships your business creates with other companies or people.',
//     keyActivities: 'Activities or tasks that are integral to operating your company.',
//     keyResource: 'Assets that are required to operate and deliver your company’s value proposition.',
//     customerSegments: 'The different groups of customers that your company interacts with.',
//     customerRelationships: 'The type of interactions your company has with its customers and the level of support it gives.',
//     channels: 'Different methods that your company uses to deliver its products and value proposition to customers.',
//     valueProposition: 'The fundamental need that your company is trying to fulfill for its customers. Why your company exists.',
//     revenueStreams: 'Your company’s sources of cash flows.',
//     costStructure: 'How a company spends money on operations. The key costs and level of cost-focus for your company.',
//   },

// ];

const BusinessModelTable = (props) => {
  const {data} = props;

  const mapBusinessModel = () => {
    return [
      {
        keyPartners: data.keyPartners || '',
        keyActivities: data.keyActivities || '',
        keyResource: data.keyResource || '',
        customerSegments: data.customerSegments || '',
        customerRelationships: data.customerRelationships || '',
        channels: data.channels || '',
        valueProposition: data.valueProposition || '',
        revenueStreams: data.revenueStreams || '',
        costStructure: data.costStructure || '',
        problemSolved: data.problemSolved || '',
        solutionConcept: data.solutionConcept || '',
        unfairAdvantage: data.unfairAdvantage || '',
      },
    
    ];
  };

  return (
    <React.Fragment>
      <CustomHeaderTable title="" />
      <CustomBodyTable id="2" tableData={mapBusinessModel()} headingColumns={['Key Partners', 'Key Activities', 'Key Resource', 'Customer Segments', 'Customer Relationships', 'Channels', 'Value proposition', 'Revenue Streams', 'Cost Structure', 'Problem to be solved', 'Solution concept', 'Unfair Advantage']} title=" " breakOn="large"/>
      </React.Fragment>
  );
};

export default BusinessModelTable;