import React from 'react';

import guestId from 'functions/guestId';

import { authFirebase } from '../firebase';
import agent from 'agent';
import { authCurrentUserApi } from 'api/auth';

function useCurrentUser() {

  const [user, setUser] = React.useState();
  const [gid, setGid] = React.useState();
  const [error, setError] = React.useState();


  const getCurrentUser = (user, token) => {
    authCurrentUserApi({ authtoken: token })
      .then((response) => {
        // eslint-disable-next-line no-debugger
        //debugger;
        //console.log('authCurrentUserApi:', response);

        const gid = window.localStorage.getItem('gid');
        if (!gid) {
          window.localStorage.setItem('gid', response.data.gid || guestId());
        } else if (gid && gid !== response.data.gid) {
          console.log('replace gid');
          window.localStorage.setItem('gid', response.data.gid || guestId());
        }

        setUser({
          user: {
            name: user.name || user.email,
            email: user.email,
            role: response.data.role,
            token: token,
            isInvestor: response.data.isInvestor,
            isStartup: response.data.isStartup,
          },
        });

        
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data);
      });
  };

  React.useEffect(() => {
    let isMounted = true; 
    // for all new customer in the first time
    const gid = window.localStorage.getItem('gid');
    if (!gid) {
      window.localStorage.setItem('gid', guestId());
    } else if (gid) {
      setGid(gid);
    }

    // to check firebase auth state
    try {
      const unsubscribe = authFirebase.onAuthStateChanged(async (user) => {
        //console.log('onAuthStateChanged');
        // eslint-disable-next-line no-debugger
        //debugger;
        if (isMounted && user) {
          const idTokenResult = await user.getIdTokenResult();
          const jwtjson = window.localStorage.getItem('jwt');
          if (jwtjson) {
            //const token = JSON.parse(jwtjson);
            agent.setToken(idTokenResult.token); // set new token
            // eslint-disable-next-line no-debugger
            //debugger;console.log(token.r, token.t);
            getCurrentUser(user, idTokenResult.token);
          }
        }

        // cleanup
        return () => (unsubscribe());
      })
    } catch(error) {
      console.log(error); 
      isMounted = false;
    }

    
    return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
  }, []);

  return {user, gid, error};
}

export default useCurrentUser;
