/* eslint-disable react/prop-types */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { container } from "assets/jss/material-kit-react.js";
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  ... container,
  ... styles,  
  container: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  root: {
    flexShrink: 0,
    //marginLeft: theme.spacing(0),
    padding: '20px',
    //backgroundColor: '#fff',
    //width: '90%',
    //margin: '10px',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    paddingRight: '20px',
    paddingLeft: '30px',
  },
  gridContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#ddd',
  },
}));

const BusinessModelSection = (props) => {
  const {data} = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12}>
          <TextField
            id="keyPartners"
            name="keyPartners"
            label={`Key Partners`}
            fullWidth
            multiline
            value={data.keyPartners || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="keyActivities"
            name="keyActivities"
            label={`Key Activities`}
            fullWidth
            multiline
            value={data.keyActivities || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="keyResource"
            name="keyResource"
            label={`Key Resource`}
            fullWidth
            multiline
            value={data.keyResource || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="customerSegments"
            name="customerSegments"
            label={`Customer Segments`}
            fullWidth
            multiline
            value={data.customerSegments || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="customerRelationships"
            name="customerRelationships"
            label={`Customer Relationships`}
            fullWidth
            multiline
            value={data.customerRelationships || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="channels"
            name="channels"
            label={`Channels`}
            fullWidth
            multiline
            value={data.channels || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="valueProposition"
            name="valueProposition"
            label={`Value proposition`}
            fullWidth
            multiline
            value={data.valueProposition || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="revenueStreams"
            name="revenueStreams"
            label={`Revenue Streams`}
            fullWidth
            multiline
            value={data.revenueStreams || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="costStructure"
            name="costStructure"
            label={`Cost Structure`}
            fullWidth
            multiline
            value={data.costStructure || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="problemSolved"
            name="problemSolved"
            label={`Problem to be solved`}
            fullWidth
            multiline
            value={data.problemSolved || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="solutionConcept"
            name="solutionConcept"
            label={`Solution concept`}
            fullWidth
            multiline
            value={data.solutionConcept || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="unfairAdvantage"
            name="unfairAdvantage"
            label={`Unfair Advantage`}
            fullWidth
            multiline
            value={data.unfairAdvantage || '...'}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Test"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BusinessModelSection;