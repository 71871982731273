/* eslint-disable no-debugger */
import React from 'react';
import {getIndustryTypesEnum} from 'api/enums/industryType';

function useIndustrytypeEnum() {
  // loading
  const [loading, setLoading] = React.useState(false);
  // error state
  const [error, setError] = React.useState("");
  // result
  const [enumIndustrytype, setEnumData] = React.useState([]);

  async function fetchData() {
    setLoading(true);
    setError("");
    const response = await getIndustryTypesEnum(); 

    if(response && response.data) {
      // setEnumData(oldArray => [...oldArray, response.data]);
      setEnumData(response.data);
    }

    setLoading(false);
  }

  const onGetIndustrytypeEnumAll = () => {
    setEnumData([]);
    fetchData()
    .catch(error => { console.log(error); setError(error.response?.data || error.response);});
  }

  return {enumIndustrytype, onGetIndustrytypeEnumAll, loading, error};
}

export default useIndustrytypeEnum;