import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableFooter from '@material-ui/core/TableFooter';
// import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AdminNav from 'components/AdminNav/AdminNav';
import CustomInput from 'components/CustomInput/CustomInput';

import { container } from "assets/jss/material-kit-react.js";
import { styles } from 'assets/jss/material-kit-react/views/adminPageStyle';

import useAdmin from 'hooks/useAdmin';

import {APP_LOAD, APP_LOADED} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  ... container,
  ... styles,
  root: {
    flexShrink: 0,
    //marginLeft: theme.spacing(0),
    padding: '20px',
  },
  icons: {
    width: "17px",
    height: "17px",
    color: "#FFFFFF"
  },
  input: {
    width: '100%',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

// eslint-disable-next-line no-unused-vars
const ManageStartups = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line no-unused-vars
  const [currentItem, setCurrentItem] = React.useState(null);

  const {data, loading: loadingAdminHook, error: errorAdminHook, onGetStartups} = useAdmin();

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  React.useMemo(() => {
    // console.log('Memo: ', data);

    onGetStartups('*');
  }, []);

  // React.useEffect(() => {
  //   console.log('Effect data: ', data);
  //   console.log('emptyRows:', emptyRows);
  // }, [data]);

  React.useEffect(() => {
    // console.log('loadingAdminHook: ',loadingAdminHook);
    loadingAdminHook && dispatch({type: APP_LOAD});
    !loadingAdminHook && dispatch({type: APP_LOADED});
  }, [loadingAdminHook]);

  React.useEffect(() => {
    errorAdminHook && console.log('errorAdminHook: ',errorAdminHook);
    errorAdminHook && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorAdminHook.error.message}});
  }, [errorAdminHook]);

  // eslint-disable-next-line no-unused-vars
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCellClick = (e) => {
    //console.log(e.target.textContent);
    const found = data.find(element => element.startupName === e.target.textContent);
    // console.log(found);
    history.push(`/admin/startupdetail/${found.slug}`);
    setCurrentItem(found);
  }

  const handleEnterSearch = (event) => {
    event.preventDefault();
    
    if(event.target.value.trim() === '') {
      onGetStartups('*');
      return;
    }

    onGetStartups(event.target.value);
  };

  
  return (
    <div className={classes.container}>
      <AdminNav />
      <div>
        <Grid container className={classes.root} spacing={0}>
          <Grid item xs={12}>
            
              <Grid
              container
              justify='center'
              alignItems='center'
              direction='row'
              >
                <Grid xs={12} sm={12} md={12} item>
                  <CustomInput
                    labelText='Search...'
                    id='search'
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      name: 'search',
                      type: 'search',
                      classes: {input: classes.input},
                      //onChange: (event) => handleStateChange(event),
                      onKeyUp: (event) => event.key === "Enter" ? handleEnterSearch(event) : null,
                      //value: state.firstname,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
              container
              justify='center'
              alignItems='center'
              direction='row'
              >
                <Grid xs={12} sm={12} md={12} item>
                <TableContainer  component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label='custom pagination table'
                  >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Name</StyledTableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row) => (
                    <TableRow 
                      key={row.startupName}
                      hover
                    >
                      <TableCell
                        //style={{ width: 260 }}
                        component='th'
                        scope='row'
                        onClick={handleCellClick} 
                      >
                        {row.startupName}
                      </TableCell>
                      
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * 1 }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  </TableBody>
                  </Table>
                </TableContainer>
                </Grid>
              </Grid>

          </Grid>

        </Grid>
      </div>
    </div>
  );
};

export default ManageStartups;