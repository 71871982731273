import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { useSelector } from 'react-redux';
import {useDispatch } from 'react-redux';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import StartupListSection from 'views/StartupMarket/Sections/StartupListSection';
import StartupListMobile from 'views/StartupMarket/Sections/StartupListMobile';

// import useStartupMarket from 'hooks/useStartupMarket';
import useCurrentUser from 'hooks/useCurrentUser';

import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

import styles from "assets/jss/material-kit-react/views/startupMarket.js";

const useStyles = makeStyles(styles);

export default function StartupMarket(props) {
  const dispatch = useDispatch();
  //const {common} = useSelector((state) => ({...state}));
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const {error: errorCurrentUser} = useCurrentUser;
  //const {onGetAll, data, error: errorStartupMarket} = useStartupMarket();
  const classes = useStyles();

  const { ...rest } = props;

  // React.useEffect(() => {
  //   common.currentUser && common.currentUser.token && onGetAll();
  // }, [common.currentUser]);

  React.useEffect(() => {
    errorCurrentUser && console.log('errorCurrentUser', errorCurrentUser)
    errorCurrentUser && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error current user', message: errorCurrentUser.error.message}});
  }, [errorCurrentUser]);

  // React.useEffect(() => {
  //   console.log('errorStartupMarket', errorStartupMarket)
  //   errorStartupMarket && dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error startup market', message: errorStartupMarket.error.message}});
  // }, [errorStartupMarket]);
  
  return (
    <div>
      <Header
        absolute
        //color='transparent'
        brand='YAK START'
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      {/*<Header
        brand="YAK START"
        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />*/}
      

      <div className={classNames(classes.main, classes.mainRaised)}>
        {matches ? <StartupListMobile /> : <StartupListSection />}
      </div>
      <Footer />
    </div>
  );
}
