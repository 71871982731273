/* eslint-disable no-debugger */
/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import {useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Datetime from "react-datetime";
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Delete from "@material-ui/icons/Delete";
// core components
// import AvatarUpload from 'components/AvatarUpload/AvatarUpload';
import AvatarUpload2 from 'components/AvatarUpload2/AvatarUpload2';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import Snackbar from 'components/CustomSnackbar/Snackbar';

import styles from 'assets/jss/material-kit-react/views/startupSections/generalInformaton';

import {SAVE_DRAF_SIGNUP} from 'constants/actionTypes';
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';
import { STATUS_MODAL_SHOW } from 'constants/actionTypes';
import validateEmail from 'functions/validations/email';
import {getIndustryTypesEnum} from 'api/enums/industryType';
import {getKeyTechnologiesEnum} from 'api/enums/keyTechnology';
import {startupRegisterApi} from 'api/startup';
import {firebaseStorage} from '../../../firebase';
import {countries} from 'functions/country';
import CropImageDialog from 'components/CropImageDialog/CropImageDialog';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';

const useStyles = makeStyles(styles);

const GeneralInformaton = (props) => {
  const {common, enums, auth} = useSelector((state) => ({...state}));
  const inputFileRef = React.createRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  let initialState = {
    startupLogo: '',
    startupLogo2: [null],
    startupName: '',
    contactName: '',
    foundedDate: '',
    numberOfEmployee: 0,
    email: '',
    emailValidate: true,
    tel: '',
    startupIndustry: '',
    startupLastFundingType: '',
    keyTechnology: [],
    operatingCountry: '',
    businessIntroPdf: '',
    businessIntroPdf2: [],
    aboutus: '',
    linkOfWebsite: '',
    linkOfFacebook: '',
  };

  const [state, setState] = React.useState(initialState);
  const [errors, setErrors] = React.useState([]);
  const [industryTypeEnum, setIndsutryTypeEnum] = React.useState([]);
  const [keyTechnologyEnum, setKeyTechnologyEnum] = React.useState([]);
  
  const [image, setImage] = React.useState(null);
  const [pdf, _setPdf] = React.useState(null);

  const [keyTechnology, setKeyTechnology] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);

  React.useEffect(() => {
    console.log('isNewProfile', props.isNewProfile);
  }, [props.isNewProfile]);

  React.useEffect(() => {
    props.setLoading(true);
    window.scrollTo(0, 0);

    let isMounted = true; // note this flag denote mount status

    if(!enums || !enums.industryTypes){
      getIndustryTypesEnum()
        .then((resp) => {
          dispatch({
            type: 'SET_INDUSTRY_TYPE_ENUM',
            payload: { industryTypes: resp.data },
          });

          if (isMounted) setIndsutryTypeEnum(resp.data);
        })
        .catch((error) => {console.log(error); return () => { isMounted = false };}); 
    } else {
      setIndsutryTypeEnum(enums.industryTypes);
    }
    if(!enums || !enums.keyTechnologys){
      getKeyTechnologiesEnum()
        .then((resp) => {
          dispatch({
            type: 'SET_KEY_TECHNOLOGY_ENUM',
            payload: { keyTechnologys: resp.data },
          });

          if (isMounted) setKeyTechnologyEnum(resp.data);
        })
        .catch((error) => {console.log(error); return () => { isMounted = false };}); 
    } else {
      setKeyTechnologyEnum(enums.keyTechnologys);
    }

    props.setLoading(false);
    return () => { isMounted = false }; // use effect cleanup to set flag false, if unmounted
    
  }, []);



  React.useEffect(() => {
    errors && errors.length > 0 && console.log(errors);
  }, [errors]);

  React.useEffect(() => {
    props.setLoading(true);

    if (auth.drafSignup && auth.drafSignup.startupName) {
      const {
        startupName,
        email,
        tel,
        startupIndustry,
        startupLastFundingType,
        foundedDate,
        numberOfEmployee,
        startupLogo2,
        businessIntroPdf2,
        contactName,
        operatingCountry,
        aboutus,
        linkOfWebsite,
        linkOfFacebook,
      } = auth.drafSignup;

      // console.log('auth.drafSignup:', auth.drafSignup);

      setKeyTechnology(auth.drafSignup.keyTechnology);
      const dateString = (foundedDate) ? moment.unix(foundedDate).format('DD/MM/YYYY') : '';

      initialState = {
        startupName: startupName,
        contactName: contactName,
        email: email,
        foundedDate: dateString,
        numberOfEmployee: numberOfEmployee || '',
        emailValidate: validateEmail(email),
        tel: tel,
        startupIndustry: startupIndustry,
        startupLastFundingType: startupLastFundingType,
        //keyTechnology: keyTechnology,
        operatingCountry: operatingCountry,
        startupLogo2,
        businessIntroPdf2,
        aboutus: aboutus || '',
        linkOfWebsite: linkOfWebsite,
        linkOfFacebook: linkOfFacebook,
      }

      if (startupLogo2 && startupLogo2.length > 0) {
        setImage(startupLogo2[0]);
        initialState = {... initialState, startupLogo2: startupLogo2,};
      }

      if(businessIntroPdf2 && businessIntroPdf2.length > 0) {
        setPdf(businessIntroPdf2[0]);
        initialState = {... initialState, businessIntroPdf2: businessIntroPdf2,};
      }

      props.validToNext(true);
    }
    
    setState(initialState);
    props.setLoading(false);

  }, [auth]);

  React.useEffect(() => {
    //console.log('image: ', image);
    props.setLoading(false);

    //console.log('keyTechnology: ', keyTechnology);
  }, [image, keyTechnology]);

  const handleStateChange = (event) => {
    setErrors([]);
    const name = event.target.name;

    if(auth && auth.drafSignup._id && name === 'startupName') {
      return;
    }
    //console.log(name, event.target.vale);
    setState({
      ...state,
      [name]: event.target.value,
    }); 
  };

  const handleChange = (event) => {
    setKeyTechnology(event.target.value);
 
  };

  const titleMobile = () =>
    window.innerWidth < 450 ? (
      <Typography style={{ color: '#999', textAlign: 'center' }} variant='h5'>
        General Informaton
      </Typography>
    ) : null;

    
  const handleValidation = () => {
    let formIsValid = true;   
    setErrors([]);
    //console.log('state: ', state);
    //Business/CompanyName
    if (!state['startupName']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `Business/CompanyName Cannot be empty.`,
          key: 'startupName', //new Date().getTime(),
        },
      ]);
    }

    //email
    if (!state['email'] || !state['emailValidate']) {
      formIsValid = false;
      setErrors((prev) => [
        ...prev,
        {
          message: `email is invalid.`,
          key: 'email', //new Date().getTime(),
        },
      ]);
    }

    return formIsValid;
  };

  const onSave = (e) => {
    props.setLoading(true);
    e.preventDefault();
    let startup = {};
    const myMomentObject = moment(state.foundedDate, 'DD/MM/YYYY');

    if (auth.drafSignup && auth.drafSignup.startupName) {

      startup = {
        ...auth.drafSignup,
        isNewProfile: false,
        role: 'startup',
        startupName: state.startupName,
        contactName: state.contactName,
        email: state.email,
        foundedDate: myMomentObject.unix() || '',
        numberOfEmployee: state.numberOfEmployee || '',
        tel: state.tel,
        startupIndustry: state.startupIndustry,
        startupLastFundingType: state.startupLastFundingType,
        keyTechnology: keyTechnology,
        operatingCountry: state.operatingCountry,
        startupLogo: image,
        startupLogo2: [image],
        businessIntroPdf: pdf,
        businessIntroPdf2: [pdf],
        aboutus: state.aboutus || '',
        linkOfWebsite: state.linkOfWebsite,
        linkOfFacebook: state.linkOfFacebook,
        gid: common.gid,
      };
    } else {

      startup = {
        isNewProfile: props.isNewProfile,
        role: 'startup',
        startupName: state.startupName,
        contactName: state.contactName,
        email: state.email,
        foundedDate: myMomentObject.unix() || '',
        numberOfEmployee: state.numberOfEmployee || '',
        tel: state.tel,
        startupIndustry: state.startupIndustry,
        startupLastFundingType: state.startupLastFundingType,
        keyTechnology: keyTechnology,
        operatingCountry: state.operatingCountry,
        startupLogo: image,
        startupLogo2: [image],
        businessIntroPdf: pdf,
        businessIntroPdf2: [pdf],
        aboutus: state.aboutus || '',
        linkOfWebsite: state.linkOfWebsite,
        linkOfFacebook: state.linkOfFacebook,
        gid: common.gid,
      };
    }

    // console.log('startup', startup);

    // eslint-disable-next-line no-constant-condition
    if (handleValidation()) {
      

      startupRegisterApi(common.currentUser.token,startup)
        // eslint-disable-next-line no-unused-vars
        .then((resp) => {
          // console.log(resp);
          startup = {... startup, isNewProfile: false};

          if(resp && resp.data && resp.data.newstartup && resp.data.newstartup._id) {
            startup._id = resp.data.newstartup._id;
          }

          dispatch({ type: SAVE_DRAF_SIGNUP, payload: startup });
          props.validToNext(true);
          props.handleComplete(); // next page
        })
        .catch((error) => {
          const errorMsg = (error.response && error.response.data && error.response.data.error && error.response.data.error.message) || JSON.stringify(error);
          console.log(error);
          props.setLoading(false);
          dispatch({type: ERROR_MODAL_SHOW, payload: {title: 'error', message: errorMsg}});
          return;
        });

    } else {
      props.setLoading(false);
      return;
    }

    props.setLoading(false);
    
    
  };

  const handleEmail = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
      emailValidate: validateEmail(event.target.value),
    });
    setErrors([]);
  };

  const cleanup = () => {
    //URL.revokeObjectURL(pdf);
    if(inputFileRef && inputFileRef.current) inputFileRef.current.value = null;
  };

  const setPdf = (newFile) => {
    if (pdf) {
      cleanup();
    }
    _setPdf(newFile);
    props.setLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleOnChange = async (event) => {
    event.preventDefault();
    const newFile = event.target?.files?.[0];
    console.log(newFile, event.target);
    props.setLoading(true);

    try {
      
      if (newFile) {
        const bucketName = `${common.gid}`;
        const storageRef = firebaseStorage.ref(`${bucketName}/${newFile.name}`);
        // console.log(storageRef);
        storageRef.put(newFile).then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadUrl => {
            //console.log(downloadUrl);
            if(downloadUrl)
            setPdf({newFile, downloadUrl});
          });
        });

      }
    } catch (error) {
      console.log(error);
      setPdf(null);
    }
    
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  // eslint-disable-next-line no-unused-vars
  const handleClick = (event) => {
    if (pdf && pdf.newFile) {
      props.setLoading(true);
      event.preventDefault();

      // Create a reference to the file to delete
      const bucketName = `${common.gid}`;
      const desertRef = firebaseStorage.ref(`${bucketName}`).child(pdf.newFile.name);

      // Delete the file
      desertRef.delete().then(function() {
        // File deleted successfully
        setPdf(null);
      }).catch(function(error) {
        // Uh-oh, an error occurred!
        console.log('delete: ', error);
      });

    }
  };

  const handlePdf = () => {
    // console.log(pdf, state.businessIntroPdf2)
    if(pdf && pdf.newFile && pdf.newFile.name){
      return pdf.newFile.name;
    }

    if(state.businessIntroPdf2 && state.businessIntroPdf2.length > 0 && state.businessIntroPdf2[0]) {
      return state.businessIntroPdf2[0].downloadUrl;
    }

    return '' ;
  };

  const handleDate = (date) => {
    setState({
      ...state,
      foundedDate: date,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      {errors && errors.length > 0 && <Snackbar color='danger' errors={errors} />}
      {titleMobile()}
      
      <div>
        {/*<AvatarUpload
          setLoading={(r) => props.setLoading(r)}
          folder='logo'
          imageName={`${state.startupName ? state.startupName.replace(/\s/g, '') : `${common.gid}_${new Date().valueOf()}`}_logo`}
          resize
          autoSave
          imagecloud={state.startupLogo2 && state.startupLogo2.length > 0 ? state.startupLogo2[0] : undefined}
          clearImage={setImage}
          result={(d) => {
            setImage(d);
          }}
        />*/}
        <AvatarUpload2 id={1} 
        // eslint-disable-next-line react/prop-types
        setLoading={(r) => props.setLoading(r)}
        imagecloud={state.startupLogo2 && state.startupLogo2.length > 0 ? state.startupLogo2[0] : null}
        clearImage={() => {
          setImage(null);
          setState((prev) => ({
            ...prev,
            startupLogo2: [null],
          }));
        }}
        handleClick={() =>  setOpenDialog(true)}
      />
        <CropImageDialog folder='logo' gid={common.gid} 
        result={(d) => {
          // console.log(d);
          setImage(d);
          setState((prev) => ({
            ...prev,
            startupLogo2: [d],
          }));
          dispatch({type: STATUS_MODAL_SHOW, payload: {title: 'Upload Image', message: 'Upload image successful'}});
          setOpenDialog(false);
        }}
        imageName={`${state.startupName ? state.startupName.replace(/\s/g, '') : `${common.gid}_${new Date().valueOf()}`}_logo`}
        handle={handleCloseDialog} openDialog={openDialog}
        />
      </div>

      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Business/CompanyName'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'startupName',
                type: 'text',
                error: !state.startupName,
                onChange: (event) => handleStateChange(event),
                value: state.startupName,
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='startupIndustry-native-helper'>
              Industry of business
            </InputLabel>
            <NativeSelect
              value={state.startupIndustry}
              onChange={handleStateChange}
              inputProps={{
                name: 'startupIndustry',
                id: 'startupIndustry-native-helper',
              }}
            >
              <option aria-label='None' value='' />
              {industryTypeEnum.map((item) => {
                return (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </NativeSelect>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Contact name'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'contactName',
                type: 'text',
                onChange: (event) => handleStateChange(event),
                value: state.contactName,
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='startupKeyTechnology-native-helper'>
              Key of technology (can select more than one)
            </InputLabel>
            <p className={classes.divider}> </p>
            <Select
              labelId='demo-mutiple-checkbox-label'
              id='demo-mutiple-checkbox'
              multiple
              value={keyTechnology}
              onChange={handleChange}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
              //MenuProps={MenuProps}
            >
              {keyTechnologyEnum.map((item) => (
                <MenuItem key={item.code} value={item.name}>
                  <Checkbox checked={keyTechnology.indexOf(item.name) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Email'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'email',
                type: 'email',
                error: !state.emailValidate,
                onChange: (event) => handleEmail(event),
                value: state.email,
                endAdornment: (
                  <InputAdornment position='end'>
                    <Email className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='startupCountry-native-helper'>
              The current operating country
            </InputLabel>
            <NativeSelect
              value={state.operatingCountry}
              onChange={handleStateChange}
              inputProps={{
                name: 'operatingCountry',
                id: 'startupCountry-native-helper',
              }}
            >
              <option aria-label='None' value='' />
              {countries.map((c) => {
                return (
                  <option key={c.name} value={c.code}>
                    {c.name}
                  </option>
                );
              })}
            </NativeSelect>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Tel...'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'tel',
                type: 'text',
                onChange: (event) => handleStateChange(event),
                value: state.tel,
                endAdornment: (
                  <InputAdornment position='end'>
                    <PhoneIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Business introduction (PDF)'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'businessIntroPdf',
                type: 'text',
                disabled: true,
                value: handlePdf(),
                
                endAdornment: (
                  <Button color='transparent' component='label' 
                  onClick={handleClick}
                  >
                    <input ref={inputFileRef} type='file' hidden onChange={(event) => handleOnChange(event)} />
                    <InputAdornment position='end'>
                      
                      {pdf ? <Delete mr={1} /> : <CloudUploadIcon mr={1} />}
                    </InputAdornment>
                  </Button>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <InputLabel htmlFor='startupLastFundingType-native-helper'>
              Last funding type
            </InputLabel>
            <NativeSelect
              value={state.startupLastFundingType}
              onChange={handleStateChange}
              inputProps={{
                name: 'startupLastFundingType',
                id: 'startupLastFundingType-native-helper',
              }}
            >
              <option aria-label='None' value='' />
              {['Pre-seed', 'Seed', 'Series A', 'Series B', 'Series C', 'Series D', 'Series E',].map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </NativeSelect>
            {/*<FormHelperText>Some important helper text</FormHelperText>*/}
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>
          <InputLabel shrink htmlFor='foundedDate'>
            Founded Date 
          </InputLabel>
          
          <FormControl fullWidth margin='dense'>
            
            <FormControl fullWidth>
              <Datetime
                dateFormat='DD/MM/YYYY'
                timeFormat={false}
                value={state.foundedDate || ''}
                onChange={handleDate}
                inputProps={{
                  placeholder: 'Datetime Picker Here',
                  id: 'foundedDate',
                }}
              />
            </FormControl>
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        
        <Grid xs={12} sm={12} md={4} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Number of employee'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'numberOfEmployee',
                type: 'number',
                //error: emailValidate,
                onChange: (event) => handleStateChange(event),
                value: state.numberOfEmployee,
                // endAdornment: (
                //   <InputAdornment position='end'>
                //     <Email className={classes.inputIconsColor} />
                //   </InputAdornment>
                // ),
              }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={12} md={4} item>

        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='About us...'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'aboutus',
                type: 'text',
                onChange: (event) => handleStateChange(event),
                value: state.aboutus,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Link of website...'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'linkOfWebsite',
                type: 'text',
                onChange: (event) => handleStateChange(event),
                value: state.linkOfWebsite,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={10} item>
          <FormControl fullWidth margin='dense'>
            <CustomInput
              labelText='Link of Facebook...'
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: 'linkOfFacebook',
                type: 'text',
                onChange: (event) => handleStateChange(event),
                value: state.linkOfFacebook,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        container
        direction='row'
        spacing={1}
        item
        justify='space-around'
        alignItems='center'
      >
        <Grid xs={12} sm={12} md={2} item>
          <p className={classes.divider}> </p>
          <Button onClick={onSave} block color='danger' size='lg'>
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralInformaton;
