/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomInput from 'components/CustomInput/CustomInput';
import validateEmail from 'functions/validations/email';

import styles from 'assets/jss/material-kit-react/components/tagInputStyle';
 
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
	... styles,
  // backdrop: {
  //   zIndex: theme.zIndex.drawer + 1,
  //   color: 'red',
  // },
}));


// eslint-disable-next-line no-unused-vars
const TagsInput = (props) => {
	const classes = useStyles();
	const {tags, setTags} = props;
	// const [tags, setTags] = React.useState([]);

	const removeTags = indexToRemove => {
		setTags([...tags.filter((_, index) => index !== indexToRemove)]);
	};

	const addTags = event => {
		if (event.target.value && event.target.value.trim() !== "" && validateEmail(event.target.value.trim())) {
			setTags([...tags, event.target.value]);
			//props.selectedTags([...tags, event.target.value]);
			event.target.value = "";
		} else {
			event.target.value = "";
		}
	};

	return (
		<div className={classes.root}>
			<div className={classes.tagsInput}>
				<ul id="tags" className={classes.tags}>
					{tags && tags.map((tag, index) => (
						<li key={index} className={classes.tag}>
							<span className={classes.tagTitle}>{tag}</span>
							<span className={classes.tagCloseIcon}
								onClick={() => removeTags(index)}
							>
								x
							</span>
						</li>
					))}
				</ul>
				{/*<input
					className={classes.input}
					type="text"
					onKeyUp={event => event.key === "Enter" || event.key === " " ? addTags(event) : null}
					placeholder="Press enter or space to add email"
				/>*/}
				<CustomInput
					labelText='email'
					id='email'
					formControlProps={{
						fullWidth: true,
						className: classes.textArea,
					}}
					inputProps={{
						multiline: true,
						rows: 5,
						name: 'email',
						type: 'text',
						// onChange: (event) => handleChange(event),
						// value: state.email,
						onKeyUp: (event) => event.key === "Enter" || event.key === " " ? addTags(event) : null,
						placeholder: "Press enter or space to add email",
					}}
        />
			</div>
		</div>
	);
};

export default TagsInput;