/* eslint-disable react/prop-types */
import React from 'react';
import {useHistory} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
// import CardFooter from 'components/Card/CardFooter';

// import image from "assets/img/ana2.jpg";

import { title } from "assets/jss/material-kit-react.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  section: {
    padding: "10px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...modalStyle,
  modalHeader: {
    borderBottom: "none",
    paddingTop: "14px",
    paddingRight: "4px",
    paddingBottom: "0",
    paddingLeft: "4px",
    minHeight: "16.43px",
    backgroundColor: '#eee',
  },
  modalBody: {
    paddingTop: "4px",
    paddingRight: "4px",
    paddingBottom: "6px",
    paddingLeft: "4px",
    position: "relative",
    backgroundColor: '#eee',
  },
  cardBorderRadius36: {
    borderRadius: "36px",
  },
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
});

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

// eslint-disable-next-line no-unused-vars
export default function SignupDialog(props){
  const history = useHistory();
  const classes = useStyles();
  const {openSignup, onCloseSignup} = props;

  const dialog = () => {
    return (
      <div className={classes.section} style={{
        // backgroundImage: "url(" + image + ")",
        backgroundColor: '#eee',
        padding: "1rem",
        borderRadius: "36px",
      }}>
        <h4 className={classes.title}>YakStart insights & analysis</h4>
        <p className={classes.title}>Intelligently search and target the right opportunities with advanced search filters</p>
        <GridContainer>
  
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{'display': 'flex', flexDirection: 'column', justifyContect: 'center', alignItems: 'center'}} className={classes.cardBorderRadius36}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                
              </GridItem>
              <h4 className={classes.cardTitle}>
                For Investor
                <br />
                <Button round color="facebook" onClick={() => {
                  return history.push(`${process.env.REACT_APP_INVESTOR_SIGNUP_PUSH_URL}?p=signup`);
                }}>
                  Sign up
                </Button>
              </h4>
              <p className={classes.cardTitle}>Already have an account? </p>
              <Button simple color="facebook" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
                Login
              </Button>

            </Card>
          </GridItem>
          
          <GridItem xs={12} sm={12} md={6}>
            <Card style={{'display': 'flex', flexDirection: 'column', justifyContect: 'center', alignItems: 'center'}} className={classes.cardBorderRadius36}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                
              </GridItem>
              <h4 className={classes.cardTitle}>
                For Startup
                <br />
                <Button round color="danger" onClick={() => history.push(`${process.env.REACT_APP_STARTUP_SIGNUP_PUSH_URL}?p=signup`)}>
                  Sign up
                </Button>
              </h4>
              <p className={classes.cardTitle}>Already have an account? </p>
              <Button simple color="facebook" onClick={() => history.push(process.env.REACT_APP_LOGIN_PUSH_URL)}>
                Login
              </Button>
            </Card>
          </GridItem>
  
        </GridContainer>
      </div>
    );
  };

  return (
    <Dialog fullWidth={true} maxWidth="md"
      className={classes.modalBodyNopadding}
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={openSignup}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCloseSignup}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onCloseSignup}
        >
          <Close className={classes.modalClose26} />
        </IconButton>
        <h4 className={classes.modalTitle}></h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        {dialog()}
      </DialogContent>
    </Dialog>
  );
}