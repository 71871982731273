import React, { createRef, useState } from "react";

import { useSelector } from 'react-redux';

/* eslint-disable react/prop-types */
import { Avatar, Button as MuiButton } from "@material-ui/core";
import {
  CloudUpload as MuiCloudUpload,
  Delete as MuiDelete,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";

import Resizer from 'react-image-file-resizer';

import styled from "styled-components";

import axios from 'axios';

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);
const DeleteIcon = styled(MuiDelete)(spacing);


const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
width: 120px;
height: 120px;
margin: 0 auto 20px;
`;
// const AvatarUpload = () => {
//   const [image, _setImage] = useState(null);
//   const inputFileRef = createRef(null);

//   const BigAvatar = styled(Avatar)`
//   width: 120px;
//   height: 120px;
//   margin: 0 auto 20px;
//   ${({ $withBorder }) =>
//     $withBorder &&
//     `border: 1px solid ${grey[500]};
//      box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};`}
// `;

const AvatarUpload = (props) => {
  const { common } = useSelector((state) => ({ ...state }));
  const [image, _setImage] = useState(null);
  const [cloudinaryImage, setCloudinaryImage] = useState(null);
  const inputFileRef = createRef(null);
  // eslint-disable-next-line no-unused-vars
  const {resize, autoSave, result, setLoading, folder, imageName, imagecloud, clearImage, displayOnly} = props;
  //console.log(props);

  // React.useEffect(() => {
  //   console.log('gid: ', common.gid);
  // }, []);

  React.useEffect(() => {
 
    if(imagecloud) {
      setImage(imagecloud.url);
      setCloudinaryImage(imagecloud);
    }
  }, [imagecloud]);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
    
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];
    setLoading(true);
    //console.log('newImage: ', newImage);

    // resize
    if(resize) {
      // console.log(newImage);
      if (newImage) {
        let imageFormat = 'JPEG';
        switch (newImage.type) {
          case 'image/png':
            imageFormat = 'PNG'
            break;
          case 'image/jpeg':
            imageFormat = 'JPEG'
            break;
          default:
            break;
        }

        Resizer.imageFileResizer(newImage // the file from input
          , 200 // width
          , 200 // height
          , imageFormat // 'JPEG' // compress format WEBP, JPEG, PNG // safari not support webp
          , 100 // quality
          , 0 // rotation
          , (uri) => {
          
            // send to server to upload to cloudinary
            if (autoSave) {
              axios
                .post(`${process.env.REACT_APP_API}/uploadimage`, { image: uri, gid: common.gid, folder: folder, name: imageName || '' })
                .then((res) => {
                  // console.log(res);
                  result(res.data);
                  setCloudinaryImage(res.data);
                })
                .catch((error) => {
                  console.log(error);
                  result (null);
 
                });
            } else {
              result(uri);

            }
        }, 'base64' // blob or base64 default base64
        );
      }
    }
    
    
    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }

  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    if (image) {
      setLoading(true);
      event.preventDefault();
      setImage(null);
    
      // send remove image on cloud
      axios.post(`${process.env.REACT_APP_API}/removeimage`, {public_id: cloudinaryImage.public_id})
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        // console.log(res);
        result (null);
        clearImage(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    }
  };

  return (
    <CenteredContent>
      <BigAvatar
        //variant="square"
        $withBorder
        alt="Avatar"
        src={image || "/static/img/avatars/default-profile.svg"}
        imgProps={{
          style: {
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
            float: "right",
          },
        }}
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id={`avatar-image-upload${props.id || ''}`}
        type="file"
        onChange={handleOnChange}
      />
      {!displayOnly && <label htmlFor={`avatar-image-upload${props.id || ''}`}>
        <Button
          variant="contained"
          color="default"
          component="span"
          mb={2}
          onClick={handleClick}
        >
          {image ? <DeleteIcon mr={1} /> : <UploadIcon mr={1} />}
          {image ? "Clear" : "Upload"}
        </Button>
      </label>}
      {/*<Typography variant="caption" display="block" gutterBottom>
        For best results, use an image of at least 128x
        128 pixels in .jpg format
      </Typography> */}
    </CenteredContent>
  );
};

export default AvatarUpload;
