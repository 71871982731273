import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// @material-ui/icons
// import PinDropIcon from '@material-ui/icons/PinDrop';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

// import IconButton from '@material-ui/core/IconButton';
// eslint-disable-next-line no-unused-vars
import {ERROR_MODAL_SHOW} from 'constants/actionTypes';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/workStyle.js';

const useStyles = makeStyles(styles);

export default function VerifySection() {

  const classes = useStyles();

  return (
    <div className={classes.section}>
      <GridContainer justify='center'>
        <GridItem cs={12} sm={12} md={8}>
          <h3 className={classes.title}>Verification email sent!</h3>
        </GridItem>
      </GridContainer>
      <GridContainer justify='flex-start'>
        <GridItem cs={12} sm={12} md={12}>
          <h5 style={{color: 'red'}} className={classes.title}>please check your email inbox! <br/>
          ps. If you can not find your confirmation email in your normal inbox, <br/>
          it is worth checking in your spam or junk mail section.</h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
